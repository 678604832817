<template flex>
  <div class="dashboard">

    <v-navigation-drawer
      app
      v-model="snapshotDialog"
      fixed
      temporary
      right
      width="500"
      class="v-fixed-right result-tab"
      style="z-index: 999999 !important"
    >
    <v-card outlined height="100%">
      <div class="sticky-header">
    <v-card-title
      class="headline d-flex justify-space-between px-5 py-3 mb-0 br-bottom "
      ><h5 class="text-h6">Test Results</h5>
      <v-icon @click="snapshotDialog=false">mdi-close</v-icon></v-card-title
    >
        <template v-if="!snapshotLoading">
          <v-tabs
            v-model="resultTab"
            height="45px"
            class="trigger-tabs px-6 "
            >
            <v-tab
                v-for="item in resultTabItems"
                :key="item"
            >
              {{ item }}
            </v-tab>
          </v-tabs>
        </template>
      </div>

        <template v-if="snapshotLoading">
          <v-row class="testRun">
            <v-col
              cols="6"
              style="margin-left: 20px;"
            >
              <p>Test Run Id : </p>
              <p>{{ testRunId }}</p>
            </v-col>
            <v-col
              cols="3"
            >
              <p>Elapsed time: </p>
              <p>{{ sarElapsedTime }}</p>
            </v-col>
            <v-col
              cols="1"
            >
              <v-tooltip bottom color="primarydark">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-1 font-22 icon-hover"
                    color="primary"
                    @click="copyJSON()"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-content-copy
                  </v-icon>
                </template>
                <span>Copy Log</span>
              </v-tooltip>
            </v-col>
            <!-- <v-col
              cols="1"
            >
              <v-tooltip bottom color="primarydark">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-1 font-22 icon-hover"
                    color="primary"
                    @click="openTRResults()"
                    :disabled="snapshotLoading"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>View results</span>
              </v-tooltip>
            </v-col> -->
          </v-row>
          <div class="d-flex flex-column" style="margin-left: 20px;">
            <p v-for="msg in testRunProgressText" class="greybase--text ma-0 mt-1">{{ msg }}</p>
            <v-divider class="mt-4"></v-divider>
          </div>
        </template>

        <template v-if="!snapshotLoading">
          <v-tabs-items v-model="resultTab" >
            <v-tab-item>
              <v-card flat>
               
                  <v-switch
                    v-model="expand1"
                    label="Expand All"
                    color="primary"
                    hide-details
                    class="ml-2 expand-btn"
                  ></v-switch>
                  <json-viewer :expand-depth="expandCount1" :value="humanJson" :key="expandCount1 + '-json-viewer'"></json-viewer>
                  <!-- <json-viewer :expand-depth="expandCount1" :value="outputJson" :key="expandCount1"></json-viewer> -->
               
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-switch
                    v-model="expand2"
                    label="Expand All"
                    color="primary"
                    hide-details
                    class="ml-2 expand-btn"
                  ></v-switch>
                  <json-viewer :expand-depth="expandCount2" :value="jsonData" :key="expandCount2 + 'json-viewer-2'"></json-viewer>
             
              </v-card>
            </v-tab-item>

          </v-tabs-items>
        </template>

    <!-- <v-card-text class="pa-2 pt-0">
      <div class="keword-lists pa-3 pt-0">
        <v-row
          class="mt-4"
        >
          <json-viewer :expand-depth="3" :value="jsonData"></json-viewer>
        </v-row>
      </div>
      </v-card-text> -->
      <v-card-actions class="justify-space-between pa-3">
        <div class="right-action-btn d-flex">
          <!-- <v-btn width="127" color="grey" depressed outlined @click="snapshotDialog=false">
            Close
          </v-btn> -->
        </div>
      </v-card-actions>
      </v-card>
    </v-navigation-drawer>

    <v-app class="scriptedRuleBody">
      <div class="inner-container only-heading-part mt-0 ">
      

        <v-container fluid class="v-main-content only-simple-content pt-0">
          <template fluid>

              <div id="app">    
                 <v-card class="transparent page-header-with-banner rounded-lg px-0 h-100 pa-4 pb-8 pt-0 extra-padding v-card v-sheet theme--light" elevation="0">
                     <h3 class="primarybase--text h5--text my-3 mb-6">Create Scripted Rule</h3>
                            <v-card elevation="0" class="transparent ">
                              <v-row class=" ">
                                <v-col cols="12" lg="6" sm="12"  class=" toggle-btn">
                            <!-- <v-btn-toggle
                                rounded
                                class=" toggle-btn"
                              > -->
                                <v-btn @click="confirmSarModeChange('blocklyeditor')" outlined :plain="lockBlockly" color="primarydark" :class="modeToggle === 'blocklyeditor'?['px-8' , 'bg-active']: 'px-8'"  rounded>
                                <v-icon v-if="modeToggle === 'blocklyeditor'" color="primary" class="mr-1">mdi-check</v-icon> <v-icon v-if="lockBlockly" color="primary" class="mr-1">mdi-lock-outline</v-icon> Automation Blocks
                              </v-btn>
                                
                              <v-btn @click="confirmSarModeChange('jseditor')" outlined color="primarydark" class="px-8" rounded :class="modeToggle === 'jseditor'?['px-8' , 'bg-active']: 'px-8'" > 
                                <v-icon v-if="modeToggle === 'jseditor'" color="primarydark" class="mr-1">mdi-check</v-icon> JavaScript Editor
                              </v-btn>
                            
                              <!-- </v-btn-toggle> -->
                                </v-col>
                                <v-col cols="12" lg="6" sm="12"   class="d-flex justify-end">
                                   <v-select
                                    :items="templateItems"
                                    @change="applyTemplate($event)"
                                    height="40"
                                    class="no-field-details scripted-filter mt-0"
                                    label="Select Template"
                                    outlined
                                    dense
                                  >
                                  </v-select>
                                  
                                </v-col>
                              </v-row>
                             
                            <v-row class="mb-2">
                              <v-col cols="12" class=" py-0 d-flex align-center justify-space-between kai-btn-wrap">

                                <v-btn color="primary" class="sm-show" depressed @click="showSmallPopup=true"><v-icon>mdi-plus</v-icon>Add Actions </v-btn>

                              <div class="button-wrapper" v-if="showpopup">

                                <v-icon  color=" "  class=" close font-22 " @click="showSmallPopup=false">mdi-close</v-icon>

                                     <v-btn @click="addSuperGlobal()" small :disabled="superGlobals.length>0" text color="primary" class="mr-2 mb-2"><v-icon color="primary">mdi-plus</v-icon>Add Inputs </v-btn>


                                  <v-btn @click="addEditor('trigger')" text small color="primary" class="mr-2 mb-2" depressed>
                                  <v-icon>mdi-plus</v-icon>
                                  Add Trigger
                                </v-btn>
                                <v-tooltip :disabled="triggerExists" bottom>
                                  <template v-slot:activator="{ on }">
                                    <span v-on="on">
                                      <v-btn :disabled="!triggerExists" @click="addEditor('campaign')" text small color="primary" class="mr-2 mb-2" depressed>
                                        <v-icon>mdi-plus</v-icon>
                                        Add Campaign Action
                                      </v-btn>
                                    </span>
                                  </template>
                                  <span>A trigger is required for creating ASA actions.</span>
                                </v-tooltip>
                                <v-tooltip :disabled="triggerExists" bottom>
                                  <template v-slot:activator="{ on }">
                                    <span v-on="on">
                                      <v-btn :disabled="!triggerExists" @click="addEditor('adgroup')" text small color="primary" class="mr-2 mb-2" depressed>
                                        <v-icon>mdi-plus</v-icon>
                                        Add Adgroup Action
                                      </v-btn>
                                    </span>
                                  </template>
                                  <span>A trigger is required for creating ASA actions.</span>
                                </v-tooltip>
                                <v-tooltip :disabled="triggerExists" bottom>
                                  <template v-slot:activator="{ on }">
                                    <span v-on="on">
                                      <v-btn :disabled="!triggerExists" @click="addEditor('keyword')" text small color="primary" class="mr-2 mb-2" depressed>
                                        <v-icon>mdi-plus</v-icon>
                                        Add Keyword Action
                                      </v-btn>
                                    </span>
                                  </template>
                                  <span>A trigger is required for creating ASA actions.</span>
                                </v-tooltip>
                                <v-btn @click="addEditor('onetime')" text small color="primary" class="mr-2 mb-2" depressed>
                                  <v-icon>mdi-plus</v-icon>
                                  Add One-Time Action
                                </v-btn>
                                  </div>
                                  <div class="min-w-fit-content">
                                 <v-btn
                                        @click="validateActions()"
                                        width="120"
                                        color="primary"
                                        class="mr-1 mb-0"
                                        depressed
                                      >
                                        Validate
                                      </v-btn>
                                    <v-btn
                                 @click="initiateTestRun()"
                                 width="120"
                                 color="secondary"
                                 class="primarydark--text "
                                 depressed
                               >
                                 <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                 <v-icon  v-bind="attrs"
                                v-on="on" class="mr-1" color="primarydark" small>mdi-play</v-icon>
                                  </template>
                                 <span>Run the rule for Debugging</span>
                               </v-tooltip>
                                 {{ enableSARLiveRun ? 'Test Run v2' : 'Test Run'}}
                                 </v-btn>
                                  </div>
                              </v-col>
                              </v-row>


                                  <!-- input var -->
                               <v-card elevation="0" class="br-8 br h-100 "  v-if="/* modeToggle === 'jseditor' && */ superGlobalsModified.length">
                                   <v-expansion-panels
                                  accordion
                                  elevation="0"
                                  class="scripted-rule-pannel"
                                  multiple
                                  v-model="inputPanel"
                                >

                                  <v-expansion-panel>
                                    <v-expansion-panel-header
                                      class="mb-0 d-flex align-center justify-space-between mt-0 pt-2 pb-6 trigger-cust-icon greyxxlight primary"
                                      >
                                        <span class="primarybase--text body--text-1 mt-4">
                                         Input variables                        
                                        </span>
                                    </v-expansion-panel-header>
                                    
                                    <span class="d-flex align-center justify-center edit-delete-icon" style="    ">
                                            <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                               <v-icon color="primary" class="cursor-pointer"   v-bind="attrs"
                                           v-on="on" @click="superGlobals = []">mdi-delete</v-icon>
                                             </template>
                                          <span>Delete</span>
                                        </v-tooltip>
                                    </span>

                                    <v-expansion-panel-content class="scriptedRule-outer">
                                      <v-card elevation="0" class="br-8    h-100 w-100 pa-6 input-var overflow-y-auto" max-height="38vh">
                                      <v-form v-model="inputFormValid" ref="inputFormRef">
                                        <v-row v-for="(item, index) in superGlobals" :key="index + '-superglobal-inputs'" class="d-flex align-start justify-start mb-3">
                                          <v-col
                                            cols="12"
                                            md="3"
                                            class="py-0"
                                          >
                                           <label class=" ">Input Name</label>
                                            <v-text-field
                                              :rules="[(v) => v != '' || 'Please enter a value',(v) => /^[a-zA-Z0-9 ]+$/.test(v) || 'Name must be alphanumeric', checkUnique(index)]"
                                              v-model="item.inputName"
                                              label=" "
                                              required
                                              class="pt-0 v-sel-custom mt-0 "
                                            ></v-text-field>
                                          </v-col>

                                          <v-col
                                            cols="12"
                                            md="3"
                                            class="py-0"
                                          >
                                           <label>Description</label>

                                            <v-text-field
                                         class="pt-0 v-sel-custom mt-0 "
                                              v-model="item.desc"
                                              :rules="[(v) => v != '' || 'Please enter a value']"
                                              label=""
                                              required
                                            ></v-text-field>
                                          </v-col>

                                          <v-col
                                            cols="12"
                                            md="3"
                                            class="py-0"
                                          >
                                           <label>Value</label>

                                            <v-text-field
                                            v-model="item.value"
                                            :rules="checkNumAndAlpha"
                                            label=""
                                            required
                                             class="pt-0 v-sel-custom mt-0 "
                                            ></v-text-field>
                                          </v-col>

                                          <v-col
                                            cols="12"
                                            md="3"
                                            class="d-flex align-center justify-space-between py-0"
                                          >
                                          <div class="d-flex flex-column   my-1 w-100 justify-space-between">
                                             <label class=" ">Usage</label>
                                             <span class="d-flex w-fit-content justify-space-between ">
                                              <v-tooltip bottom v-if="superGlobalsModified[index].usage">
                                            <template v-slot:activator="{ on, attrs }">
                                           <span v-bind="attrs"
                                           v-on="on" class="caption--text mt-1 text-overflow cursor-default max-200">{{superGlobalsModified[index].usage}} 
                                          </span> 
                                            </template>
                                             <span>{{superGlobalsModified[index].usage}} </span>
                                            </v-tooltip>
                                           <v-tooltip bottom v-if="superGlobalsModified[index].usage">
                                            <template v-slot:activator="{ on, attrs }">
                                               <v-icon color="primary" size="medium" class="cursor-pointer ml-2" @click="copyToClipboard(superGlobalsModified[index].usage)"  v-bind="attrs"
                                           v-on="on">mdi-content-copy</v-icon>
                                             </template>
                                             <span>Copy to clipboard</span>
                                            </v-tooltip>
                                           </span>
                                          </div>
                                           <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                               <v-icon color="primary" class="cursor-pointer ml-2"   v-bind="attrs"
                                           v-on="on" @click="superGlobals.splice(index,1)">mdi-delete</v-icon>
                                             </template>
                                          <span>Delete</span>
                                        </v-tooltip>
                                          </v-col>
                                        </v-row>
                                      <span @click="addSuperGlobal()" class="mr-2  primary--text cursor-pointer"><v-icon color="primary">mdi-plus</v-icon>Add Inputs </span>
                                    </v-form>
                                       
                                      </v-card>
                                    </v-expansion-panel-content>

                                  </v-expansion-panel>
                                </v-expansion-panels>
                               </v-card>
                              <!--end input var -->


                               <v-card elevation="0" class="br-8 br h-100 " >
                                   <v-expansion-panels
                                  accordion
                                  elevation="0"
                                  class="scripted-rule-pannel"
                                  multiple
                                  :value="scriptedExpansionValue"
                                >

                                  <!-- trigger  -->
                                  <v-expansion-panel :class="item.type !== 'trigger' && item.type !== 'trigger_blockly' ? ['white--text','action-panel'] : ''" v-for="(item, index) in scriptedAutomationsModel.automationSteps" :key="index + '-sar-expansion-panel'"
                                  >

                                    <v-expansion-panel-header
                                      class="mb-0 d-flex align-center justify-space-between mt-0 pt-2 pb-9 trigger-cust-icon greyxxlight primary"
                                      >
                                        <span class="primarybase--text body--text-1">
                                          Step {{ index + 1 }} : {{ item.label }}
                                          
                                          <v-tooltip v-if="item.isValid && anActionWithoutTrigger && !['trigger', 'trigger_blockly', 'onetime', 'onetime_blockly'].includes(item.type)" bottom color="primarydark">
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-icon
                                                v-bind="attrs"
                                                v-on="on"
                                                class="red--text font-22"
                                                >mdi-alert-outline</v-icon
                                              >
                                            </template>
                                            <span>A trigger is required before ASA actions.</span>
                                          </v-tooltip>
                                          
                                          <v-tooltip v-if="!item.isValid" bottom color="primarydark">
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-icon
                                                v-bind="attrs"
                                                v-on="on"
                                                class="red--text font-22"
                                                >mdi-alert-outline</v-icon
                                              >
                                            </template>
                                            <span v-if="!['campaign_blockly', 'adgroup_blockly', 'keyword_blockly', 'onetime_blockly','trigger_blockly'].includes(item.type)">Errors found in script. Please fix to continue.</span>
                                            <span v-if="['campaign_blockly', 'adgroup_blockly', 'keyword_blockly', 'onetime_blockly','trigger_blockly'].includes(item.type)">There is an error with your Blockly code.</span>
                                          </v-tooltip>
                                          
                                        </span>
                                    </v-expansion-panel-header>

                                    <span class="mt-0 font-13 edit-name primary--text d-flex align-start flex-row" style="    "
                                        >
                                        <v-text-field
                                          v-model="item.title"
                                          required
                                          solo
                                          :style= "{ width: item.title.length * 9 + 'px' }"
                                          :readonly="!item.titleEditable"
                                          :class="item.titleEditable ? (item.type === 'trigger' || item.type === 'trigger_blockly') ? ['edit-trigger-inactive',' edit-trigger-active',' primary--text']  : ['edit-inactive',' edit-active',' white--text'] : (item.type === 'trigger' || item.type === 'trigger_blockly') ? ' edit-trigger-active' : 'edit-active'"
                                        ></v-text-field>

                                    <v-tooltip bottom>
                                     <template v-slot:activator="{ on, attrs }">
                                     <v-icon
                                            v-bind="attrs"
                                              v-on="on"
                                            v-if="!item.titleEditable"
                                            :class="item.type === 'trigger' || item.type === 'trigger_blockly' ? ['grey--text edit-pencile font-18'] : ['grey--text edit-pencile font-18']"
                                            @click="item.titleEditable = true"
                                          >mdi-pencil-outline</v-icon
                                        >
                                         </template>
                                      <span>Edit</span>
                                    </v-tooltip>
                                         <v-icon
                                            v-if="item.titleEditable"
                                            :class="item.type === 'trigger' || item.type === 'trigger_blockly' ? ['primary--text edit-check font-22'] : ['primary--text edit-check font-22']"
                                            @click="item.titleEditable = false"
                                          >mdi-check</v-icon
                                        >
                                      </span>

                                        <span class="d-flex align-center justify-center edit-icon" style="    ">
                                          
                                           <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                          <v-icon 
                                            v-bind="attrs"
                                           v-on="on"
                                            v-if="showMoveUp(item, index)"
                                            @click="moveEditor(index, -1)"
                                            :class="item.type === 'trigger' || item.type === 'trigger_blockly' ? ['primary--text mr-2'] : ['primary--text mr-2']"
                                          >
                                            mdi-arrow-up
                                          </v-icon>
                                            </template>
                                          <span>Move Up</span>
                                        </v-tooltip>
                                          
                                             <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                          <v-icon 
                                          v-bind="attrs"
                                           v-on="on"
                                          v-if="showMoveDown(item, index)"
                                            @click="moveEditor(index, 1)"
                                            :class="item.type === 'trigger' || item.type === 'trigger_blockly' ? ['primary--text mr-2'] : ['primary--text mr-2']"
                                          >
                                            mdi-arrow-down
                                          </v-icon>
                                               </template>
                                          <span>Move Down</span>
                                        </v-tooltip>

                                            <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                             <v-icon
                                             v-bind="attrs"
                                           v-on="on"
                                        v-if="index" @click="deleteScriptedArStep(index)"
                                            :class="item.type === 'trigger' || item.type === 'trigger_blockly' ? ['primary--text mr-2'] : ['primary--text mr-2']"
                                          >mdi-delete-outline</v-icon
                                        >
                                             </template>
                                          <span>Delete</span>
                                        </v-tooltip>
                                       
                                         
                                       
                                       </span>

                                    <v-expansion-panel-content class="scriptedRule-outer">

                                      <v-card v-if="!['campaign_blockly', 'adgroup_blockly', 'keyword_blockly', 'onetime_blockly','trigger_blockly'].includes(item.type)" elevation="0" class="br-8  br overflow-y-scroll h-100 scriptedRuleLeft" max-height="38vh">

                                        <v-card elevation="0" class="scripted-tab">
                                          <template v-if="item.type === 'trigger'">
                                            <div class="sticky-header-h">
                                              <v-tabs
                                                v-model="scriptedtab[index]"
                                                height="45px"
                                                class="trigger-tabs "
                                                center-active
                                                show-arrows
                                                >
                                                <v-tab
                                                    v-for="item in trigVarTabs"
                                                    :key="item + '-trigVarTabs'"
                                                >
                                                  {{ item }}
                                                </v-tab>
                                                </v-tabs>

                                                <v-text-field
                                                  outlined
                                                  dense
                                                  v-model="searchTriggerVars"
                                                  placeholder="Search"
                                                  height="35px"
                                                  prepend-inner-icon="mdi-magnify"
                                                  class="mt-3 mx-4 var-search"
                                                ></v-text-field>
                                                </div>

                                                <v-tabs-items v-model="scriptedtab[index]" class="scripted-search ">
                                                  <v-tab-item>
                                                      <v-card flat>

                                                  <v-list dense class="pt-0 mx-4">
                                                        <v-list-item-group
                                                            color="primary"
                                                        >
                                                            <v-list-item
                                                              v-for="(item, i) in campaignTriggerVar"
                                                              @click="insertFromNavigator('campaign.' + item, index)"
                                                              :key="i + '-campaignTriggerVar'"
                                                              class="font-14"
                                                              min-height="35px"
                                                            >
                                                            <v-list-item-icon>
                                                              <v-img
                                                                rounded
                                                                class="ma-0"
                                                                src="../assets/images/var.svg"
                                                              ></v-img>
                                                            </v-list-item-icon>
                                                            <v-list-item-content>
                                                              <v-list-item-title v-text="item"></v-list-item-title>
                                                            </v-list-item-content>
                                                            </v-list-item>
                                                        </v-list-item-group>
                                                      </v-list>
                                                      </v-card>
                                                  </v-tab-item>
                                                  <v-tab-item class="scripted-search">
                                                    <v-card flat>

                                                <v-list dense class="pt-0 mx-4">
                                                      <v-list-item-group
                                                          color="primary"
                                                      >
                                                      <v-list-item
                                                      v-for="(item, i) in adgroupTriggerVar"
                                                      @click="insertFromNavigator('adgroup.' + item, index)"
                                                      :key="i + '-adgroupTriggerVar'"
                                                      class="font-14"
                                                      min-height="35px"
                                                    >
                                                    <v-list-item-icon>
                                                      <v-img
                                                      rounded
                                                      class="ma-0"
                                                      src="../assets/images/var.svg"
                                                    ></v-img>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                      <v-list-item-title v-text="item"></v-list-item-title>
                                                    </v-list-item-content>
                                                    </v-list-item>
                                                      </v-list-item-group>
                                                    </v-list>
                                                    </v-card>
                                                </v-tab-item>
                                                <v-tab-item>
                                                  <v-card flat>

                                              <v-list dense class="mx-4 pt-0">
                                                    <v-list-item-group
                                                        color="primary"
                                                    >
                                                    <v-list-item
                                                    v-for="(item, i) in keywordTriggerVar"
                                                    @click="insertFromNavigator('keyword.' + item, index)"
                                                    :key="i + '-keywordTriggerVar'"
                                                    class="font-14"
                                                    min-height="35px"
                                                  >
                                                  <v-list-item-icon>
                                                  <v-img
                                                      rounded
                                                      class="ma-0"
                                                      src="../assets/images/var.svg"
                                                    ></v-img>
                                                  </v-list-item-icon>
                                                  <v-list-item-content>
                                                    <v-list-item-title v-text="item"></v-list-item-title>
                                                  </v-list-item-content>
                                                  </v-list-item>
                                                    </v-list-item-group>
                                                  </v-list>
                                                  </v-card>
                                              </v-tab-item>
                                                  </v-tabs-items>
                                            </template>
                                            <template v-if="item.type !== 'trigger'">
                                              <div class="sticky-header-h">
                                              <!-- <p style="display: flex;
                                                align-content: center;
                                                justify-content: center;"
                                                  class="mb-1 greybase lighten-3 py-4 d-flex align-center justify-center "

                                                >Actions for step {{ index + 1}}
                                              </p> -->
                                              <v-tabs
                                                v-model="scriptedtab[index]"
                                                height="45px"
                                                  class=" "
                                                    center-active
                                               show-arrows
                                                >
                                                <v-tabs-slider color="primary" width="50%"></v-tabs-slider>

                                                <v-tab
                                                    v-for="item in scripteditems"
                                                    :key="item + '-scripteditems'"


                                                >
                                                  {{ item }}
                                                </v-tab>
                                                </v-tabs>
                                                    <v-text-field
                                                    outlined
                                                    dense
                                                    v-model="searchVars"
                                                    placeholder="Search"
                                                    prepend-inner-icon="mdi-magnify"
                                                    class="mt-3 var-search mx-4"
                                                  ></v-text-field>
                                                </div>

                                            <v-tabs-items v-model="scriptedtab[index]" class="scripted-search" >
                                            <v-tab-item>
                                                <v-card flat>

                                            <v-list dense class="pt-0 mx-4">
                                                  <v-list-item-group
                                                      color="primary"
                                                  >
                                                      <v-list-item
                                                        v-for="(item, i) in getFiltFuncList(item.type)"
                                                        @click="insertFromNavigator(item, index)"
                                                        :key="i + '-getFiltFuncList'"
                                                        class="font-14"
                                                        min-height="35px"
                                                      >
                                                      <v-list-item-icon>
                                                        <v-img
                                                      rounded
                                                      class="ma-0"
                                                      src="../assets/images/fun.svg"
                                                    ></v-img>
                                                      </v-list-item-icon>
                                                      <v-list-item-content>
                                                        <v-list-item-title v-text="item"></v-list-item-title>
                                                      </v-list-item-content>
                                                      </v-list-item>
                                                  </v-list-item-group>
                                                </v-list>
                                                </v-card>
                                            </v-tab-item>
                                            <v-tab-item>
                                              <v-card flat>

                                          <v-list dense>
                                                <v-list-item-group
                                                    color="primary"
                                                >
                                                <v-list-item
                                                v-for="(item, i) in getFiltVarList(item.type)"
                                                @click="insertFromNavigator(item, index)"
                                                :key="i + '-getFiltVarList'"
                                                class="font-14"
                                                min-height="35px"
                                              >
                                              <v-list-item-icon>
                                                  <v-img
                                                      rounded
                                                      class="ma-0"
                                                      src="../assets/images/var.svg"
                                                    ></v-img>
                                              </v-list-item-icon>
                                              <v-list-item-content>
                                                <v-list-item-title v-text="item"></v-list-item-title>
                                              </v-list-item-content>
                                              </v-list-item>
                                                </v-list-item-group>
                                              </v-list>
                                              </v-card>
                                          </v-tab-item>
                                            </v-tabs-items>
                                            </template>
                                          </v-card>
                                      </v-card>

                                      <v-card elevation="0" :class="jsExpanded ? ['scriptedRuleRight', 'pa-2 ','pr-0'] : ['scriptedRuleRight', ' ', 'pa-2','pr-0']">
                                      <template v-if="item.type === 'trigger'">
                                        <TriggerEditor
                                        class="pr-2"
                                          @valid="getEditorValues($event, item, item.type, index)"
                                          :template="item.template"
                                          :insertFromNavigator="item.insertFromNavigator"
                                          :triggerVarMap="triggerVarMap"
                                          :superGlobals="[...(superGlobalsModified.map(e=>'@'+e.usage))]"
                                        ></TriggerEditor>
                                      </template>
                                      
                                      <template v-if="item.type === 'trigger_blockly'">
                                        <BlocklyTriggers
                                          :triggerVarMap="triggerVarMap"
                                          :state="item.startState"
                                          @valid="getBlocklyCode($event, item, index)"
                                          :superGlobals="[...(superGlobalsModified.map(e=>'@'+e.usage))]"
                                        ></BlocklyTriggers>

                                        <!-- <span class="d-flex align-center expand-editor" v-if="!jsExpanded">
                                          <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-icon color="primary" v-bind="attrs" v-on="on" class="cursor-pointer" @click="expCollapJS('expand')"> 
                                                mdi-arrow-left
                                              </v-icon>
                                            </template>
                                            <span>Expand</span>
                                          </v-tooltip>
                                          <h5 class="primary--text mx-3">JS</h5>
                                        </span> -->
                                        
                                      </template>

                                      <template v-else-if='["campaign", "adgroup", "keyword", "onetime"].includes(item.type)'>
                                        <ActionEditor
                                          :validate="validate[index]"
                                          @valid="getEditorValues($event, item, item.type, index)"
                                          :insertFromNavigator="item.insertFromNavigator"
                                          :template="item.template"
                                          :scriptedArFunctions="getFunctionsList(item.type)"
                                          :scriptedArVars="getVariablesList(item.type)"
                                          :miscVars="getMiscVariableList(item.type)"
                                          :globalVars="[...globalVariablesMapIndexWise[index], ...(superGlobalsModified.map(e=>e.usage))]"
                                          :superGlobals="[...(superGlobalsModified.map(e=>e.usage))]"
                                          :propCompletions="getPropList(item.type)"
                                          :type="item.type"
                                          class="pr-2"
                                        ></ActionEditor>

                                       
                                      </template>
                                      
                                      <template v-else-if='["campaign_blockly", "adgroup_blockly", "keyword_blockly", "onetime_blockly"].includes(item.type)'>
                                        <BlocklyEditor
                                          :validate="validate[index]"
                                          :scriptedArFunctions="getFunctionsList(item.type)"
                                          :scriptedArVars="getVariablesList(item.type)"
                                          :scriptedSovArVars="getSovVariablesList(item.type)"
                                          :state="item.startState"
                                          :globalVars="[...globalVariablesMapIndexWise[index], ...(superGlobalsModified.map(e=>e.usage))]"
                                          :superGlobals="[...(superGlobalsModified.map(e=>e.usage))]"
                                          @valid="getBlocklyCode($event, item, index)"
                                          :type="item.type"
                                          :lookback="settings.lookback"
                                        ></BlocklyEditor>

                                        <!-- <span class="d-flex align-center expand-editor" v-if="!jsExpanded">
                                          <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-icon color="primary" v-bind="attrs" v-on="on" class="cursor-pointer" @click="expCollapJS('expand')">
                                                mdi-arrow-left
                                              </v-icon>
                                            </template>
                                            <span>Expand</span>
                                          </v-tooltip>
                                         <h5 class="primary--text mx-3">JS</h5>
                                        </span> -->
                                      </template>
                          
                                      </v-card>

                                      <v-card v-if='["campaign_blockly", "adgroup_blockly", "keyword_blockly", "onetime_blockly"].includes(item.type)' :class="jsExpanded ? ['blockly-editor'] : ['blockly-editor', ' ']" elevation="0">
                                      <v-card-title class="d-flex w-100 pa-2 justify-space-between  sticky-header-h ">
                                        <span class="d-flex align-center" v-if="jsExpanded">
                                          <!-- <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">  -----   class="cursor-pointer"   @click="expCollapJS('collapse')" -->
                                              <v-icon color="primary"  > 
                                                mdi-arrow-right
                                              </v-icon>
                                            <!-- </template>
                                            <span>collapse</span>
                                          </v-tooltip> -->
                                        
                                         <h5 class="primary--text mx-3">JS</h5>
                                         <p class="grey--text caption--text ma-0 ">Generated Javascript</p>
                                        </span>
                                        <v-tooltip bottom>
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="primary" v-bind="attrs" v-on="on" class="cursor-pointer text-end" @click="copyCode(item.code)"> 
                                              mdi-content-copy
                                            </v-icon>
                                          </template>
                                          <span>Copy</span>
                                        </v-tooltip>
                                      </v-card-title>
                                      
                                      <pre v-if="blocklyCodePreview[index]">
                                        <code class="hljs javascript"><span v-html="blocklyCodePreview[index]"></span></code>
                                        </pre>
                                      
                                      </v-card>
                                      
                                      <v-card v-if='["trigger_blockly"].includes(item.type)' :class="jsExpanded ? ['blockly-editor'] : ['blockly-editor', '']" elevation="0">
                                        <v-card-title class="d-flex w-100 pa-2 justify-space-between  ">
                                          <span class="d-flex align-center" v-if="jsExpanded">
                                            <!-- <v-tooltip bottom>
                                              <template v-slot:activator="{ on, attrs }"> ----------class="cursor-pointer"  @click="expCollapJS('collapse')" -->
                                                <v-icon color="primary"  > 
                                                  mdi-arrow-right
                                                </v-icon>
                                              <!-- </template>
                                              <span>Collapse</span>
                                            </v-tooltip> -->
                                           <h5 class="primary--text mx-3">JS</h5>
                                           <p class="grey--text caption--text ma-0 ">Generated SQL</p>
                                          </span>
                                          <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-icon color="primary" v-bind="attrs" v-on="on" class="cursor-pointer text-end" @click="copyCode(item.code)"> 
                                                mdi-content-copy
                                              </v-icon>
                                            </template>
                                            <span>Copy</span>
                                          </v-tooltip>
                                        </v-card-title>
                                        
                                        <pre v-if="blocklyCodePreview[index]"><code class="hljs sql"><span v-html="blocklyCodePreview[index]"></span></code></pre>
                                        
                                      </v-card>
                                       

                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                </v-expansion-panels>
                                    </v-card>


                            </v-card>


                         <!-- settings form -->

                                <v-form v-model="sarSettingsFormvalid" ref="sarSettings">
                                  <v-row class="setting-sr mt-2 d-flex align-center br br-4 white pa-2 mx-0 mt-3">
                                    <v-col cols="12"  lg="3" md="3" sm="6" class="pb-0">
                                      <label>{{ $t('createAR.ruleName') }}</label>
                                      <v-text-field
                                        class="v-sel-custom mt-0"
                                        v-model="ruleName"
                                        dense
                                        :rules="nameRule"
                                        required
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12"  lg="3" md="3" sm="6" class="pb-0">
                                      <label>Goals</label>
                                      <v-form v-model="goalFormvalid" ref="goalsFormRef">
                                        <v-autocomplete
                                          ref="goalsRef"
                                          class="rounded-lg"
                                          v-model="selectedGoals"
                                          :items="appGoals || []"
                                          :loading="$apollo.queries.getGoals.loading"
                                          item-text="name"
                                          item-value="_id"
                                          required
                                          dense
                                          prepend-inner-icon="mdi-bullseye"
                                          return-object
                                          multiple
                                          :rules="goalRules"
                                          @change="goalSelected"
                                        >
                                        </v-autocomplete>
                                      </v-form>
                                    </v-col>
                                    <v-col cols="12"  lg="3" md="3" sm="6" class="pb-0">	
                                      <label>{{ $t('createAR.sharedVoiceLabel') }}</label>	
                                      <v-select	
                                        class="v-sel-custom mt-0"	
                                        v-model="selectShareVoiceOption"	
                                        :items="sharedVoiceOptions"	
                                        :rules="sovRules"
                                        dense	
                                        attach	
                                        	
                                      ></v-select>	
                                      	
                                    </v-col>
                                     <v-col cols="12"  lg="3" md="3" sm="6" class="pb-0">
                                        <label>{{ $t('createAR.selectDateRange') }}</label>
                                      <div>
                                        <v-menu
                                          ref="menu"
                                          v-model="ruleRangePicker"
                                          :close-on-content-click="false"
                                          :return-value.sync="ruleRangePicker"
                                          transition="scale-transition"
                                          offset-y
                                          min-width="auto"
                                        >
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                              style="font-size: revert"
                                              v-model="ruleDateRangeText"
                                              prepend-inner-icon="mdi-calendar"
                                              v-bind="attrs"
                                              v-on="on"
                                              class=" w-sm-100 mx-0 pt-0"
                                            ></v-text-field>
                                          </template>
                                          <template>
                                            <div style="display: flex">
                                              <div style="flex: auto">
                                                <v-date-picker
                                                  v-model="ruleDateRangelModel"
                                                  no-title
                                                  scrollable
                                                  range
                                                >
                                                  <v-btn
                                                    text
                                                    color="grey"
                                                    @click="ruleRangePicker = false"
                                                    >{{ $t('createAR.cancel') }}</v-btn
                                                  >
                                                  <v-btn
                                                    :disabled="
                                                      ruleDateRangelModel &&
                                                      ruleDateRangelModel.length !== 2
                                                    "
                                                    text
                                                    color="primary"
                                                    @click="applyRuleDateRange()"
                                                    >{{ $t('kai.ok') }}</v-btn
                                                  >
                                                  <v-btn
                                                    text
                                                    color="secondary"
                                                    @click="clearRuleDateRange"
                                                    >{{ $t('kai.clear') }}</v-btn
                                                  >
                                                </v-date-picker>
                                              </div>
                                            </div>
                                          </template>
                                        </v-menu>
                                      </div>
                                      
                                    </v-col>
                                       <v-col cols="12"  lg="3" md="3" sm="6" class="pb-0 pt-2">
                                        <v-row class="d-flex align-start">
                                         
                                     <v-col cols="6"  lg="6" md="6" sm="6" class="pb-0">
                                      <label>{{ $t('createAR.chckFreq') }}</label>
                                      <v-select
                                        class="v-sel-custom mt-0"
                                        v-model="settings.checkFrequency"
                                        :items="actionFreqs"
                                        dense
                                        attach
                                        :rules="vSelectNotNullRules"
                                      ></v-select>
                                     </v-col>
                                       <v-col cols="6"  lg="6" md="6" sm="6" class="pb-0">
                                          <label class=" ">Lookback (Days)</label>
                                          <v-text-field
                                            class="v-sel-custom mt-0"
                                            v-model="settings.lookback"
                                            dense
                                            :rules="lookbackRule"
                                            required
                                            type="number"
                                            max="30"
                                            min="0"
                                          ></v-text-field>
                                          <v-chip
                                            color="secondary"
                                            class="primarydark--text"
                                            text-color="white"
                                            v-if="settings.lookback == 0"
                                          >
                                            <b>Current day only</b>
                                          </v-chip>
                                        </v-col>
                                      </v-row>
                                    </v-col>
                                    <v-col cols="12" lg="3" md="3" sm="12" class="pb-0">
                                      <v-checkbox
                                        height="0"
                                        class="my-0"
                                        v-model="settings.sendReports"
                                        label="Send Reports"
                                      ></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" lg="3" md="3" sm="12" class="pb-0">
                                      <v-checkbox
                                        height="0"
                                        class="my-0"
                                        v-model="includeInactiveEntities"
                                        label="Include paused or inactive entities."
                                      ></v-checkbox>
                                    </v-col>
                                  </v-row>
                                  
                                  <v-row class="setting-sr mt-2 d-flex align-center br br-4 white pa-2 mx-0 mt-6" v-if="settings.sendReports">
                                     <v-col cols="6"  lg="3" md="3" sm="6" class="pb-0">
                                      <div class="field">
                                        <v-select
                                          class="v-sel-custom v-sel-custom-rule"
                                          v-model="selectedMailIds"
                                          :items="emailList"
                                          item-text="Email"
                                          item-value="Email"
                                          :label="$t('createAR.selectEmail')"
                                          multiple
                                          attach
                                          chips
                                          dense
                                          :rules="vSelectEmailRules"
                                          :disabled="!settings.sendReports"
                                          :loading="$apollo.queries.getEmails.loading"
                                          @change="emailSelectionChanged()"
                                        >
                                          <template
                                            v-slot:prepend-item
                                            v-if="emailList.length > 0"
                                          >
                                            <v-divider class="mt-2"></v-divider>
                                            <v-list-item ripple @click="toggleEmailSelectAll()">
                                              <v-list-item-action>
                                                <v-icon
                                                  color="primary"
                                                >
                                                  {{ selectAllEmailIcon }}
                                                </v-icon>
                                              </v-list-item-action>
                                              <v-list-item-content>
                                                <v-list-item-title>
                                                  {{ $t('createAR.selectAll') }}
                                                </v-list-item-title>
                                              </v-list-item-content>
                                            </v-list-item>
                                            <v-divider class="mt-2"></v-divider>
                                          </template>
                                        </v-select>
                                      </div>
                                    </v-col>
                                    <v-col cols="6"  lg="3" md="3" sm="6" class="pb-0">
                                      <div class="field">
                                        <v-select
                                          class="v-sel-custom"
                                          v-model="selectedSlacks"
                                          :items="slackChannelList"
                                          :label="$t('createAR.selectChannel')"
                                          :no-data-text="$t('createAR.noDataAvailable')"
                                          item-value="IntegrationID"
                                          item-text="channel"
                                          attach
                                          chips
                                          dense
                                          multiple
                                          :rules="vSelectRules"
                                          :disabled="!settings.sendReports"
                                          :loading="$apollo.queries.getSlacks.loading"
                                          @change="slackSelectionChanged()"
                                        >
                                          <template
                                            v-slot:prepend-item
                                            v-if="slackChannelList.length > 0"
                                          >
                                            <v-divider class="mt-2"></v-divider>
                                            <v-list-item ripple @click="toggleSlackSelectAll()">
                                              <v-list-item-action>
                                                <v-icon
                                                  color="primary"
                                                >
                                                  {{ selectAllSlackIcon }}
                                                </v-icon>
                                              </v-list-item-action>
                                              <v-list-item-content>
                                                <v-list-item-title>
                                                  {{ $t('createAR.selectAll') }}
                                                </v-list-item-title>
                                              </v-list-item-content>
                                            </v-list-item>
                                            <v-divider class="mt-2"></v-divider>
                                          </template>
                                        </v-select>
                                      </div>
                                    </v-col>
                                     <v-col cols="12" lg="6" md="6" sm="12" class="pb-0">
                                       <v-combobox
                                        v-model="externalMailIds"
                                        multiple
                                        class="v-sel-custom"
                                        label="Enter other Email Ids"
                                        chips
                                        deletable-chips
                                        dense
                                        :rules="csvEmailRules"
                                        :disabled="!settings.sendReports"
                                        ref="emailSelectRef"
                                        @change="onModelValueChange"
                                        ></v-combobox>
                                    </v-col>
                                  </v-row>
                                  
                                </v-form>

                            <v-row
                              class="d-flex justify-end align-center mb-2 mt-4"
                            >
                             <v-col cols="12" lg="8" md="8" sm="12" class="py-0 checkbox-container mb-3">
                                     
                              <p class=" primary--text  grey--text mb-0 ">Please review the <a class="text-decoration-underline" href="https://support.searchadsmaven.com/docs/r9to5oq9ad" target="_blank">Quick Start Guide</a> before creating your first rule. <br> We can help! Contact <a href="mailto:support@searchadsmaven.com">support@searchadsmaven.com</a> for assistance with rule creation.</p>

                             </v-col>
                             <v-col cols="12" lg="4" md="4" sm="12" class="d-flex justify-end">
                              <div>
                                <v-btn
                                  v-if="!editModeSAR"
                                  width="127"
                                  color="primary"
                                  depressed
                                  @click="initiateSaveSarAction(4, {})"
                                  class="ml-3"
                                >Save
                                </v-btn>
                                <v-btn
                                  v-if="editModeSAR"
                                  width="127"
                                  color="primary"
                                  depressed
                                  @click="initiateUpdateSarAction(5, {})"
                                >Update
                                </v-btn>
                              </div>
                            </v-col>

                            </v-row>
                          </v-card>
              </div>


              
              <v-dialog v-model="validProgDialogVisible" persistent width="600">
                <v-card>
                    <v-card-title
                    class="d-flex justify-space-between align-center w-100 primary white--text px-6 py-3 w-100"
                  >
                    <h5>Validation</h5>
                  </v-card-title>
                  <v-card-text class="d-flex align-start px-6 py-8 pb-6 w-100">
                  
                    <div class="d-flex flex-column w-100">
                      <div class="d-flex">
                        <v-card-title class="text-subtitle-1 pa-0">
                          Validation in progress, please wait...
                        </v-card-title>                        
                      </div>
                    </div>
                  </v-card-text>
                  <!-- <v-card-actions  class="d-flex justify-end align-center w-100 pb-6  px-6">
                    <v-btn color="primary" @click="closeValidationProgressDialog">Stop Validation</v-btn>
                  </v-card-actions> -->
                </v-card>
              </v-dialog>
                
              <v-dialog v-model="dialogVisible" persistent width="600">
                <v-card v-if="validationError" class="d-flex align-center justify-center flex-column w-100">
                  <v-card-title
                    class="d-flex justify-space-between align-center w-100 primary white--text px-6 py-3 w-100"
                  >
                    <h5>Validation failed</h5>
                    <v-icon class="white--text" @click="closeDialog">mdi-close</v-icon>
                  </v-card-title>
                  <v-card-text class="d-flex align-start px-6 py-8 pb-6 w-100">
                  
                    <div class="d-flex flex-column w-100">
                        <div class="d-flex">
                          <v-card-title class="text-subtitle-1 pa-0">
                            The following errors were found:
                          </v-card-title>
                          <v-icon class="red--text font-22 ml-1">mdi-alert-outline</v-icon>
                        </div>
                        <v-card-text class=" text-subtitle-1 pa-0 d-flex w-100 flex-column">
                          <div class="scroll-auto error-height">
                            <div class="" v-for="(item, index) in dialogErrorMessage" :key="index + '-dialogErrorMessage'">
                              <p v-html="item"></p>
                            </div>    
                            <v-list class="pa-0" v-for="(item, index) in ruleErrorsArray" :key="index + '-ruleErrorsArray'">
                              <p class="mb-0">{{item.error}}
                                <ul>
                                  <li v-if="Array.isArray(item.message)">
                                  <p class="mb-0"  v-for="(msg, msgIndex) in item.message" :key="msgIndex + '-item-message'" >
                                    {{msg.msgErrDesc}}
                                  </p>
                                </li>
                                  <li v-else>
                                    <p class="mb-0">{{item.message}}</p>
                                  </li>
                                </ul>
                              </p>
                            </v-list>
                          </div>
                        </v-card-text>
                    </div>
                  </v-card-text>
                  <v-card-actions class="d-flex justify-end align-center w-100 pb-6  px-6">
                    <v-btn color="primary" @click="closeDialog">Close</v-btn>
                  </v-card-actions>
                </v-card>
                <v-card v-else>
                    <v-card-title
                    class="d-flex justify-space-between align-center w-100 primary white--text px-6 py-3 w-100"
                  >
                    <h5>Validate</h5>
                    <v-icon class="white--text" @click="closeDialog"
                      >mdi-close</v-icon
                    >
                  </v-card-title>
                  <v-card-title>
                    Validation Complete
                  </v-card-title>
                  <v-card-text>
                    <v-icon color="success">mdi-check</v-icon>
                    <span>No errors found</span>
                  </v-card-text>
                  <v-card-actions  class="d-flex justify-end align-center w-100 pb-6  px-6">
                    <v-btn color="primary" @click="closeDialog">Close</v-btn>
                  </v-card-actions>
                </v-card>
                </v-dialog>

            <DecisionAlert
              :DecisionAlert="decisionAlertFlag"
              :DecisionAlertTitle="decisionAlertTitle"
              :DecisionAlertIcon="decisionAlertIcon"
              :DecisionAlertMessage="decisionAlertMessage"
              :DecisionAlertData="decisionAlertData"
              @emitDecisionAlertCancelClicked="clearDecisionModel"
              @emitDecisionAlertOkClicked="decisionOkAction($event)"
            />

            <BlocklyJsSwitch
              :DecisionAlert="blocklyJsSwitchFlag"
              :DecisionAlertTitle="blocklyJsSwitchTitle"
              :DecisionAlertIcon="blocklyJsSwitchIcon"
              :onlyMessage="blocklyJsSwitchOnlyMessage"
              :DecisionAlertMessage="blocklyJsSwitchMessage"
              :DecisionAlertData="blocklyJsSwitchData"
              @emitDecisionAlertCancelClicked="clearDecisionModel"
              @emitDecisionAlertOkClicked="decisionOkAction($event)"
            />
          </template>
        </v-container>


      </div>

    </v-app>
  </div>
</template>

<script>
import * as _ from 'lodash';

import hljs from "highlight.js/lib/core";
import 'highlight.js/styles/github.css';

import { sarVarMap } from '../utils/objects';

import Vue from 'vue';
import { v4 as uuidv4 } from 'uuid';

import javascript from 'highlight.js/lib/languages/javascript';
import sql from 'highlight.js/lib/languages/sql';

import {
  GET_ALL_RULES,
  GET_ALL_LOGS,
  DISABLE_AR_RULE,
  DELETE_AR_RULE,
  ENABLE_AR_RULE,
  GET_ALL_RULES_ADV,
  GET_SINGLE_RULE_ADV,
  DISABLE_AR_ADV_RULE,
  ENABLE_AR_ADV_RULE,
  DELETE_AR_ADV_RULE,
  REACTIVATE_SINGLE_RULE_ADV,
  TEST_RUN_SCRIPTED_AR,
  SAVE_SCRIPTED_AR,
  UPDATE_SCRIPTED_AR,
  GET_ALL_RULES_SCRIPTED,
  GET_SLACKS,
  GET_EMAILS,
  GET_MYSQL_COUNT,
  QUEUE_SAR_TEST_RUN
} from '../utils/arrules-queries';
import { GET_GOALS_FROM_APP, GET_GOALS } from '../utils/apollo-queries';
import CreateRule from '../components/arrules/createrule1.vue';
import DecisionAlert from '../components/DecisionAlert.vue'
import BlocklyJsSwitch from '../components/scripted-automations/BlocklyJsSwitch.vue';
import ActionEditor from '../components/scripted-automations/ActionEditor.vue';
import BlocklyEditor from '../components/scripted-automations/BlocklyEditor.vue';
import TriggerEditor from '../components/scripted-automations/TriggerEditor.vue';
import BlocklyTriggers from '../components/scripted-automations/BlocklyTriggers.vue'
import { parse, updateCLF } from '../utils/util';
import { LSS } from '@core/services';
import * as moment from 'moment';
import { RuleCheckFrequencies } from '@shared/enums/arenums';
import JsonViewer from 'vue-json-viewer';
import { decodeToken } from '@shared/utils/commom.util';
import DuplicateEmailComboboxService from "@services/component-services/dulicate-email-combobox.service";

export default {
  name: 'Rules',
  components: {
    ActionEditor,
    TriggerEditor,
    BlocklyTriggers,
    JsonViewer,
    DecisionAlert,
    BlocklyJsSwitch,
    BlocklyEditor
  },
  data() {
    return {
      sarExecutionLog: [],
      sarElapsedTime: '00:00',
      sarElapsedTimer: null,
      mySQLCampsDone: false,
      mySQLAdsDone: false,
      mySQLKeysDone: false,
      mySQLCampsProgress: 0,
      mySQLAdsProgress: 0,
      mySQLKeysProgress: 0,
      testRunId: 'init',
      enableSARLiveRun: false,
      initTestRun: false,
      initSaveSar:false,
      initUpdateSar:false,
      showValidationPopup: false,
      stopValidating: false,
      validProgDialogVisible: false,
      goalValidationResult:true,
      diagnosticsValues:[],
      validationErrorMessage: [],
      validationError: false,
      dialogErrorMessage: [],
      ruleErrorsArray:[],
      dialogVisible: false,
      validate: {},
      selectShareVoiceOption:false,
      inputPanel:[0], 
      inputFormValid:true,  
      superGlobals:[],  
      uid: '',
      mysqlProgress: 0,
      showMysqlprogress: false,
      lookbackRule: [
        (v) => (v !=='' && v >= 0 && v < 31) || 'Lookback should be from 0 to 30 days',
        (v) => (Number.isInteger(Number(v))) || 'value must be an integer'
      ],
      modeToggle: 'blocklyeditor',
      lockBlockly: false,
      blocklyCodePreview: {},
      tempRuleId:'',
      globalVariablesMap: {},
      declaredReportVars:[],
      globalVariablesMapIndexWise: {},
      campaignCount: 0,
      adgroupCount : 0,
      keywordCount : 0,
      mySQLCampaigns : [],
      mySQLAdgroups : [],
      mySQLKeywords : [],
      expand1:false,
      expand2:false,
      expandCount1:3,
      expandCount2:3,
      selectedMailIds: [],
      externalMailIds: [],
      selectAllEmails: false,
      selectAllSlacks: false,
      selectedSlacks: [],
      emailList: [],
      selectAllEmailIcon: 'mdi-crop-square',
      selectAllSlackIcon: 'mdi-crop-square',
      slackChannelList: [],
      csvEmailRules: [
        (v) => {
          if(v.length === 0) return true
          if((v.length > 0) && (/^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(,[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/.test(
            v
          ))) return true
          else return this.$t('createAR.enterEmail')
        },
      ],
      vSelectRules: [

      ],
      vSelectEmailRules: [

      ],
      vSelectNotNullRules: [
        (v) => v != null || this.$t('createAR.pleaseSelect'),
        (v) => v != '' || this.$t('createAR.pleaseSelect'),
      ],
      showSmallPopup : false,
      window: {
            width: 0,
            height: 0
        },
      selectedGoals: [],
      appGoals: [],
      trigVarTabs: ['campaign', 'adgroup', 'keyword'],
      editModeSAR: false,
      selectedSAR: null,
      ruleName: '',
      sarSettingsFormvalid: false,
      goalFormvalid: false,
      scriptedtab: {},
      jsonData: [],
      outputJson: [],
      humanJson:[],
      snapshotDialog: false,
      tooManyResults: false,
      snapshotLoading: false,
      testRunProgressText: [],
      closeSARDialog: false,
      syncStatus: false,
      locale: 'en',
      expanded: [],
      goals: [],
      decisionAlertFlag: false,
      decisionAlertTitle: 'Are you sure ?',
      decisionAlertMessage: '',
      decisionAlertIcon:2,
      decisionAlertData: {},
      blocklyJsSwitchFlag: false,
      blocklyJsSwitchTitle: 'Are you sure ?',
      blocklyJsSwitchOnlyMessage: false,
      blocklyJsSwitchMessage: '',
      blocklyJsSwitchIcon:2,
      blocklyJsSwitchData: {},
      tab: null,
      toptab: null,
      searchAR: '',
      searchLogs: '',
      selectedARlogs: [],
      logItems: [],
      logLoading: false,
      disableSwitch: null,
      selectedARItems: [],
      arLoading: false,
      arSearch: '',
      dataToCreateRule: {},
      fullscreenLoader: null,
      arItems: [],
      infoAlertFlag: false,
      infoAlertTitle: 'Info',
      infoAlertMessage: '',
      appleMsgs: [],
      searchLogItems: [],
      RuleCheckFrequenciesEnum: RuleCheckFrequencies,
      searchLog: {
        get() {
          return {
            ruleId: 0,
            ruleName: 'All',
          };
        },
        set(e) {
          this.searchLog.ruleId = e.ruleId;
          this.searchLog.ruleName = e.ruleName;
        },
      },
      logItemsFiltered: [],
      scriptedAutomationsModel: {
        automationSteps: [
          {
            isValid: true,
            label: 'Trigger',
            code: '',
            type: 'trigger_blockly',
            title: 'Trigger',
            titleEditable: false,
          }
        ]
      },
      templateMap: {
        pause_poor: [
          {
            isValid: true,
            label: 'Trigger',
            title: 'Trigger',
            type: 'trigger',
            template: 'campaign.tapAvgCPA > 10 and campaign.tapInstalls > 25',
            code: 'campaign.tapAvgCPA > 10 and campaign.tapInstalls > 25'
          },
          {
            isValid: true,
            label: 'Campaign Action',
            title: 'Campaign Action',
            type: 'campaign',
            template: 'PauseCampaign(id)',
            code: 'PauseCampaign(id)'
          }
        ],
        fund_exhausted: [
          {
            isValid: true,
            label: 'Trigger',
            title: 'Trigger',
            type: 'trigger',
            template: 'campaign.spend > campaign.budget - 100',
            code: 'campaign.spend > campaign.budget - 100'
          },
          {
            isValid: true,
            label: 'Campaign Action',
            title: 'Campaign Action',
            type: 'campaign',
            template: 'SetCampaignBudget(id, budget + 500)',
            code: 'SetCampaignBudget(id, budget + 500)'
          },
          {
            isValid: true,
            label: 'Keyword Action',
            title: 'Keyword Action',
            type: 'keyword',
            template: 'SetKeywordBidAmount(id, bidAmount * 1.25)',
            code: 'SetKeywordBidAmount(id, bidAmount * 1.25)'
          }
        ],
        reallocate: [
          {
            isValid: true,
            label: 'Trigger',
            title: 'Trigger',
            type: 'trigger',
            template: 'campaign.tapAvgCPA > 1 and campaign.tapInstalls > 10 and campaign.spend < campaign.budget and campaign.state = "ACTIVE"',
            code: 'campaign.tapAvgCPA > 1 and campaign.tapInstalls > 10 and campaign.spend < campaign.budget and campaign.state = "ACTIVE"'
          },
          {
            isValid: true,
            label: 'One-Time Action',
            title: 'One-Time Action',
            type: 'onetime',
            template: 'var _reallocateBudget = 0',
            code: 'var _reallocateBudget = 0'
          },
          {
            isValid: true,
            label: 'Campaign Action',
            title: 'Campaign Action',
            type: 'campaign',
            template: `PauseCampaign(id);\n_reallocateBudget += budget - spend`,
            code: 'PauseCampaign(id);\n_reallocateBudget += budget - spend'
          },
          {
            isValid: true,
            label: 'Trigger',
            title: 'Trigger',
            type: 'trigger',
            template: 'campaign.tapAvgCPA <= 1 and campaign.tapInstalls > 10 and campaign.state = "ACTIVE"',
            code: 'campaign.tapAvgCPA <= 1 and campaign.tapInstalls > 10 and campaign.state = "ACTIVE"'
          },
          {
            isValid: true,
            label: 'Trigger',
            title: 'Trigger',
            type: 'trigger',
            template: 'ORDER BY campaign.tapAvgCPA ASC',
            code: 'ORDER BY campaign.tapAvgCPA ASC'
          },
          {
            isValid: true,
            label: 'Campaign Action',
            title: 'Campaign Action',
            type: 'campaign',
            template: `_reallocateBudget += budget;\nSetCampaignBudget(id, _reallocateBudget);\nStopAction();`,
            code: `_reallocateBudget += budget;\nSetCampaignBudget(id, _reallocateBudget);\nStopAction();`
          },
        ],
        kw_journey_1_impressions: [
          {
            "type": "trigger_blockly",
            "code": "",
            "isValid": true,
            "title": "Trigger",
            "state": {
                "languageVersion": 0,
                "blocks": [
                    {
                        "type": "query_join",
                        "id": "fFRG+ouf4.o_HteOR%xE",
                        "x": 127,
                        "y": 47,
                        "extraState": {
                            "itemCount": 5,
                            "customName": "Query"
                        },
                        "inputs": {
                            "ADD0": {
                                "block": {
                                    "type": "query_any_of",
                                    "id": "7ooU|MNCnrgDVwKQtjKg",
                                    "extraState": {
                                        "itemCount": 2,
                                        "customName": "Any Of"
                                    },
                                    "inputs": {
                                        "ADD0": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "LH?q#.t-5W`8c/Th+QUO",
                                                "fields": {
                                                    "operator": "="
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "zk4T#9xB+2bPKr^{,@%b",
                                                            "fields": {
                                                                "category": "campaign",
                                                                "asavar": "name"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "text",
                                                            "id": "YV5x$`baXg4{2_%_{lhJ",
                                                            "fields": {
                                                                "TEXT": "My Campaign 1"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        "ADD1": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "HjX{9M2XMFwX{rF@lTY8",
                                                "fields": {
                                                    "operator": "="
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "G{k$cpUy@V=O%Q?+3;,=",
                                                            "fields": {
                                                                "category": "campaign",
                                                                "asavar": "name"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "text",
                                                            "id": "!U9!#A/-B#wZ_bBd)TCD",
                                                            "fields": {
                                                                "TEXT": "My Campaign 2"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            },
                            "ADD1": {
                                "block": {
                                    "type": "and_query",
                                    "id": "y+qPav9beAS/g-m+A}lY"
                                }
                            },
                            "ADD2": {
                                "block": {
                                    "type": "query_all_of",
                                    "id": "cIImMo$.NwRemvjDB=a-",
                                    "extraState": {
                                        "itemCount": 4,
                                        "customName": "All Of"
                                    },
                                    "inputs": {
                                        "ADD0": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "F+Tgo9SI/B!6;nj4I.z|",
                                                "fields": {
                                                    "operator": "="
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "9s2jSAx.Sp,K%yxv_C;V",
                                                            "fields": {
                                                                "category": "keyword",
                                                                "asavar": "taps"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "math_number",
                                                            "id": "2$]!Piz/%Zc`^=B9ejAl",
                                                            "fields": {
                                                                "NUM": 0
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        "ADD1": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "5?M*N`kh,GL0WC(mI(=,",
                                                "fields": {
                                                    "operator": "="
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "C+Y{rs|6ah2*8~ThuZMv",
                                                            "fields": {
                                                                "category": "campaign",
                                                                "asavar": "state"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "text",
                                                            "id": "D1J$)#//-|bN}%KB{z$3",
                                                            "fields": {
                                                                "TEXT": "ENABLED"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        "ADD2": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "T|kqANu!!lTtJ_^3~l?E",
                                                "fields": {
                                                    "operator": "="
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "8Up/|7H.X_,1Lj8QAjk2",
                                                            "fields": {
                                                                "category": "adgroup",
                                                                "asavar": "state"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "text",
                                                            "id": "#]owLq-s=lze+dY/h6)f",
                                                            "fields": {
                                                                "TEXT": "ENABLED"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        "ADD3": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "6*Uzt5^MZ$p^JPwN[[O9",
                                                "fields": {
                                                    "operator": "="
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "V@qP?~|mhw[Ti/?j-mL2",
                                                            "fields": {
                                                                "category": "keyword",
                                                                "asavar": "state"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "text",
                                                            "id": "DVi+F5,#Oow97Zm/NF{U",
                                                            "fields": {
                                                                "TEXT": "ACTIVE"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            },
                            "ADD3": {
                                "block": {
                                    "type": "orderby_query",
                                    "id": "?vLrb*!WGu[6oU$hsFmv",
                                    "fields": {
                                        "sort_order": "asc"
                                    },
                                    "inputs": {
                                        "field": {
                                            "block": {
                                                "type": "target_query",
                                                "id": "7o)du)BM3T#;?Q5Rn.QD",
                                                "fields": {
                                                    "category": "keyword",
                                                    "asavar": "bidAmount"
                                                }
                                            }
                                        }
                                    }
                                }
                            },
                            "ADD4": {
                                "block": {
                                    "type": "limit_query",
                                    "id": "cs`%Q7svACTHBf+Zgp^Z",
                                    "inputs": {
                                        "limit": {
                                            "block": {
                                                "type": "math_number",
                                                "id": "g:|T^tbbBh@aOXlxJ9?g",
                                                "fields": {
                                                    "NUM": 10
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                ]
            },
            "label": "Trigger",
            "titleEditable": false
          },
          {
            "isValid": true,
            "label": "One-Time Action",
            "code": "var Max_Bid, Min_Bid, Bid_Increase;\n\n\nMax_Bid = 3;\nMin_Bid = 0.5;\nBid_Increase = 0.05;\n",
            "type": "onetime_blockly",
            "title": "One-Time Action",
            "titleEditable": false,
            "state": {
                "blocks": {
                    "languageVersion": 0,
                    "blocks": [
                        {
                            "type": "variables_set",
                            "id": "XvDP3fDAhB|Q_zei?uO^",
                            "x": 59,
                            "y": 32,
                            "fields": {
                                "VAR": {
                                    "id": "e?,LO$8W,imyffaab]@6"
                                }
                            },
                            "inputs": {
                                "VALUE": {
                                    "block": {
                                        "type": "math_number",
                                        "id": "5=]*Kq:(5$7W`Jq.9M[$",
                                        "fields": {
                                            "NUM": 3
                                        }
                                    }
                                }
                            },
                            "next": {
                                "block": {
                                    "type": "variables_set",
                                    "id": "^yiuK3YT)Dcr^^S/=P@[",
                                    "fields": {
                                        "VAR": {
                                            "id": "tG~Wl@55/u#%|{tIR}l_"
                                        }
                                    },
                                    "inputs": {
                                        "VALUE": {
                                            "block": {
                                                "type": "math_number",
                                                "id": ")fgj%{x=2ytAN6H:*aRx",
                                                "fields": {
                                                    "NUM": 0.5
                                                }
                                            }
                                        }
                                    },
                                    "next": {
                                        "block": {
                                            "type": "variables_set",
                                            "id": "^uyAikUPC@6U.U}W3?MX",
                                            "fields": {
                                                "VAR": {
                                                    "id": ".yJmS1icWsxAwl7[!z#G"
                                                }
                                            },
                                            "inputs": {
                                                "VALUE": {
                                                    "block": {
                                                        "type": "math_number",
                                                        "id": "#TI[}c=llD?vDVE#3_`]",
                                                        "fields": {
                                                            "NUM": 0.05
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    ]
                },
                "vars": [
                    {
                        "name": "Max Bid",
                        "id": "e?,LO$8W,imyffaab]@6"
                    },
                    {
                        "name": "Min Bid",
                        "id": "tG~Wl@55/u#%|{tIR}l_"
                    },
                    {
                        "name": "Bid Increase",
                        "id": ".yJmS1icWsxAwl7[!z#G"
                    }
                ]
            },
          },
          {
            "isValid": true,
            "label": "Keyword Action",
            "type": "keyword_blockly",
            "title": "Keyword Action",
            "titleEditable": false,
            "state": {
                "blocks": {
                    "languageVersion": 0,
                    "blocks": [
                        {
                            "type": "controls_if",
                            "id": "N^q1VYyp-J9FM-Jjqf{;",
                            "x": 24,
                            "y": 89,
                            "extraState": {
                                "elseIfCount": 2
                            },
                            "inputs": {
                                "IF0": {
                                    "block": {
                                        "type": "logic_compare_any",
                                        "id": "ozSm4{{N1@YnWye#l;K2",
                                        "fields": {
                                            "operator": "<"
                                        },
                                        "inputs": {
                                            "left": {
                                                "block": {
                                                    "type": "bidAmount",
                                                    "id": "fYDU=7Py;X^xe+|7t9yv"
                                                }
                                            },
                                            "right": {
                                                "block": {
                                                    "type": "Min_Bid",
                                                    "id": "v6`Bg@nX%F@o$pjR!j0D"
                                                }
                                            }
                                        }
                                    }
                                },
                                "DO0": {
                                    "block": {
                                        "type": "SetKeywordBidAmount",
                                        "id": "0O~t$1t)a/po!%[%06bK",
                                        "inputs": {
                                            "bid": {
                                                "block": {
                                                    "type": "Min_Bid",
                                                    "id": "((dlbvpr{_oA~tP2icT$"
                                                }
                                            }
                                        }
                                    }
                                },
                                "IF1": {
                                    "block": {
                                        "type": "logic_compare_any",
                                        "id": "s/lBgyE~OIYgc,D$[qhY",
                                        "fields": {
                                            "operator": ">"
                                        },
                                        "inputs": {
                                            "left": {
                                                "block": {
                                                    "type": "bidAmount",
                                                    "id": "cf{lVF1/,H[XHRmV6b/g"
                                                }
                                            },
                                            "right": {
                                                "block": {
                                                    "type": "Max_Bid",
                                                    "id": "%%Y?/^a*5{xtEV%F=y3S"
                                                }
                                            }
                                        }
                                    }
                                },
                                "DO1": {
                                    "block": {
                                        "type": "SetKeywordBidAmount",
                                        "id": "CT-h!k)+8eObM*4eW)#6",
                                        "inputs": {
                                            "bid": {
                                                "block": {
                                                    "type": "Max_Bid",
                                                    "id": "eTtD2?nY(_^OhXYNoo-N"
                                                }
                                            }
                                        }
                                    }
                                },
                                "IF2": {
                                    "block": {
                                        "type": "logic_compare_any",
                                        "id": "dZNjP2Rf~Y(NC=i2K_J*",
                                        "fields": {
                                            "operator": "<"
                                        },
                                        "inputs": {
                                            "left": {
                                                "block": {
                                                    "type": "bidAmount",
                                                    "id": "A6`U{~]^Sv4CyWR}8mXS"
                                                }
                                            },
                                            "right": {
                                                "block": {
                                                    "type": "Max_Bid",
                                                    "id": "=sWM%zOkCyv_rxH;;9UM"
                                                }
                                            }
                                        }
                                    }
                                },
                                "DO2": {
                                    "block": {
                                        "type": "SetKeywordBidAmount",
                                        "id": ".{.SP0lV}3x/7~^TLgNZ",
                                        "inputs": {
                                            "bid": {
                                                "block": {
                                                    "type": "math_arithmetic",
                                                    "id": "BMwqPq,nzfoddPAy-rO=",
                                                    "fields": {
                                                        "OP": "ADD"
                                                    },
                                                    "inputs": {
                                                        "A": {
                                                            "block": {
                                                                "type": "bidAmount",
                                                                "id": "(Br8Q,;maTc{t523ySIJ"
                                                            }
                                                        },
                                                        "B": {
                                                            "block": {
                                                                "type": "Bid_Increase",
                                                                "id": "Hwy.Uv-ao[@U!NM3~f9~"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    ]
                },
                "vars": [
                    {
                        "name": "New Bid Amount",
                        "id": "~5fm;d*JZ4k5{U]6oGKZ"
                    }
                ]
            }
          }
        ],
        kw_journey_2_tap: [
          {
            "type": "trigger_blockly",
            "code": "(campaign.name = \"Zazzle - Discovery\" or campaign.name = \"Zazzle - Generic\" or campaign.name = \"Zazzle Generic - CPP Printing\" or campaign.name = \"Zazzle Generic CPP CustomClothes\" or campaign.name = \"Zazzle Generic - CPPStickers\" or campaign.name = \"Zazzle Generic - CPP Wedding\" or campaign.name = \"Zazzle Generic -CPP Instant Download\" or campaign.name = \"Zazzle Generic CPP Birthday\" or campaign.name = \"Zazzle Generic - CPP CustomHome\") and (keyword.taps > 0 and keyword.tapInstalls = 0 and campaign.state = \"ENABLED\" and adgroup.state = \"ENABLED\" and keyword.state = \"ACTIVE\") order by keyword.spend desc limit 500",
            "isValid": true,
            "title": "Trigger",
            "state": {
                "languageVersion": 0,
                "blocks": [
                    {
                        "type": "query_join",
                        "id": "_l~BE-;ab]I?Y0}j!B(X",
                        "x": 122,
                        "y": 45,
                        "extraState": {
                            "itemCount": 5,
                            "customName": "Query"
                        },
                        "inputs": {
                            "ADD0": {
                                "block": {
                                    "type": "query_any_of",
                                    "id": "GH:sqI*WlTB*YA0@U)Sg",
                                    "extraState": {
                                        "itemCount": 2,
                                        "customName": "Any Of"
                                    },
                                    "inputs": {
                                        "ADD0": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "uG*aA5|Hl/m4XcmN]PpA",
                                                "fields": {
                                                    "operator": "="
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "l7!)f%6QZbEvj:S8]q~n",
                                                            "fields": {
                                                                "category": "campaign",
                                                                "asavar": "name"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "text",
                                                            "id": ":|mhiC_OmB=idp$}o@s%",
                                                            "fields": {
                                                                "TEXT": "My Campaign 1"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        "ADD1": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "dvT%uC;-(XOp4vnSAnU*",
                                                "fields": {
                                                    "operator": "="
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "1ecZG+6/bd;88T|y:T,P",
                                                            "fields": {
                                                                "category": "campaign",
                                                                "asavar": "name"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "text",
                                                            "id": "-+h4YqiGQ|V]A9j.{ej|",
                                                            "fields": {
                                                                "TEXT": "My Campaign 2"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            },
                            "ADD1": {
                                "block": {
                                    "type": "and_query",
                                    "id": "Fg9Mb[973fS!46PaMOiM"
                                }
                            },
                            "ADD2": {
                                "block": {
                                    "type": "query_all_of",
                                    "id": "6XtjQX/K[xC*mZTk.hSx",
                                    "extraState": {
                                        "itemCount": 5,
                                        "customName": "All Of"
                                    },
                                    "inputs": {
                                        "ADD0": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "S4d%jUJVxp%*d=#*t#g*",
                                                "fields": {
                                                    "operator": ">"
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "FDTbjLgAKV.a(-oK*GQJ",
                                                            "fields": {
                                                                "category": "keyword",
                                                                "asavar": "taps"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "math_number",
                                                            "id": "NIVJ2-qV{:4!Gygs[a_y",
                                                            "fields": {
                                                                "NUM": 0
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        "ADD1": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "IL9$cw7fYeXW6:2(TNgH",
                                                "fields": {
                                                    "operator": "="
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "TYuAh=YpYT!a(F$7AS5X",
                                                            "fields": {
                                                                "category": "keyword",
                                                                "asavar": "tapInstalls"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "math_number",
                                                            "id": "gK=OcEXZN~mdPnA2sW!D",
                                                            "fields": {
                                                                "NUM": 0
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        "ADD2": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "prdU6tc/bY^Tt]Dn^92,",
                                                "fields": {
                                                    "operator": "="
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "t1h5M$d1QpPaSMH!O=~y",
                                                            "fields": {
                                                                "category": "campaign",
                                                                "asavar": "state"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "text",
                                                            "id": "hl8S1D)]n(;Ke#=`;w}x",
                                                            "fields": {
                                                                "TEXT": "ENABLED"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        "ADD3": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "WJQ[O1o=0]:!fByWJ$X8",
                                                "fields": {
                                                    "operator": "="
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "Qjo0;op]Ek[j1SuxdDRx",
                                                            "fields": {
                                                                "category": "adgroup",
                                                                "asavar": "state"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "text",
                                                            "id": ";)A_y?f[Y|cG6Tq2bHC{",
                                                            "fields": {
                                                                "TEXT": "ENABLED"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        "ADD4": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "caPyz-nMlLPZ]FOZ-i1-",
                                                "fields": {
                                                    "operator": "="
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "{utYCBPO[VeL9Q+?XGaZ",
                                                            "fields": {
                                                                "category": "keyword",
                                                                "asavar": "state"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "text",
                                                            "id": "2Xa_:qZ*p]9mM%r^WmaM",
                                                            "fields": {
                                                                "TEXT": "ACTIVE"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            },
                            "ADD3": {
                                "block": {
                                    "type": "orderby_query",
                                    "id": "pna)2{to-Yy%W1U,!C}R",
                                    "fields": {
                                        "sort_order": "desc"
                                    },
                                    "inputs": {
                                        "field": {
                                            "block": {
                                                "type": "target_query",
                                                "id": "+U8s[)/t$9Cg:N{0gktZ",
                                                "fields": {
                                                    "category": "keyword",
                                                    "asavar": "spend"
                                                }
                                            }
                                        }
                                    }
                                }
                            },
                            "ADD4": {
                                "block": {
                                    "type": "limit_query",
                                    "id": "kvLVfpa~O@lUGi)(KB!.",
                                    "inputs": {
                                        "limit": {
                                            "block": {
                                                "type": "math_number",
                                                "id": "jJ7,ZFI;7}c.sKo2!zFP",
                                                "fields": {
                                                    "NUM": 500
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                ]
            },
            "label": "Trigger",
            "titleEditable": false
          },
          {
            "isValid": true,
            "label": "One-Time Action",
            "type": "onetime_blockly",
            "title": "One-Time Action",
            "titleEditable": false,
            "state": {
                "blocks": {
                    "languageVersion": 0,
                    "blocks": [
                        {
                            "type": "variables_set",
                            "id": "5X,?7{#FH3pjhAYP_983",
                            "x": 102,
                            "y": 51,
                            "fields": {
                                "VAR": {
                                    "id": "9|QV~s9`PrJ#Ll`@5I=b"
                                }
                            },
                            "inputs": {
                                "VALUE": {
                                    "block": {
                                        "type": "math_number",
                                        "id": "7?Wj*qv#iHiFP`O{TFr/",
                                        "fields": {
                                            "NUM": 0.01
                                        }
                                    }
                                }
                            },
                            "next": {
                                "block": {
                                    "type": "variables_set",
                                    "id": "!.2%nZ8X79YTM3v~_QaT",
                                    "fields": {
                                        "VAR": {
                                            "id": "bSw[gr7H_T@gx4G!d#lq"
                                        }
                                    },
                                    "inputs": {
                                        "VALUE": {
                                            "block": {
                                                "type": "math_number",
                                                "id": "noZvY~nM2?jxa9m1@Az(",
                                                "fields": {
                                                    "NUM": 3
                                                }
                                            }
                                        }
                                    },
                                    "next": {
                                        "block": {
                                            "type": "variables_set",
                                            "id": "#WH8h0#?qc{9!:Vy=oVh",
                                            "fields": {
                                                "VAR": {
                                                    "id": "o%OcQR[r#[bu17PZ(BlT"
                                                }
                                            },
                                            "inputs": {
                                                "VALUE": {
                                                    "block": {
                                                        "type": "math_number",
                                                        "id": "YOjLs@g5d8i@d}t:G5Lk",
                                                        "fields": {
                                                            "NUM": 0.01
                                                        }
                                                    }
                                                }
                                            },
                                            "next": {
                                                "block": {
                                                    "type": "variables_set",
                                                    "id": "12LLuR{k=?A]lzwGu}P%",
                                                    "fields": {
                                                        "VAR": {
                                                            "id": "RWf4:|zqg}|q:G8,PR/`"
                                                        }
                                                    },
                                                    "inputs": {
                                                        "VALUE": {
                                                            "block": {
                                                                "type": "math_number",
                                                                "id": ",,_]I-Abs%k~WZ%aB{H3",
                                                                "fields": {
                                                                    "NUM": 6
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    ]
                },
                "vars": [
                    {
                        "name": "Spend Cap",
                        "id": "RWf4:|zqg}|q:G8,PR/`"
                    },
                    {
                        "name": "Max Bid",
                        "id": "bSw[gr7H_T@gx4G!d#lq"
                    },
                    {
                        "name": "Min Bid",
                        "id": "9|QV~s9`PrJ#Ll`@5I=b"
                    },
                    {
                        "name": "Bid Increase",
                        "id": "o%OcQR[r#[bu17PZ(BlT"
                    }
                ]
            },
            "code": "var Min_Bid, Max_Bid, Bid_Increase, Spend_Cap;\n\n\nMin_Bid = 0.01;\nMax_Bid = 3;\nBid_Increase = 0.01;\nSpend_Cap = 6;\n"
          },
          {
            "isValid": true,
            "label": "Keyword Action",
            "type": "keyword_blockly",
            "title": "Keyword Action",
            "titleEditable": false,
            "state": {
                "blocks": {
                    "languageVersion": 0,
                    "blocks": [
                        {
                            "type": "controls_if",
                            "id": "1:}vq4{,]XX)KW%yufsR",
                            "x": 52,
                            "y": 38,
                            "extraState": {
                                "elseIfCount": 2,
                            },
                            "inputs": {
                                "IF0": {
                                    "block": {
                                        "type": "logic_compare_any",
                                        "id": ")b)5~oDzeM2;z5%`(8,b",
                                        "fields": {
                                            "operator": ">="
                                        },
                                        "inputs": {
                                            "left": {
                                                "block": {
                                                    "type": "spend",
                                                    "id": "kGcJoNba$)CS0hZD%]Qg"
                                                }
                                            },
                                            "right": {
                                                "block": {
                                                    "type": "Spend_Cap",
                                                    "id": "dAe=*a_6[CMSj@J%5:gl"
                                                }
                                            }
                                        }
                                    }
                                },
                                "DO0": {
                                    "block": {
                                        "type": "SetKeywordBidAmount",
                                        "id": "Ax%|X(r?#cj1~A!{yJHx",
                                        "inputs": {
                                            "bid": {
                                                "block": {
                                                    "type": "Min_Bid",
                                                    "id": "[`O1pUX[!5?{3c{kDIwa"
                                                }
                                            }
                                        }
                                    }
                                },
                                "IF1": {
                                    "block": {
                                        "type": "logic_compare_any",
                                        "id": "le_:Ne1MjonW8`j*Nx[@",
                                        "fields": {
                                            "operator": "<"
                                        },
                                        "inputs": {
                                            "left": {
                                                "block": {
                                                    "type": "bidAmount",
                                                    "id": "}XIG3s4Y^iY|zstMuY(p"
                                                }
                                            },
                                            "right": {
                                                "block": {
                                                    "type": "Max_Bid",
                                                    "id": ":9^Xbu*0MLN`yUk2z/|4"
                                                }
                                            }
                                        }
                                    }
                                },
                                "DO1": {
                                    "block": {
                                        "type": "SetKeywordBidAmount",
                                        "id": "OdOjsnP7sh_N0DoPJADY",
                                        "inputs": {
                                            "bid": {
                                                "block": {
                                                    "type": "math_arithmetic",
                                                    "id": "S?DfHvmx{4{AyOKdDi0L",
                                                    "fields": {
                                                        "OP": "ADD"
                                                    },
                                                    "inputs": {
                                                        "A": {
                                                            "block": {
                                                                "type": "bidAmount",
                                                                "id": "{tGZ[AUSHp5.{emVZQpS"
                                                            }
                                                        },
                                                        "B": {
                                                            "block": {
                                                                "type": "Bid_Increase",
                                                                "id": "pG%x5Qo(J88{]Y/|Xpu."
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                },
                                "IF2": {
                                    "block": {
                                        "type": "logic_compare_any",
                                        "id": "le_:Ne1MjccW8`j*Nx[@",
                                        "fields": {
                                            "operator": ">"
                                        },
                                        "inputs": {
                                            "left": {
                                                "block": {
                                                    "type": "bidAmount",
                                                    "id": "}XIG3x8Y^iY|vstMuY(p"
                                                }
                                            },
                                            "right": {
                                                "block": {
                                                    "type": "Max_Bid",
                                                    "id": ":9^Ybu*0MLM`yUk2z/|4"
                                                }
                                            }
                                        }
                                    }
                                },
                                "DO2": {
                                  "block": {
                                        "type": "SetKeywordBidAmount",
                                        "id": "Ax%|X(r?#xj1~A!{yJHz",
                                        "inputs": {
                                            "bid": {
                                                "block": {
                                                    "type": "Max_Bid",
                                                    "id": ":9^Xbu*0MLH`yUk2z/|7"
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    ]
                },
                "vars": []
            },
            "code": "if (spend >= Spend_Cap\n) {\n  SetKeywordBidAmount(id, Min_Bid)\n} else if (bidAmount < Max_Bid\n) {\n  SetKeywordBidAmount(id, (bidAmount + Bid_Increase))\n}\n"
          }
        ],
        kw_journey_3_installs: [
        {
            "type": "trigger_blockly",
            "code": "(campaign.name = \"Zazzle - Discovery\" or campaign.name = \"Zazzle - Generic\" or campaign.name = \"Zazzle Generic - CPP Printing\" or campaign.name = \"Zazzle Generic CPP CustomClothes\" or campaign.name = \"Zazzle Generic - CPPStickers\" or campaign.name = \"Zazzle Generic - CPP Wedding\" or campaign.name = \"Zazzle Generic -CPP Instant Download\" or campaign.name = \"Zazzle Generic CPP Birthday\" or campaign.name = \"Zazzle Generic - CPP CustomHome\") and (keyword.tapInstalls > 0 and keyword.goalEventCount = 0 and campaign.state = \"ENABLED\" and adgroup.state = \"ENABLED\" and keyword.state = \"ACTIVE\") order by keyword.spend desc limit 500",
            "isValid": true,
            "title": "Trigger",
            "state": {
                "languageVersion": 0,
                "blocks": [
                    {
                        "type": "query_join",
                        "id": "9REcLB3V`fxN~:,Q=J-S",
                        "x": 126,
                        "y": 49,
                        "extraState": {
                            "itemCount": 5,
                            "customName": "Query"
                        },
                        "inputs": {
                            "ADD0": {
                                "block": {
                                    "type": "query_any_of",
                                    "id": ",Xg#yBQnS=b[j?wsfr=Q",
                                    "extraState": {
                                        "itemCount": 2,
                                        "customName": "Any Of"
                                    },
                                    "inputs": {
                                        "ADD0": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "2dc{z0F[SOBh$,KQ0:e*",
                                                "fields": {
                                                    "operator": "="
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "X*@EB^gUXI3/?yFIGXUy",
                                                            "fields": {
                                                                "category": "campaign",
                                                                "asavar": "name"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "text",
                                                            "id": "P+}t^t=*MzB?(L(btG*z",
                                                            "fields": {
                                                                "TEXT": "My Campaign 1"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        "ADD1": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "U#wr*P)$Xlb2U]Ji-J1z",
                                                "fields": {
                                                    "operator": "="
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "qAp{ObE1afNOm6CQEB@j",
                                                            "fields": {
                                                                "category": "campaign",
                                                                "asavar": "name"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "text",
                                                            "id": "z0l7hA1jckiW~6}qgf~T",
                                                            "fields": {
                                                                "TEXT": "My Campaign 2"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            },
                            "ADD1": {
                                "block": {
                                    "type": "and_query",
                                    "id": "NmLvLG|5xU_l5q%]m@1="
                                }
                            },
                            "ADD2": {
                                "block": {
                                    "type": "query_all_of",
                                    "id": "E)N4b9{yXa6eZLQ~#.F,",
                                    "extraState": {
                                        "itemCount": 5,
                                        "customName": "All Of"
                                    },
                                    "inputs": {
                                        "ADD0": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "tf6f``BP$t9cM7[`W1:_",
                                                "fields": {
                                                    "operator": ">"
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "9oCKgcbv{ZrH]g$;$p0?",
                                                            "fields": {
                                                                "category": "keyword",
                                                                "asavar": "tapInstalls"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "math_number",
                                                            "id": "H`+}oMZ@-c=1}j7K_^`[",
                                                            "fields": {
                                                                "NUM": 0
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        "ADD1": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "fXiV038HeE%^YE@Z{G(J",
                                                "fields": {
                                                    "operator": "="
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "4O7#d-A0%t`;IIH4Yg@F",
                                                            "fields": {
                                                                "category": "keyword",
                                                                "asavar": "goalEventCount"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "math_number",
                                                            "id": "XkGMkOyVcRMCyG[7vlt]",
                                                            "fields": {
                                                                "NUM": 0
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        "ADD2": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "Eg=E%xoGYJe!57qTRuiA",
                                                "fields": {
                                                    "operator": "="
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "%H]jP1U:2-^)fXsQcREJ",
                                                            "fields": {
                                                                "category": "campaign",
                                                                "asavar": "state"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "text",
                                                            "id": "QWDW8!u@)!_c?P:2XPC`",
                                                            "fields": {
                                                                "TEXT": "ENABLED"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        "ADD3": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "17??n?AJ`[(F*=AILN50",
                                                "fields": {
                                                    "operator": "="
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "EIYJEh6Tx-wY.@E.!.Mm",
                                                            "fields": {
                                                                "category": "adgroup",
                                                                "asavar": "state"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "text",
                                                            "id": "7=Y21ugpfq5{(;G|1uIu",
                                                            "fields": {
                                                                "TEXT": "ENABLED"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        "ADD4": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "D1im,Jz|C/*VB14xoxV`",
                                                "fields": {
                                                    "operator": "="
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": ":F!k9r3NMBoq|X5JMqpU",
                                                            "fields": {
                                                                "category": "keyword",
                                                                "asavar": "state"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "text",
                                                            "id": "lw39^B;?3gJpH9NQK$KQ",
                                                            "fields": {
                                                                "TEXT": "ACTIVE"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            },
                            "ADD3": {
                                "block": {
                                    "type": "orderby_query",
                                    "id": "2l7^;gk7m#(xwc`q|rgv",
                                    "fields": {
                                        "sort_order": "desc"
                                    },
                                    "inputs": {
                                        "field": {
                                            "block": {
                                                "type": "target_query",
                                                "id": "MkBS4qc1^n,CC(`%dB(4",
                                                "fields": {
                                                    "category": "keyword",
                                                    "asavar": "spend"
                                                }
                                            }
                                        }
                                    }
                                }
                            },
                            "ADD4": {
                                "block": {
                                    "type": "limit_query",
                                    "id": "C5%Uf[}xIe0N!^GH`?z/",
                                    "inputs": {
                                        "limit": {
                                            "block": {
                                                "type": "math_number",
                                                "id": "7Q;MGSpg9G+vQ[hY?N]S",
                                                "fields": {
                                                    "NUM": 500
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                ]
            },
            "label": "Trigger",
            "titleEditable": false
        },
        {
            "isValid": true,
            "label": "One-Time Action",
            "type": "onetime_blockly",
            "title": "One-Time Action",
            "titleEditable": false,
            "state": {
                "blocks": {
                    "languageVersion": 0,
                    "blocks": [
                        {
                            "type": "variables_set",
                            "id": "]zm$QBHo+8eVQe84h8+n",
                            "x": 49,
                            "y": 52,
                            "fields": {
                                "VAR": {
                                    "id": "pEfZ;nU?h]`67D6;*2hD"
                                }
                            },
                            "inputs": {
                                "VALUE": {
                                    "block": {
                                        "type": "math_number",
                                        "id": "8kR#t3}[;|lw[MB:~$,t",
                                        "fields": {
                                            "NUM": 0.01
                                        }
                                    }
                                }
                            },
                            "next": {
                                "block": {
                                    "type": "variables_set",
                                    "id": "ZbC%O5e1D1:;v48dGxuF",
                                    "fields": {
                                        "VAR": {
                                            "id": "+biDD?D}nNdoy)fEI3+8"
                                        }
                                    },
                                    "inputs": {
                                        "VALUE": {
                                            "block": {
                                                "type": "math_number",
                                                "id": "ZkN4C]NaKr4MkXmBBBdY",
                                                "fields": {
                                                    "NUM": 7
                                                }
                                            }
                                        }
                                    },
                                    "next": {
                                        "block": {
                                            "type": "variables_set",
                                            "id": "U=LU2z%nil.d0f4mN^Ui",
                                            "fields": {
                                                "VAR": {
                                                    "id": ":/kyBQ*GjFBo50Zn;pz9"
                                                }
                                            },
                                            "inputs": {
                                                "VALUE": {
                                                    "block": {
                                                        "type": "math_number",
                                                        "id": "p+KP~k}lz%AUpka=3Jh#",
                                                        "fields": {
                                                            "NUM": 5
                                                        }
                                                    }
                                                }
                                            },
                                            "next": {
                                                "block": {
                                                    "type": "variables_set",
                                                    "id": "%b^(b3Ti5g@|maiifJK#",
                                                    "fields": {
                                                        "VAR": {
                                                            "id": "L_JzuU482z_.SIfbU7#."
                                                        }
                                                    },
                                                    "inputs": {
                                                        "VALUE": {
                                                            "block": {
                                                                "type": "math_number",
                                                                "id": "X;}s-C_G.)P;K#G=PXZh",
                                                                "fields": {
                                                                    "NUM": 4
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    ]
                },
                "vars": [
                    {
                        "name": "Min Bid",
                        "id": "pEfZ;nU?h]`67D6;*2hD"
                    },
                    {
                        "name": "Max Bid",
                        "id": "+biDD?D}nNdoy)fEI3+8"
                    },
                    {
                        "name": "Spend Cap",
                        "id": ":/kyBQ*GjFBo50Zn;pz9"
                    },
                    {
                        "name": "Target CPI",
                        "id": "L_JzuU482z_.SIfbU7#."
                    }
                ]
            },
            "code": "var Min_Bid, Max_Bid, Spend_Cap, Target_CPI;\n\n\nMin_Bid = 0.01;\nMax_Bid = 7;\nSpend_Cap = 5;\nTarget_CPI = 4;\n"
        },
        {
            "isValid": true,
            "label": "Keyword Action",
            "type": "keyword_blockly",
            "title": "Keyword Action",
            "titleEditable": false,
            "state": {
                "blocks": {
                    "languageVersion": 0,
                    "blocks": [
                        {
                            "type": "controls_if",
                            "id": "Xa3TAL*]4{gMFpr*Z(JQ",
                            "x": 46,
                            "y": 234,
                            "inputs": {
                                "IF0": {
                                    "block": {
                                        "type": "logic_compare_any",
                                        "id": "#hr^4~I}aG`r$vXUlLSA",
                                        "fields": {
                                            "operator": ">="
                                        },
                                        "inputs": {
                                            "left": {
                                                "block": {
                                                    "type": "spend",
                                                    "id": "o}kdIac!(Up4lT,wx?g`"
                                                }
                                            },
                                            "right": {
                                                "block": {
                                                    "type": "Spend_Cap",
                                                    "id": "cFhJ{nY18feRz,X#gH0U"
                                                }
                                            }
                                        }
                                    }
                                },
                                "DO0": {
                                    "block": {
                                        "type": "variables_set",
                                        "id": "QjY4z^c1w^.TYx$T]ag8",
                                        "fields": {
                                            "VAR": {
                                                "id": ";ZU]rE=8tl@x2Yn2|vtN"
                                            }
                                        },
                                        "inputs": {
                                            "VALUE": {
                                                "block": {
                                                    "type": "Min_Bid",
                                                    "id": "L`5bh^A#OW:#Ya7tp,e_"
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "type": "variables_set",
                            "id": "}h~G|fe%veL16i-QLfC4",
                            "x": 43,
                            "y": 30,
                            "fields": {
                                "VAR": {
                                    "id": ";ZU]rE=8tl@x2Yn2|vtN"
                                }
                            },
                            "inputs": {
                                "VALUE": {
                                    "block": {
                                        "type": "math_arithmetic",
                                        "id": "KBMb.?0j?=mbVz94^mQ|",
                                        "fields": {
                                            "OP": "DIVIDE"
                                        },
                                        "inputs": {
                                            "A": {
                                                "block": {
                                                    "type": "math_arithmetic",
                                                    "id": "rkchsikM@To{;)pOXjjf",
                                                    "fields": {
                                                        "OP": "MULTIPLY"
                                                    },
                                                    "inputs": {
                                                        "A": {
                                                            "block": {
                                                                "type": "Target_CPI",
                                                                "id": "0y?`yGkx8LC^1kekHc{."
                                                            }
                                                        },
                                                        "B": {
                                                            "block": {
                                                                "type": "tapInstalls",
                                                                "id": "}hJIyYDfbMWM7MozaIZ9"
                                                            }
                                                        }
                                                    }
                                                }
                                            },
                                            "B": {
                                                "block": {
                                                    "type": "taps",
                                                    "id": ":vlra8j9KGtYIuU=k)6Q"
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "type": "controls_if",
                            "id": "[:F9D%~s/D[iI%q81/ll",
                            "x": 45,
                            "y": 355,
                            "inputs": {
                                "IF0": {
                                    "block": {
                                        "type": "logic_compare_any",
                                        "id": "8)U2O)cB/66(6Qs*h|pj",
                                        "fields": {
                                            "operator": "!="
                                        },
                                        "inputs": {
                                            "left": {
                                                "block": {
                                                    "type": "variables_get",
                                                    "id": "{9O5(!Qo=Ka8,l^dv!E6",
                                                    "fields": {
                                                        "VAR": {
                                                            "id": ";ZU]rE=8tl@x2Yn2|vtN"
                                                        }
                                                    }
                                                }
                                            },
                                            "right": {
                                                "block": {
                                                    "type": "bidAmount",
                                                    "id": "[PMK,Y@oH0x+JvybPqi3"
                                                }
                                            }
                                        }
                                    }
                                },
                                "DO0": {
                                    "block": {
                                        "type": "SetKeywordBidAmount",
                                        "id": "Y`^.67Y~R3VY!T(gLLPz",
                                        "inputs": {
                                            "bid": {
                                                "block": {
                                                    "type": "variables_get",
                                                    "id": "AceJ)CF=@P|VG:r3KpZp",
                                                    "fields": {
                                                        "VAR": {
                                                            "id": ";ZU]rE=8tl@x2Yn2|vtN"
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "type": "controls_if",
                            "id": "*/lJE~)];Y#|eY[K~WMB",
                            "x": 47,
                            "y": 119,
                            "inputs": {
                                "IF0": {
                                    "block": {
                                        "type": "logic_compare_any",
                                        "id": "38g%t4ljvePflB7|id[N",
                                        "fields": {
                                            "operator": ">"
                                        },
                                        "inputs": {
                                            "left": {
                                                "block": {
                                                    "type": "variables_get",
                                                    "id": "?=uWY)x^S4)f,PZx}1]{",
                                                    "fields": {
                                                        "VAR": {
                                                            "id": ";ZU]rE=8tl@x2Yn2|vtN"
                                                        }
                                                    }
                                                }
                                            },
                                            "right": {
                                                "block": {
                                                    "type": "Max_Bid",
                                                    "id": "*/01AhLtJ#.u4~951KZ~"
                                                }
                                            }
                                        }
                                    }
                                },
                                "DO0": {
                                    "block": {
                                        "type": "variables_set",
                                        "id": "!Pr*[H]Va/zOQ8CPYETs",
                                        "fields": {
                                            "VAR": {
                                                "id": ";ZU]rE=8tl@x2Yn2|vtN"
                                            }
                                        },
                                        "inputs": {
                                            "VALUE": {
                                                "block": {
                                                    "type": "Max_Bid",
                                                    "id": "W7sbVh6J.iECvWDAnRTn"
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    ]
                },
                "vars": [
                    {
                        "name": "New Bid Amount",
                        "id": ";ZU]rE=8tl@x2Yn2|vtN"
                    }
                ]
            },
            "code": "var New_Bid_Amount;\n\n\nNew_Bid_Amount = (Target_CPI * tapInstalls) / taps;\n\nif (New_Bid_Amount > Max_Bid\n) {\n  New_Bid_Amount = Max_Bid;\n}\n\nif (spend >= Spend_Cap\n) {\n  New_Bid_Amount = Min_Bid;\n}\n\nif (New_Bid_Amount != bidAmount\n) {\n  SetKeywordBidAmount(id, New_Bid_Amount)\n}\n"
        }
        ],
        kw_journey_4_events: [
        {
            "type": "trigger_blockly",
            "code": "(campaign.name = \"Zazzle - Discovery\" or campaign.name = \"Zazzle - Generic\" or campaign.name = \"Zazzle Generic - CPP Printing\" or campaign.name = \"Zazzle Generic CPP CustomClothes\" or campaign.name = \"Zazzle Generic - CPPStickers\" or campaign.name = \"Zazzle Generic - CPP Wedding\" or campaign.name = \"Zazzle Generic -CPP Instant Download\" or campaign.name = \"Zazzle Generic CPP Birthday\" or campaign.name = \"Zazzle Generic - CPP CustomHome\") and (keyword.tapInstalls > 0 and keyword.goalEventCount > 0 and campaign.state = \"ENABLED\" and adgroup.state = \"ENABLED\" and keyword.state = \"ACTIVE\") order by keyword.spend desc limit 500",
            "isValid": true,
            "title": "Trigger",
            "state": {
                "languageVersion": 0,
                "blocks": [
                    {
                        "type": "query_join",
                        "id": "rLVu~f_KP*3s)P(Mu8~#",
                        "x": 92,
                        "y": 61,
                        "extraState": {
                            "itemCount": 5,
                            "customName": "Query"
                        },
                        "inputs": {
                            "ADD0": {
                                "block": {
                                    "type": "query_any_of",
                                    "id": "Zf#lI6M2H~:R:2E^;s@^",
                                    "extraState": {
                                        "itemCount": 2,
                                        "customName": "Any Of"
                                    },
                                    "inputs": {
                                        "ADD0": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "Loh`r9!G~oA^bBILQR9,",
                                                "fields": {
                                                    "operator": "="
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "n@tU~DmS!8]o(LT^CL]@",
                                                            "fields": {
                                                                "category": "campaign",
                                                                "asavar": "name"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "text",
                                                            "id": "xHL@;n%I[`V14V-o:Hkj",
                                                            "fields": {
                                                                "TEXT": "My Campaign 1"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        "ADD1": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "YX*=+_OIv6cGp*9dy)t{",
                                                "fields": {
                                                    "operator": "="
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "(7FvxJ[MDR9Wo)0YrlY=",
                                                            "fields": {
                                                                "category": "campaign",
                                                                "asavar": "name"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "text",
                                                            "id": "koKaTo@k[S4y]x(C7_vS",
                                                            "fields": {
                                                                "TEXT": "My Campaign 2"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            },
                            "ADD1": {
                                "block": {
                                    "type": "and_query",
                                    "id": "grMSgqV5[_4U%=%eu]y)"
                                }
                            },
                            "ADD2": {
                                "block": {
                                    "type": "query_all_of",
                                    "id": "lfIHLu%Q=__@YZ0aL|wf",
                                    "extraState": {
                                        "itemCount": 5,
                                        "customName": "All Of"
                                    },
                                    "inputs": {
                                        "ADD0": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "v)4A_a`=k1/`Dp)W0}Nm",
                                                "fields": {
                                                    "operator": ">"
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "u{A_16QY}6~q6xcM$l}E",
                                                            "fields": {
                                                                "category": "keyword",
                                                                "asavar": "tapInstalls"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "math_number",
                                                            "id": "jo=i^XK}d/evN*[N(w#z",
                                                            "fields": {
                                                                "NUM": 0
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        "ADD1": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "p/KpJ%!Fcg6]k#Cz92B6",
                                                "fields": {
                                                    "operator": ">"
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "M}F25kYn{rs%p9*z*D~}",
                                                            "fields": {
                                                                "category": "keyword",
                                                                "asavar": "goalEventCount"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "math_number",
                                                            "id": "4cxmwWUyn9)2eSR!//[~",
                                                            "fields": {
                                                                "NUM": 0
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        "ADD2": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "(th+L+.w9M:h|G2_Td;T",
                                                "fields": {
                                                    "operator": "="
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "o/C7_GO.u)gSPsK3%9^U",
                                                            "fields": {
                                                                "category": "campaign",
                                                                "asavar": "state"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "text",
                                                            "id": "U3!7#+c7II@cxq0+*l1(",
                                                            "fields": {
                                                                "TEXT": "ENABLED"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        "ADD3": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "n|5#(E9?{7JDPBuWPVXw",
                                                "fields": {
                                                    "operator": "="
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "O[T)i3f@!~^juy-4;Tio",
                                                            "fields": {
                                                                "category": "adgroup",
                                                                "asavar": "state"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "text",
                                                            "id": "{xB*8^Z;y8[uWslVS%Mv",
                                                            "fields": {
                                                                "TEXT": "ENABLED"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        "ADD4": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "{oZ)*Q(*g;Yu$Sy}%5#*",
                                                "fields": {
                                                    "operator": "="
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "8(?^x((;.n.9~:7$qRJC",
                                                            "fields": {
                                                                "category": "keyword",
                                                                "asavar": "state"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "text",
                                                            "id": ".LLxNt.ya=id--PAF(zH",
                                                            "fields": {
                                                                "TEXT": "ACTIVE"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            },
                            "ADD3": {
                                "block": {
                                    "type": "orderby_query",
                                    "id": "}X=+4.X-md{[[]2FYq;/",
                                    "fields": {
                                        "sort_order": "desc"
                                    },
                                    "inputs": {
                                        "field": {
                                            "block": {
                                                "type": "target_query",
                                                "id": "PhAeRqbVgCf7v1[9xPzU",
                                                "fields": {
                                                    "category": "keyword",
                                                    "asavar": "spend"
                                                }
                                            }
                                        }
                                    }
                                }
                            },
                            "ADD4": {
                                "block": {
                                    "type": "limit_query",
                                    "id": "]vVdyWpjH6K`Dj`A!.=Y",
                                    "inputs": {
                                        "limit": {
                                            "block": {
                                                "type": "math_number",
                                                "id": ")Rx~M*CyF4$W7sYyU^$_",
                                                "fields": {
                                                    "NUM": 500
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                ]
            },
            "label": "Trigger",
            "titleEditable": false
        },
        {
            "isValid": true,
            "label": "One-Time Action",
            "type": "onetime_blockly",
            "title": "One-Time Action",
            "titleEditable": false,
            "state": {
                "blocks": {
                    "languageVersion": 0,
                    "blocks": [
                        {
                            "type": "variables_set",
                            "id": "[H3aMaKWg1]#@D;JUTd!",
                            "x": 42,
                            "y": 32,
                            "fields": {
                                "VAR": {
                                    "id": "^qgR4348i!8.[MNT0-#%"
                                }
                            },
                            "inputs": {
                                "VALUE": {
                                    "block": {
                                        "type": "math_number",
                                        "id": ".i1:RpUf-jtN.8waZcns",
                                        "fields": {
                                            "NUM": 8
                                        }
                                    }
                                }
                            },
                            "next": {
                                "block": {
                                    "type": "variables_set",
                                    "id": "ZrU4dQ!g_KVdN3ches?r",
                                    "fields": {
                                        "VAR": {
                                            "id": "?SqmOaf(p?]^_GCz#lGq"
                                        }
                                    },
                                    "inputs": {
                                        "VALUE": {
                                            "block": {
                                                "type": "math_number",
                                                "id": ")W0IYRkf.YOpA7Pvzh:m",
                                                "fields": {
                                                    "NUM": 21
                                                }
                                            }
                                        }
                                    },
                                    "next": {
                                        "block": {
                                            "type": "variables_set",
                                            "id": "491OWQh8lISS)@7;9/yF",
                                            "fields": {
                                                "VAR": {
                                                    "id": "_(n5~ts(zIjFb,,bqK11"
                                                }
                                            },
                                            "inputs": {
                                                "VALUE": {
                                                    "block": {
                                                        "type": "math_number",
                                                        "id": "lg@cmoT+Bf}ME$pbc5Uf",
                                                        "fields": {
                                                            "NUM": 1
                                                        }
                                                    }
                                                }
                                            },
                                            "next": {
                                                "block": {
                                                    "type": "variables_set",
                                                    "id": "|wv(ohk(Y5}guRBz,EdG",
                                                    "fields": {
                                                        "VAR": {
                                                            "id": "!G^5ANd+IH=505Y9oZG0"
                                                        }
                                                    },
                                                    "inputs": {
                                                        "VALUE": {
                                                            "block": {
                                                                "type": "math_number",
                                                                "id": "=XPOZ{|5X1MP}0u^K_6V",
                                                                "fields": {
                                                                    "NUM": 10
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    ]
                },
                "vars": [
                    {
                        "name": "Max Bid",
                        "id": "^qgR4348i!8.[MNT0-#%"
                    },
                    {
                        "name": "Target CPG",
                        "id": "?SqmOaf(p?]^_GCz#lGq"
                    },
                    {
                        "name": "Max Boost Multiplier",
                        "id": "_(n5~ts(zIjFb,,bqK11"
                    },
                    {
                        "name": "Established Event Count",
                        "id": "!G^5ANd+IH=505Y9oZG0"
                    }
                ]
            },
            "code": "var Max_Bid, Target_CPG, Max_Boost_Multiplier, Established_Event_Count;\n\n\nMax_Bid = 8;\nTarget_CPG = 21;\nMax_Boost_Multiplier = 1;\nEstablished_Event_Count = 10;\n"
        },
        {
            "isValid": true,
            "label": "Keyword Action",
            "type": "keyword_blockly",
            "title": "Keyword Action",
            "titleEditable": false,
            "state": {
                "blocks": {
                    "languageVersion": 0,
                    "blocks": [
                        {
                            "type": "variables_set",
                            "id": ";}Ox1-3v+:QZ0%S%I9NE",
                            "x": 32,
                            "y": 34,
                            "fields": {
                                "VAR": {
                                    "id": "x5CyVNIoj2RIcBpLHZ5/"
                                }
                            },
                            "inputs": {
                                "VALUE": {
                                    "block": {
                                        "type": "math_arithmetic",
                                        "id": "kt0(YiXY$9aA;.?c=m2z",
                                        "fields": {
                                            "OP": "DIVIDE"
                                        },
                                        "inputs": {
                                            "A": {
                                                "block": {
                                                    "type": "math_arithmetic",
                                                    "id": "kSoR??N.Qm_?-ez-fO4F",
                                                    "fields": {
                                                        "OP": "MULTIPLY"
                                                    },
                                                    "inputs": {
                                                        "A": {
                                                            "block": {
                                                                "type": "Target_CPG",
                                                                "id": "8Uc`i~|kz{lFNyVEFEK9"
                                                            }
                                                        },
                                                        "B": {
                                                            "block": {
                                                                "type": "goalEventCount",
                                                                "id": "1/}mu5h*P[:Vm*kEH;,R"
                                                            }
                                                        }
                                                    }
                                                }
                                            },
                                            "B": {
                                                "block": {
                                                    "type": "taps",
                                                    "id": ".:bO4UnJh5Aw,P#ma*tp"
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "type": "controls_if",
                            "id": ")E*=|6cHM4vSn1wUP|]@",
                            "x": 31,
                            "y": 114,
                            "inputs": {
                                "IF0": {
                                    "block": {
                                        "type": "logic_compare_any",
                                        "id": "ZXy~a@1e$}=Gz7s{XbV(",
                                        "fields": {
                                            "operator": "<"
                                        },
                                        "inputs": {
                                            "left": {
                                                "block": {
                                                    "type": "goalEventCount",
                                                    "id": "tcM3tk,ob}To]f7MNx,@"
                                                }
                                            },
                                            "right": {
                                                "block": {
                                                    "type": "Established_Event_Count",
                                                    "id": "PvNm*2rKK}2,HozQdDbr"
                                                }
                                            }
                                        }
                                    }
                                },
                                "DO0": {
                                    "block": {
                                        "type": "variables_set",
                                        "id": "k%KE//NR+i*4}?1u]Yb^",
                                        "fields": {
                                            "VAR": {
                                                "id": "ln{vYHM(y+o]{Ek-m#}%"
                                            }
                                        },
                                        "inputs": {
                                            "VALUE": {
                                                "block": {
                                                    "type": "math_arithmetic",
                                                    "id": "(,mE-#Vlaa%[UGaeSO*0",
                                                    "fields": {
                                                        "OP": "ADD"
                                                    },
                                                    "inputs": {
                                                        "A": {
                                                            "block": {
                                                                "type": "math_arithmetic",
                                                                "id": "N~b|QI{+!B?x,I$+[rcs",
                                                                "fields": {
                                                                    "OP": "MULTIPLY"
                                                                },
                                                                "inputs": {
                                                                    "A": {
                                                                        "block": {
                                                                            "type": "math_arithmetic",
                                                                            "id": "38aPy]o=EGpQSez#.,ET",
                                                                            "fields": {
                                                                                "OP": "DIVIDE"
                                                                            },
                                                                            "inputs": {
                                                                                "A": {
                                                                                    "block": {
                                                                                        "type": "math_arithmetic",
                                                                                        "id": "~Y`i3S!?3uWx|gXeQhA+",
                                                                                        "fields": {
                                                                                            "OP": "MULTIPLY"
                                                                                        },
                                                                                        "inputs": {
                                                                                            "A": {
                                                                                                "block": {
                                                                                                    "type": "math_number",
                                                                                                    "id": "$2G8Clz_O[Ss+stGM[}^",
                                                                                                    "fields": {
                                                                                                        "NUM": -1
                                                                                                    }
                                                                                                }
                                                                                            },
                                                                                            "B": {
                                                                                                "block": {
                                                                                                    "type": "Max_Boost_Multiplier",
                                                                                                    "id": "etBsHS,xYg`AClnJvEa("
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                },
                                                                                "B": {
                                                                                    "block": {
                                                                                        "type": "Established_Event_Count",
                                                                                        "id": "TnhrMqn=!wiTpN6du7*B"
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    },
                                                                    "B": {
                                                                        "block": {
                                                                            "type": "goalEventCount",
                                                                            "id": "2}u`o[adIz_tn=)CW6a9"
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        },
                                                        "B": {
                                                            "block": {
                                                                "type": "Max_Boost_Multiplier",
                                                                "id": "Kjt9TIZ;,;g00czF0*NH"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        "next": {
                                            "block": {
                                                "type": "variables_set",
                                                "id": "Nt!6~mOqYu.OI{Mtj{/Y",
                                                "fields": {
                                                    "VAR": {
                                                        "id": "x5CyVNIoj2RIcBpLHZ5/"
                                                    }
                                                },
                                                "inputs": {
                                                    "VALUE": {
                                                        "block": {
                                                            "type": "math_arithmetic",
                                                            "id": "3bYTZWnTb^aWfSSd|`Tz",
                                                            "fields": {
                                                                "OP": "ADD"
                                                            },
                                                            "inputs": {
                                                                "A": {
                                                                    "block": {
                                                                        "type": "math_arithmetic",
                                                                        "id": "D#7}tgU#7@bHq_*60mm7",
                                                                        "fields": {
                                                                            "OP": "MULTIPLY"
                                                                        },
                                                                        "inputs": {
                                                                            "A": {
                                                                                "block": {
                                                                                    "type": "variables_get",
                                                                                    "id": "T7RBty1}1pHuwn}L8Lz6",
                                                                                    "fields": {
                                                                                        "VAR": {
                                                                                            "id": "x5CyVNIoj2RIcBpLHZ5/"
                                                                                        }
                                                                                    }
                                                                                }
                                                                            },
                                                                            "B": {
                                                                                "block": {
                                                                                    "type": "variables_get",
                                                                                    "id": "I!j*sx!8jhlN?Dm*5pw7",
                                                                                    "fields": {
                                                                                        "VAR": {
                                                                                            "id": "ln{vYHM(y+o]{Ek-m#}%"
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                },
                                                                "B": {
                                                                    "block": {
                                                                        "type": "variables_get",
                                                                        "id": "G[jq*5[$sZnf@$d4SIaD",
                                                                        "fields": {
                                                                            "VAR": {
                                                                                "id": "x5CyVNIoj2RIcBpLHZ5/"
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "type": "controls_if",
                            "id": "S]=,wg.eM??@$j:C!aSu",
                            "x": 31,
                            "y": 327,
                            "inputs": {
                                "IF0": {
                                    "block": {
                                        "type": "logic_compare_any",
                                        "id": "ok$HpkazTX+JAn9S9F]t",
                                        "fields": {
                                            "operator": ">"
                                        },
                                        "inputs": {
                                            "left": {
                                                "block": {
                                                    "type": "variables_get",
                                                    "id": "0Lwj0VCi2Fw~ViKXhx%y",
                                                    "fields": {
                                                        "VAR": {
                                                            "id": "x5CyVNIoj2RIcBpLHZ5/"
                                                        }
                                                    }
                                                }
                                            },
                                            "right": {
                                                "block": {
                                                    "type": "Max_Bid",
                                                    "id": "qO]!H^![~w/~p7_D1N]/"
                                                }
                                            }
                                        }
                                    }
                                },
                                "DO0": {
                                    "block": {
                                        "type": "variables_set",
                                        "id": "ccHk-uhYONhzj7X0i,}V",
                                        "fields": {
                                            "VAR": {
                                                "id": "x5CyVNIoj2RIcBpLHZ5/"
                                            }
                                        },
                                        "inputs": {
                                            "VALUE": {
                                                "block": {
                                                    "type": "Max_Bid",
                                                    "id": "SD9[0EU[gg9wj*Mq=1K2"
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        {
                            "type": "controls_if",
                            "id": "y,b,s|uXTloI]+|TsL=*",
                            "x": 32,
                            "y": 449,
                            "inputs": {
                                "IF0": {
                                    "block": {
                                        "type": "logic_compare_any",
                                        "id": "@2xAU:``$5=FNttr1D|9",
                                        "fields": {
                                            "operator": "!="
                                        },
                                        "inputs": {
                                            "left": {
                                                "block": {
                                                    "type": "variables_get",
                                                    "id": "7uqzML1BZp*G%C4H-@Dn",
                                                    "fields": {
                                                        "VAR": {
                                                            "id": "x5CyVNIoj2RIcBpLHZ5/"
                                                        }
                                                    }
                                                }
                                            },
                                            "right": {
                                                "block": {
                                                    "type": "bidAmount",
                                                    "id": "IS#DRq~9gG$P:~cHeu|x"
                                                }
                                            }
                                        }
                                    }
                                },
                                "DO0": {
                                    "block": {
                                        "type": "SetKeywordBidAmount",
                                        "id": "$#|5K[L)d@KbG0gZ_;lj",
                                        "inputs": {
                                            "bid": {
                                                "block": {
                                                    "type": "variables_get",
                                                    "id": "~4N+2A_a4QzhVmW82(ge",
                                                    "fields": {
                                                        "VAR": {
                                                            "id": "x5CyVNIoj2RIcBpLHZ5/"
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    ]
                },
                "vars": [
                    {
                        "name": "New Bid Amount",
                        "id": "x5CyVNIoj2RIcBpLHZ5/"
                    },
                    {
                        "name": "Boost",
                        "id": "ln{vYHM(y+o]{Ek-m#}%"
                    }
                ]
            },
            "code": "var New_Bid_Amount, Boost;\n\n\nNew_Bid_Amount = (Target_CPG * goalEventCount) / taps;\n\nif (goalEventCount < Established_Event_Count\n) {\n  Boost = ((-1 * Max_Boost_Multiplier) / Established_Event_Count) * goalEventCount + Max_Boost_Multiplier;\n  New_Bid_Amount = New_Bid_Amount * Boost + New_Bid_Amount;\n}\n\nif (New_Bid_Amount > Max_Bid\n) {\n  New_Bid_Amount = Max_Bid;\n}\n\nif (New_Bid_Amount != bidAmount\n) {\n  SetKeywordBidAmount(id, New_Bid_Amount)\n}\n"
        }
        ],
        kw_performance_report: [
          {
            "type": "trigger_blockly",
            "code": "\n(campaign.name = 'My Campaign 1' or campaign.name = 'My Campaign 2') and keyword.taps > 0 order by keyword.tapInstalls desc limit 500\n",
            "isValid": true,
            "title": "Trigger",
            "state": {
                "languageVersion": 0,
                "blocks": [
                    {
                        "type": "query_join",
                        "id": "_l~BE-;ab]I?Z0}j!D(X",
                        "x": 122,
                        "y": 45,
                        "extraState": {
                            "itemCount": 5,
                            "customName": "Query"
                        },
                        "inputs": {
                            "ADD0": {
                                "block": {
                                    "type": "query_any_of",
                                    "id": "GH:sqI*WlTD*YA0@U)Sg",
                                    "extraState": {
                                        "itemCount": 2,
                                        "customName": "Any Of"
                                    },
                                    "inputs": {
                                        "ADD0": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "uG*aA5|Hl/m4XcmN]PpA",
                                                "fields": {
                                                    "operator": "="
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "l7!)f%6QZbEvj:S8]q~n",
                                                            "fields": {
                                                                "category": "campaign",
                                                                "asavar": "name"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "text",
                                                            "id": ":|mhiC_OmB=idp$}o@s%",
                                                            "fields": {
                                                                "TEXT": "My Campaign 1"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        },
                                        "ADD1": {
                                            "block": {
                                                "type": "logic_compare_sql",
                                                "id": "dvT%uC;-(XOp4vnSAnU*",
                                                "fields": {
                                                    "operator": "="
                                                },
                                                "inputs": {
                                                    "left": {
                                                        "block": {
                                                            "type": "target_query",
                                                            "id": "1ecZG+6/bd;88T|y:T,P",
                                                            "fields": {
                                                                "category": "campaign",
                                                                "asavar": "name"
                                                            }
                                                        }
                                                    },
                                                    "right": {
                                                        "block": {
                                                            "type": "text",
                                                            "id": "-+h4YqiGQ|V]A9j.{ej|",
                                                            "fields": {
                                                                "TEXT": "My Campaign 2"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            },
                            "ADD1": {
                                "block": {
                                    "type": "and_query",
                                    "id": "Fg9Mb[973fS!46PaMOiM"
                                }
                            },
                            "ADD2": {
                              "block": {
                                "type": "logic_compare_sql",
                                "id": "S4d%jUJVxp%*d=#*t#g*",
                                "fields": {
                                    "operator": ">"
                                },
                                "inputs": {
                                    "left": {
                                        "block": {
                                            "type": "target_query",
                                            "id": "FDTbjLgAKV.a(-oK*GQJ",
                                            "fields": {
                                                "category": "keyword",
                                                "asavar": "taps"
                                            }
                                        }
                                    },
                                    "right": {
                                        "block": {
                                            "type": "math_number",
                                            "id": "NIVJ2-qV{:4!Gygs[a_y",
                                            "fields": {
                                                "NUM": 0
                                            }
                                        }
                                    }
                                }
                              }
                            },
                            "ADD3": {
                                "block": {
                                    "type": "orderby_query",
                                    "id": "pna)2{to-Yy%W1U,!C}R",
                                    "fields": {
                                        "sort_order": "desc"
                                    },
                                    "inputs": {
                                        "field": {
                                            "block": {
                                                "type": "target_query",
                                                "id": "+U8s[)/t$9Cg:N{0gktZ",
                                                "fields": {
                                                    "category": "keyword",
                                                    "asavar": "tapInstalls"
                                                }
                                            }
                                        }
                                    }
                                }
                            },
                            "ADD4": {
                                "block": {
                                    "type": "limit_query",
                                    "id": "kvLVfpa~O@lUGi)(KB!.",
                                    "inputs": {
                                        "limit": {
                                            "block": {
                                                "type": "math_number",
                                                "id": "jJ7,ZFI;7}c.sKo2!zFP",
                                                "fields": {
                                                    "NUM": 500
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                ]
            },
            "label": "Trigger",
            "titleEditable": false
          },
          {
            "isValid": true,
            "label": "One-Time Action",
            "type": "onetime_blockly",
            "title": "One-Time Action",
            "titleEditable": false,
            "code": "var _report;\n_report = (CreateReport('Keyword Performance Report', ['Keyword', 'Spend', 'Bid', 'Imps', 'Taps', 'TapInstalls', 'Goals', 'TapAvgCPA', 'CPG']));\n;",
            "state": {
                "blocks": {
                    "languageVersion": 0,
                    "blocks": [
                    {
                      "type": "variables_set",
                      "id": "-P._hP-di:ybBALwa(B(",
                      "x": 0,
                      "y": 26,
                      "fields": {
                          "VAR": {
                              "id": "UDPjAS}2/i4dkOn[KEh8"
                          }
                      },
                      "inputs": {
                          "VALUE": {
                              "block": {
                                  "type": "CreateReport_onetime",
                                  "id": "P5Yznhy@KPs=}:?8Esth",
                                  "inputs": {
                                      "name": {
                                          "block": {
                                              "type": "text",
                                              "id": "8un|.H*`(6T/FRnH]|;)",
                                              "fields": {
                                                  "TEXT": "Keyword Performance Report"
                                              }
                                          }
                                      },
                                      "headers": {
                                          "block": {
                                              "type": "lists_join_many",
                                              "id": "4+H]6;%ND_a_=cc:a047",
                                              "extraState": {
                                                  "itemCount": 9,
                                                  "customName": "Create List With"
                                              },
                                              "inputs": {
                                                  "ADD0": {
                                                      "block": {
                                                          "type": "text",
                                                          "id": "A{mPhAuRL}b3}5yXpU.g",
                                                          "fields": {
                                                              "TEXT": "Keyword"
                                                          }
                                                      }
                                                  },
                                                  "ADD1": {
                                                      "block": {
                                                          "type": "text",
                                                          "id": "eu^;P4tu*E;1O_8bGAnP",
                                                          "fields": {
                                                              "TEXT": "Spend"
                                                          }
                                                      }
                                                  },
                                                  "ADD2": {
                                                      "block": {
                                                          "type": "text",
                                                          "id": "h*nNe(*S;VH7Ufi;iP8s",
                                                          "fields": {
                                                              "TEXT": "Bid"
                                                          }
                                                      }
                                                  },
                                                  "ADD3": {
                                                      "block": {
                                                          "type": "text",
                                                          "id": "8`+w!?,N54,O9C!c1:oa",
                                                          "fields": {
                                                              "TEXT": "Imps"
                                                          }
                                                      }
                                                  },
                                                  "ADD4": {
                                                      "block": {
                                                          "type": "text",
                                                          "id": "L~7N,f.RK_-Grk1dO~wZ",
                                                          "fields": {
                                                              "TEXT": "Taps"
                                                          }
                                                      }
                                                  },
                                                  "ADD5": {
                                                    "block": {
                                                          "type": "text",
                                                          "id": "nUP]q}[jpO9+){CXDdd+",
                                                          "fields": {
                                                              "TEXT": "TapInstalls"
                                                          }
                                                      }
                                                  },
                                                  "ADD6": {
                                                      "block": {
                                                          "type": "text",
                                                          "id": "Hi40Obva[-KyF:cM?RgS",
                                                          "fields": {
                                                              "TEXT": "Goals"
                                                          }
                                                      }
                                                  },
                                                  "ADD7": {
                                                      "block": {
                                                          "type": "text",
                                                          "id": "Gi40Obva[-KyF:cM?RgS",
                                                          "fields": {
                                                              "TEXT": "TapAvgCPA"
                                                          }
                                                      }
                                                  },
                                                  "ADD8": {
                                                      "block": {
                                                          "type": "text",
                                                          "id": "Ft40Obva[-KyF:cM?RgS",
                                                          "fields": {
                                                              "TEXT": "CPG"
                                                          }
                                                      }
                                                  }
                                              }
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
                    ]
                },
                "vars": [
                    {
                        "name": "_report",
                        "id": "UDPjAS}2/i4dkOn[KEh8",
                    },
                ]
            },
          },
          {
            "isValid": true,
            "label": "Keyword Action",
            "type": "keyword_blockly",
            "title": "Keyword Action",
            "titleEditable": false,            
            "code": "\n_report.AddRow([name, spend, bidAmount, impressions, taps, tapInstalls, goalEventCount, tapAvgCPA, goalCostPer])\n",
            "state": {
                "blocks": {
                    "languageVersion": 0,
                    "blocks": [
                    {
                      "type": "AddRow",
                      "id": "hqHOE@._v70)=nm4Jvba",
                      "x": 0,
                      "y": 2,
                      "inputs": {
                          "report_variable": {
                              "block": {
                                  "type": "_report",
                                  "id": "SD9[0EU[gg9wj*Mq=1K3"
                              }
                          },
                          "variables": {
                              "block": {
                                  "type": "lists_join_many",
                                  "id": "Iv9eQl9/qUWjhw.*^TuX",
                                  "extraState": {
                                      "itemCount": 9,
                                      "customName": "Create List With"
                                  },
                                  "inputs": {
                                      "ADD0": {
                                          "block": {
                                              "type": "name",
                                              "id": "4jo#mP2pII)}Sq?)SN7A"
                                          }
                                      },
                                      "ADD1": {
                                          "block": {
                                              "type": "spend",
                                              "id": "9Z8wpD%S3=ntx7h|X7`!"
                                          }
                                      },
                                      "ADD2": {
                                          "block": {
                                              "type": "bidAmount",
                                              "id": "4o7j%CHty}1m-mg*t#d+"
                                          }
                                      },
                                      "ADD3": {
                                          "block": {
                                              "type": "impressions",
                                              "id": "d(:T@)TanTCI{@I4K!U*"
                                          }
                                      },
                                      "ADD4": {
                                          "block": {
                                              "type": "taps",
                                              "id": "^4se_ka?.QLc!^d~m}bK"
                                          }
                                      },
                                      "ADD5": {
                                          "block": {
                                              "type": "tapInstalls",
                                              "id": "3B]dDk,)Z3o*b=}s4!5H"
                                          }
                                      },
                                      "ADD6": {
                                          "block": {
                                              "type": "goalEventCount",
                                              "id": "E_ao?.l^BEUv|hhQfu:v"
                                          }
                                      },
                                      "ADD7": {
                                          "block": {
                                              "type": "tapAvgCPA",
                                              "id": "JdT,qn;;sg|`|t-I)DU3"
                                          }
                                      },
                                      "ADD8": {
                                          "block": {
                                              "type": "goalCostPer",
                                              "id": "*5hLGi0{)_`lOdp)mrK2"
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
                    ]
                }
            },
          },
          {
            "isValid": true,
            "label": "One-Time Action",
            "type": "onetime_blockly",
            "title": "One-Time Action",
            "titleEditable": false,
            "code": "\nSendEmailMultiple(['user1@example.com', 'user2@example.com'], 'Subject', 'Body',[_report])\n",
            "state": {
                "blocks": {
                    "languageVersion": 0,
                    "blocks": [
                    {
                      "type": "SendReportMultiple_onetime",
                      "id": "STL1:.P_`aBhn6|Ub$s:",
                      "x": 0,
                      "y": 36,
                      "inputs": {
                          "email": {
                              "block": {
                                  "type": "lists_join_many",
                                  "id": "1/oq5~kFpz_1F/u5hshx",
                                  "extraState": {
                                      "itemCount": 2,
                                      "customName": ""
                                  },
                                  "inputs": {
                                      "ADD0": {
                                          "block": {
                                              "type": "text",
                                              "id": "7DLii;|-_XMJQE7Y3F0S",
                                              "fields": {
                                                  "TEXT": "user1@example.com"
                                              }
                                          }
                                      },
                                      "ADD1": {
                                          "block": {
                                              "type": "text",
                                              "id": "xn2Wt,aNkv?EL/QPFu=p",
                                              "fields": {
                                                  "TEXT": "user2@example.com"
                                              }
                                          }
                                      }
                                  }
                              }
                          },
                          "subject": {
                              "block": {
                                  "type": "text",
                                  "id": "7@orV5a7+|buWM@NOhw+",
                                  "fields": {
                                      "TEXT": "Subject"
                                  }
                              }
                          },
                          "body": {
                              "block": {
                                  "type": "text",
                                  "id": "lI?S7$kA4)/D:,=4u4wy",
                                  "fields": {
                                      "TEXT": "Body"
                                  }
                              }
                          },
                          // "report": {
                          //     "block": {
                          //         "type": "_report",
                          //         "id": "SD9[0EU[gg9wj*Mq=1K4"
                          //     }
                          // },
                          "report": {
                              "block": {
                                  "type": "lists_join_many",
                                  "id": "1/oq5~kFpz_1F/u5hshy",
                                  "extraState": {
                                      "itemCount": 1,
                                      "customName": ""
                                  },
                                  "inputs": {
                                      "ADD0": {
                                          "block": {
                                            "type": "_report",
                                            "id": "SD9[0EU[gg9wj*Mq=1K5"
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
                    ]
                }
            },
          },
        ],
      },
      goalFields: ["goalEventCount", "goalRevenue", "goalRevenuePer", "goalCostPer", "goalROAS", "goalARPU", "goalAMPU"],
      sovFields: ["SOVTerm","SOVImpressionShareHigh","SOVImpressionShareLow","SOVRank","SOVSearchPopularity","SOVRegion","SOVMatched","SOVMatchedDaysAgo"],
      triggerVarMap: {
        asaFields: ['campaign', 'adgroup', 'keyword', 'select'],
        campaignProps : ["name", "id", "state", "dailyBudget", "budget", "cpm", "spend", "tapAvgCPA", "totalAvgCPA", "cpt", "impressions", "taps", "tapInstalls",  "viewInstalls", "totalInstalls", "ttr", "tapInstallRate", "totalInstallRate", "dateCreated", "goalEventCount", "goalRevenue", "goalRevenuePer", "goalCostPer", "goalROAS", "goalARPU", "goalAMPU"],
        adgroupProps : ["name", "id", "state", "spend", "tapAvgCPA", "totalAvgCPA", "cpt", "cpm", "impressions", "taps", "tapInstalls",  "viewInstalls", "totalInstalls", "ttr", "tapInstallRate", "totalInstallRate", "dateCreated", "campaignId", "goalEventCount", "goalRevenue", "goalRevenuePer", "goalCostPer", "goalROAS", "goalARPU", "goalAMPU"],
        keywordProps: ["name", "id", "state", "bidAmount", "spend", "tapAvgCPA", "totalAvgCPA", "cpt", "cpm", "impressions", "taps", "tapInstalls",  "viewInstalls", "totalInstalls", "ttr", "tapInstallRate", "totalInstallRate", "dateCreated", "adGroupId", "campaignId", "goalEventCount", "goalRevenue", "goalRevenuePer", "goalCostPer", "goalROAS", "goalARPU", "goalAMPU"],
      },
      scriptedArVarMap: {},
      searchVars: '',
      editorItem: {},
      editorIndex: null,

      searchTriggerVars: '',
      campaignTriggerVar: ["name", "id", "state", "dailyBudget", "budget", "cpm", "spend", "tapAvgCPA", "totalAvgCPA", "cpt", "impressions", "taps", "tapInstalls",  "viewInstalls", "totalInstalls", "ttr", "tapInstallRate", "totalInstallRate", "dateCreated", "goalEventCount", "goalRevenue", "goalRevenuePer", "goalCostPer", "goalROAS", "goalARPU", "goalAMPU"],
      adgroupTriggerVar: ["name", "id", "state", "spend", "tapAvgCPA", "totalAvgCPA", "cpt", "impressions", "taps", "tapInstalls",  "viewInstalls", "totalInstalls", "ttr", "tapInstallRate", "totalInstallRate", "dateCreated", "goalEventCount", "goalRevenue", "goalRevenuePer", "goalCostPer", "goalROAS", "goalARPU", "goalAMPU"],
      keywordTriggerVar: ["name", "id", "state", "bidAmount", "spend", "tapAvgCPA", "totalAvgCPA", "cpt", "impressions", "taps", "tapInstalls",  "viewInstalls", "totalInstalls", "ttr", "tapInstallRate", "totalInstallRate", "dateCreated", "goalEventCount", "goalRevenue", "goalRevenuePer", "goalCostPer", "goalROAS", "goalARPU"],
      disallowedKeywords: ['drop', 'create', 'update', 'delete', 'insert', 'select', 'null', 'show', 'alter', 'view', 'join', 'table', 'set', 'truncate', 'function', 'procedure', 'contraint', 'database', 'values', 'from', 'exec', 'column', 'information_schema', 'tables', 'analyze', 'explain', 'priviliges', 'use', 'grant', 'schema'],

      resultTab: null,
      resultTabItems: ['Results', 'Details'],
      nameRule: [
        (v) => v != '' || this.$t('createAR.pleaseEnteraValue'),
        (v) => v != 0 || this.$t('createAR.pleaseEnteraValue'), 
        (v) => v != null || this.$t('createAR.pleaseEnteraValue'),
        (v) => {
          if ((!this.editModeSAR) && v) {
            return (
              (this.sarNames && !this.sarNames.some((s) => s === v.toLowerCase().trim())) ||
              this.$t('createAR.ruleAlreadyExist')
            );
          } else {
            if(v.toLowerCase().trim() === this.sarName) {
              return true
            }
            else {
              return (this.sarNames && !this.sarNames.some((s) => s === v.toLowerCase().trim())) || this.$t('createAR.ruleAlreadyExist')
            }
          }
        },
      ],
      goalRules: [
        (v) => {
          const s = this.goalValidation()
          return s || 'Please select at least one goal'
        },
      ],
      sovRules: [
        (v) => {
          const s = this.sovValidation()
          return s || 'Please select an option.'
        },
      ],
      sarNames: [],
      sarName: '',
      includeInactiveEntities: false,
      settings: {
        checkFrequency: RuleCheckFrequencies.EVERY_DAY,
        isDated: false,
        dateRange: null,
        isIndefinite: false,
        sendReports: false,
        activeEntitiesOnly: true,
        emailIds: [],
        slackIds: [],
        lookback: 30,
      },
      ruleRangePicker: null,
      ruleDateRangelModel: [],
      ruleDateRange: [],

      titleEditable: false,
      jsExpanded: true,
      comboboxService: new DuplicateEmailComboboxService({
        component : this,
        externalEmailPropertyName : "externalMailIds",
        mainEmailPropertyName : "selectedMailIds"
      }),
    };
  },
  apollo: {
    getGoals: {
      query: GET_GOALS,
      update(data) {
        if (LSS.ownApps && LSS.ownApps.length > 0) {
          const appsWithAttr = LSS.ownApps.filter((e) => e.attrExist);
          const appIdsWithAttr = appsWithAttr.map(e => e.adamId)
          this.appGoals = data.getGoals.filter(e => appIdsWithAttr.includes(e.appId))
          this.selectedGoals.map(e => {
            data.getGoals.map(g => {
              if(e._id == g._id) e.mysqlPopulatedAt = g.mysqlPopulatedAt
            })
          })
        }
      },
    },
    getMySQLCOunt: {
      query: GET_MYSQL_COUNT,
      update(data) {
        if (data.getMySQLCount.code === 0 && data.getMySQLCount.data) { 
          const json = JSON.parse(data.getMySQLCount.data)
          this.campaignCount = json.campaignCount || 0
          this.adgroupCount = json.adgroupCount || 0
          this.keywordCount = json.keywordCount || 0
          this.mySQLCampaigns = json.campaigns || []
          this.mySQLAdgroups = json.adgroups || []
          this.mySQLKeywords = json.keywords || []
        }
      },
    },
    getEmails: {
      query: GET_EMAILS,
      update(data) {
        this.emailList = data.getEmails ? data.getEmails : [];
      },
    },
    getSlacks: {
      query: GET_SLACKS,
      update(data) {
        this.slackChannelList = data.getSlacks
      },
    },
  },
  computed: {
    sharedVoiceOptions:{	
      cache: false,	
      get() {	
        return [	
          {	
            text: this.$t('createAR.sharedNone'),	
            value: false,	
          },	
          {	
            text: this.$t('createAR.sharedKeywordMatch'),	
            value: true,	
          },	
        ]	
      }	
    },
    checkNumAndAlpha:{
      cache: false,
      get() {
        return [
        (v) => v != '' || 'Please enter a value',
        /* (v) => v != 0 || 'Please enter a value', */
        (v) => v != null || 'Please enter a value',
        (v) => {
          if(isNaN(v)){
              if(v.length==1 && (v=="'" || v=="\"")){
                return "Please enter a value"
              }
             if(v[0] == "'" && v[v.length - 1] == "'"){
              return true
             }
             else if(v[0] == "\"" && v[v.length - 1] == "\""){
              return true
             }
             else return "Please enclose the string with quotes"
          }
          return true
        },
      ]}
    },
    superGlobalsModified:{
      cache:false,
      get(){
        const newArr = this.superGlobals.map(v => ({...v, usage: (v.inputName)?"_IN_"+(v.inputName.replace(/\s+/g,' ').trim().replace(/ /g, '_')).toUpperCase()+"_":""}))
        return  newArr
      }
    },
    templateItems: {
      cache: false,
      get() {
          if(this.modeToggle === 'jseditor') return [
          {
            text: 'Pause Poorly Performing Campaigns',
            value: 'pause_poor'
          },
          // {
          //   text: 'Fund Nearly Exhausted Campaigns',
          //   value: 'fund_exhausted'
          // },
          {
            text: 'Reallocate Funds to Performant Campaigns',
            value: 'reallocate'
          }
        ];
        
        return [
          {
            text: 'Keyword Journey - Stage 1 - Impressions',
            value: 'kw_journey_1_impressions'
          },
          {
            text: 'Keyword Journey - Stage 2 - Tap',
            value: 'kw_journey_2_tap'
          },
          {
            text: 'Keyword Journey - Stage 3 - Install',
            value: 'kw_journey_3_installs'
          },
          {
            text: 'Keyword Journey - Stage 4 - Events',
            value: 'kw_journey_4_events'
          },
          // {
          //   text: 'Keyword Performance Report',
          //   value: 'kw_performance_report'
          // }
        ];
      }
    },
    firstActionIndex: {
      get() {
        const index = this.scriptedAutomationsModel.automationSteps.findIndex(f => !['trigger', 'trigger_blockly'].includes(f.type));

        return index;
      },
      cache: false
    },
    lastIndex: {
      get() {
        return this.scriptedAutomationsModel.automationSteps.length - 1;
      },
      cache: false
    },
    triggerExists: {
      get() {
        return !!this.scriptedAutomationsModel.automationSteps.find(item => ['trigger', 'trigger_blockly'].includes(item.type));
      },
      cache: false
    },
    showpopup(){
      if (this.window.width>=960) return true;
      if (this.window.width<960 && this.showSmallPopup) return true
      return false
    },
    ruleDateRangeText() {
      if (this.ruleDateRange[0] > this.ruleDateRange[1]) {
        const maxDate = this.ruleDateRange[0];
        this.ruleDateRange[0] = this.ruleDateRange[1];
        this.ruleDateRange[1] = maxDate;
      }
      return this.ruleDateRange.join(' - ');
    },
    actionFreqs: {
      cache: false,
      get() {
        return [
          //{ text: this.$t('createAR.actionFreqsText1'), value: RuleCheckFrequencies.HOURLY },
          {
            text: this.$t('createAR.actionFreqsText1'),
            value: RuleCheckFrequencies.HOURLY,
          },
          {
            text: this.$t('createAR.actionFreqsText4hrs'),
            value: RuleCheckFrequencies.EVERY_4_HRS,
          },
          // {
          //   text: this.$t('createAR.actionFreqsText2'),
          //   value: RuleCheckFrequencies.EVERY_6_HRS,
          // },
          {
            text: this.$t('createAR.actionFreqsText8hrs'),
            value: RuleCheckFrequencies.EVERY_8_HRS,
          },
          {
            text: this.$t('createAR.actionFreqsText3'),
            value: RuleCheckFrequencies.EVERY_12_HRS,
          },
          {
            text: this.$t('createAR.actionFreqsText4'),
            value: RuleCheckFrequencies.EVERY_DAY,
          },
          //{ text: this.$t('createAR.actionFreqsText5'), value: RuleCheckFrequencies.CUSTOM },
        ];
      },
    },
    areScriptsInvalid: {
      cache: false,
      get() {
        return this.scriptedAutomationsModel.automationSteps.find(item => !item.isValid)
      }
    },
    anActionWithoutTrigger: {
      cache: false,
      get() {
        let triggerFound = false, actionWithoutTrigger = false;
        this.scriptedAutomationsModel.automationSteps.forEach(item => {
          if(['trigger', 'trigger_blockly'].includes(item.type)) triggerFound = true;
          
          if(!triggerFound && !['trigger', 'trigger_blockly', 'onetime', 'onetime_blockly'].includes(item.type)) actionWithoutTrigger = true;
        })
        
        return actionWithoutTrigger;
      }
    },
    triggerActionPreset: {
      cache: false,
      get() {
        return ['trigger', 'trigger_blockly', 'onetime', 'onetime_blockly'].includes(this.scriptedAutomationsModel.automationSteps[0]?.type);
      }
    },
    scriptedExpansionValue: {
      cache: false,
      get() {
        return Array.from(Array(this.scriptedAutomationsModel.automationSteps.length).keys())
      }
    },
    logHeaders: {
      cache: false,
      get() {
        return [
          { text: this.$t('signinSlackBtn.sno'), value: 'sno' },
          { text: this.$t('aRules.name'), value: 'ruleName' },
          // {
          //   text: this.$t('aRules.createdAt'),
          //   value: 'createdOn',
          //   default: 'NA',
          // },
          { text: this.$t('aRules.status'), value: 'logStatus', default: 'NA' },
          {
            text: this.$t('aRules.completed'),
            value: 'updatedOn',
            default: 'NA',
          },
          { text: this.$t('createAR.details'), value: 'data-table-expand' },
          // {
          //   text: this.$t('aRules.actionFreq'),
          //   value: 'ruleFreq',
          //   default: 'NA',
          // },
          // { text: 'Actions', value: 'actions' },
        ];
      },
    },

    items: {
      cache: false,
      get() {
        return [this.$t('aRules.rules'), this.$t('aRules.logs')];
      },
    },

    scripteditems: {
      cache: false,
      get() {
        return [this.$t('Functions'), this.$t('Variables')];
      },
    },
    triggeritems: {
      cache: false,
      get() {
        return [this.$t('Functions'), this.$t('Variables')];
      },
    },
    arHeaders: {
      cache: false,
      get() {
        return [
          { text: this.$t('signinSlackBtn.sno'), value: 'sno' },
          { text: this.$t('aRules.name'), value: 'ruleName' },
          { text: this.$t('aRules.status'), value: 'arStatus', default: 'NA' },
          {
            text: this.$t('aRules.chckFreq'),
            value: 'freq',
          },
          {
            text: this.$t('aRules.enableDisable'),
            value: 'disable',
            sortable: false,
          },
          {
            text: this.$t('aRules.actions'),
            value: 'actions',
            sortable: false,
          },
        ];
      },
    },

    // arItems: {
    //   cache: false,
    //   get() {
    //     return [
    //       {
    //         name: 'Rule 1',
    //         createdOn: 'Date on',
    //         action: 'Email Notification',
    //         status: 'Running',
    //         checkFreq: 'Every 3 Hrs',
    //         actionFreq: 'Every 12 Hrs',
    //       },
    //     ];
    //   },
    // },
  },
  watch: {
    snapshotDialog(newValue){
      if(!newValue) {
        this.expand1=false;
        this.expand2=false;
        this.expandCount1=3;
        this.expandCount2=3;
      }
    },
    expand1(newValue){
      if(this.snapshotDialog){
        if(newValue) this.expandCount1=100;
        else this.expandCount1=0
        }
      },
    expand2(newValue){
      if(this.snapshotDialog){
        if(newValue) this.expandCount2=100;
        else this.expandCount2=0
        }
      },
    searchTriggerVars() {
      this.filteredTriggerVars()
    }
  },

  methods: {
    onModelValueChange(){
      this.comboboxService.checkForDuplicateRemovalAndNotifyError();
    },
    copyJSON(json) {
      navigator.clipboard.writeText(JSON.stringify(this.sarExecutionLog ));
      this.$notify.success('Log Copied');
    },
    initiateTestRun() {
      this.mySQLCampsDone = false,
      this.mySQLAdsDone = false,
      this.mySQLKeysDone = false,
      this.mySQLCampsProgress = 0,
      this.mySQLAdsProgress = 0,
      this.mySQLKeysProgress = 0,
      this.showValidationProgressDialog();
      setTimeout(() => {
        if(!this.stopValidating) {
          this.ruleErrorsArray=[];
          if(this.firstActionIndex==-1){
            this.validationError = true;
            this.dialogVisible=true;
            this.ruleErrorsArray.push({
              error: 'Missing action : ',
              message: 'At least one action is required.'
            });
            this.validProgDialogVisible = false;
          }else{
            this.validate = {...this.validate, [this.firstActionIndex]: Date.now() };
            this.initTestRun = true;
          }
        }
      }, 500)  
    },
    initiateSaveSarAction(){
      this.showValidationProgressDialog();
      setTimeout(() => {
        if(!this.stopValidating) {
          this.ruleErrorsArray=[];
          if(this.firstActionIndex==-1){
              this.validationError = true;
              this.dialogVisible=true;
              this.ruleErrorsArray.push({
                error: 'Missing action : ',
                message: 'At least one action is required.'
              });
              this.validProgDialogVisible = false;
          }
        }
        this.validate = {...this.validate, [this.firstActionIndex]: Date.now() };
        this.initSaveSar = true;
      }, 500)
    },
    initiateUpdateSarAction(){
      this.showValidationProgressDialog();
      setTimeout(() => {
        if(!this.stopValidating) {
          this.ruleErrorsArray=[];
          if(this.firstActionIndex==-1){
            this.validationError = true;
            this.dialogVisible=true;
            this.ruleErrorsArray.push({
              error: 'Missing action : ',
              message: 'At least one action is required.'
            });
            this.validProgDialogVisible = false;
          }
        }
        this.validate = {...this.validate, [this.firstActionIndex]: Date.now() };
        this.initUpdateSar = true;
      }, 500)
    },
    validateActions() {
      this.showValidationProgressDialog();
      setTimeout(() => {
        this.validate = {...this.validate, [this.firstActionIndex]: Date.now() };
        if(!this.stopValidating) {
          this.showValidationPopup = true;
          this.ruleErrorsArray=[];
          if(this.firstActionIndex==-1){
            this.validationError = true;
            this.dialogVisible=true;
            this.ruleErrorsArray.push({
              error: 'Missing action : ',
              message: 'At least one action is required.'
            });
            this.validProgDialogVisible = false;
          }
        }
      }, 500)
    },
    closeDialog() {
      this.dialogVisible = false;
      this.showValidationPopup = false;
      this.initTestRun = false;
      this.initSaveSar = false;
      this.initUpdateSar=false;      
      this.validProgDialogVisible = false;
    },
    showValidationProgressDialog() {
      this.stopValidating = false;
      this.validProgDialogVisible = true;
    },
    closeValidationProgressDialog() {
      this.stopValidating = true;
      this.validProgDialogVisible = false;
    },
    showMoveUp(item, index) {
      if(index < 1) return false;
      
      if(['trigger', 'trigger_blockly', 'onetime', 'onetime_blockly'].includes(item.type)) {
        return true;
      } else {
        return !['trigger', 'trigger_blockly', 'onetime', 'onetime_blockly'].includes(this.scriptedAutomationsModel.automationSteps[index - 1]?.type);
      }
      
      return false;
    },
    showMoveDown(item, index) {
      if(index >= this.lastIndex) return false;
      
      if(['trigger', 'trigger_blockly', 'onetime', 'onetime_blockly'].includes(item.type)) {
        return ['trigger', 'trigger_blockly', 'onetime', 'onetime_blockly'].includes(this.scriptedAutomationsModel.automationSteps[index + 1]?.type);
      } else {
        return true;
      }
      
      return false;
    },
    copyToClipboard(text){
      navigator.clipboard.writeText(text)
    },
    checkUnique(index){
    
      let arr = []
      this.superGlobalsModified.forEach(e=>{
        if(e.usage == this.superGlobalsModified[index].usage) arr.push(e.usage)
      })
      if(arr.length>1) return "name must be unique"
      return true
    },
    addSuperGlobal(){
      this.superGlobals.push({
        inputName:'',
        desc:'',
        value: ''
      })
    },
    copyCode(code) {
      navigator.clipboard.writeText(code);
      this.$notify.success("Code Copied")
    },

    expCollapJS(event) {
      if(event == "collapse") {
        this.jsExpanded = false
      } else {
        this.jsExpanded = true
      }
    },

    confirmSarModeChange(mode) {
      if(this.modeToggle == mode) return;
      
      if(this.lockBlockly && mode == 'blocklyeditor') {
        this.blocklyJsSwitchMessage = 'Conversion from JavaScript back to Automation Blocks is not possible because only a subset of JavaScript is supported by Automation Blocks. Please create a new rule if you wish to use Automation Blocks.'
        
        this.blocklyJsSwitchFlag = true;
        this.blocklyJsSwitchTitle = '';
      
        this.blocklyJsSwitchData = {
          id: 8,
          from: 'SAR',
          now: Date.now(),
          sarData: mode
        };
        
        return;
      }
      
      this.blocklyJsSwitchFlag = true;
      this.blocklyJsSwitchTitle = '';
      if(mode == 'blocklyeditor') {
        this.blocklyJsSwitchMessage = 'Do you want to switch to Automation Blocks mode? Your code will not be converted to blocks and it will be lost.'
      }
      else if(mode == 'jseditor') {
        this.blocklyJsSwitchMessage = 'Converting to raw JavaScript is a one-way conversion and the rule cannot be converted back to Automation Blocks later. Are you sure you wish to convert this rule to JavaScript?'
      }
      this.blocklyJsSwitchData = {
        id: 6,
        from: 'SAR',
        now: Date.now(),
        sarData: mode
      };
    },
    sarModeChange(mode) {
      this.modeToggle = mode;
      
      if(this.modeToggle === 'jseditor') {
        this.lockBlockly = true;
      }
      
      const resetcode = this.modeToggle === 'blocklyeditor' ? true : false;
      
      const abc = this.scriptedAutomationsModel.automationSteps.map((it) => {
        let temp = _.cloneDeep(it);
        const name = this.modeToggle === 'blocklyeditor' ? temp.type + '_blockly' : temp.type.split('_')[0];
        temp = Object.assign({}, it, { type: name });
        
        if(resetcode) {
          temp = Object.assign({}, temp, { code: '', isValid: false, state: null });
          this.blocklyCodePreview = {};
          
          this.globalVariablesMap = {};
          this.globalVariablesMapIndexWise = {};
        }
        
        return temp;
      });
      
      this.scriptedAutomationsModel.automationSteps = [];
      setTimeout(() => {
        abc.map(arStep => arStep.template = arStep.code);
        this.scriptedAutomationsModel.automationSteps = abc;
      }, 0)
    },
    async humanForm(obj, lookback, metricLog,sovLog){
      let arr = []
      let err = []
      for(const action of obj){
        if (['trigger', 'trigger_blockly'].includes(action.type)){
          let campaigns = action.output.rows.campaigns.length || 0
          let adgroups = action.output.rows.adgroups.length || 0
          let keywords = action.output.rows.keywords.length || 0
          if(action.output.error && action.output.error.message){
            err.push(action.output.error.message + ` in your query - '${action.codeWithoutComments}'`)
          }
          arr.push({
              [action.title]:`Trigger matched ${keywords} keywords, ${adgroups} adgroups, ${campaigns} campaigns`
            })
          arr.push({"sovLog":sovLog})
        }
        else{
          let arr1 = []
          for(const output of action.output){
            for(const outputItemKey in output){
              if(outputItemKey == 'log'){
                if(output[outputItemKey]=='SAR_DISABLERULE_CALLED') arr1.push('Disabling this rule')
                else if(output[outputItemKey]=='SAR_STOPRULE_CALLED') arr1.push('Stopping this rule')
                else if(output[outputItemKey]=='SAR_STOPACTION_CALLED') arr1.push('Stopping this action')
                else if(output[outputItemKey]=='SAR_NEXTRESULT_CALLED') arr1.push('Moving to next result')
                else arr1.push(`ERROR: ${output[outputItemKey]}`) 
              }
              if(/callexpression/gi.test(outputItemKey)){
                if(output[outputItemKey].type=='error'){
                  arr1.push(`ERROR: ${output[outputItemKey].message}`)
                }
                else{
                  if(output[outputItemKey].humanMes){
                    arr1.push(output[outputItemKey].humanMes)
                    delete output[outputItemKey].humanMes
                  }
                  else if(output[outputItemKey].message){
                    arr1.push(output[outputItemKey].message)
                  }
                  else{
                    arr1.push(output[outputItemKey])
                  }
                }
                
              }
            }
          }
          arr.push({[action.title]:arr1})
          delete action.title
        }
      }
      if(Array.isArray(metricLog)){
        arr.push({"metric_log":metricLog})
      }
      return {
        lookback_window: lookback,
        result: arr,
        errors: err
      }
    },
    toggleEmailSelectAll(i) {
      this.selectAllEmails = ~this.selectAllEmails
      if (this.selectAllEmails) {
        this.selectedMailIds = this.emailList.map((e) => e.Email)
        this.selectAllEmailIcon = 'mdi-checkbox-multiple-marked'
      } else {
        this.selectedMailIds = []
        this.selectAllEmailIcon = 'mdi-crop-square'
      }
    },
    toggleSlackSelectAll() {
      this.selectAllSlacks = ~ this.selectAllSlacks
      if (this.selectAllSlacks) {
        this.selectedSlacks = this.slackChannelList.map(
          (e) => e.IntegrationID
        );
        this.selectAllSlackIcon = 'mdi-checkbox-multiple-marked'
      } else {
        this.selectedSlacks = []
        this.selectAllSlackIcon = 'mdi-crop-square'
      }
    },
    emailSelectionChanged() {
      this.comboboxService.checkAndInitiateADuplicateRemovalInOtherEmail();
      if(this.emailList && this.emailList.length > 0 && this.selectedMailIds.length === this.emailList.length) {
        this.selectAllEmails = true
        this.selectAllEmailIcon = 'mdi-checkbox-multiple-marked'
      }else{
        this.selectAllEmails = false
        this.selectAllEmailIcon = 'mdi-crop-square'
      }
    },
    slackSelectionChanged() {
      if(this.selectedSlacks && this.slackChannelList.length > 0 && this.selectedSlacks.length === this.slackChannelList.length) {
        this.selectAllSlacks = true
        this.selectAllSlackIcon = 'mdi-checkbox-multiple-marked'
      }else{
        this.selectAllSlacks = false
        this.selectAllSlackIcon = 'mdi-crop-square'
      }
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },

    async goalSelected() {
      await this.$refs.sarSettings.validate();
      if (!this.sarSettingsFormvalid) {
        return;
      }
    },

    goalValidation() {
      const val = this.scriptedAutomationsModel.automationSteps.map((step, index) => {
        let err = false
        this.goalFields.map(field => {
          if(step.code && step.code.includes(field)){
            err = true
          }
        })
        if(err && (!this.selectedGoals || this.selectedGoals.length === 0)){
          return false
        }else{
          return true
        }
      })
      return !val.some( e => !e)
    },

    sovValidation() {
      const val = this.scriptedAutomationsModel.automationSteps.map((step, index) => {
        let err = false
        this.sovFields.map(field => {
          if(step.code && step.code.includes(field)){
            err = true
          }
        })
        if(err && (!this.selectShareVoiceOption || this.selectShareVoiceOption.length === 0)){
          return false
        }else{
          return true
        }
      })
      return !val.some( e => !e)
    },

    filteredTriggerVars() {
      this.campaignTriggerVar = this.triggerVarMap.campaignProps.filter((trigger) => trigger.toLowerCase().includes(this.searchTriggerVars))
      this.adgroupTriggerVar = this.triggerVarMap.adgroupProps.filter((trigger) => trigger.toLowerCase().includes(this.searchTriggerVars))
      this.keywordTriggerVar = this.triggerVarMap.keywordProps.filter((trigger) => trigger.toLowerCase().includes(this.searchTriggerVars))
    },

    insertFromNavigator(value, index) {
      this.scriptedAutomationsModel.automationSteps = this.scriptedAutomationsModel.automationSteps.map((item, itemIndex) => {
        if(index === itemIndex) {
          item.insertFromNavigator = {
            value,
            now: Date.now()
          };
        }

        return item
      })
    },
    applyTemplate(key) {
      this.editModeSAR = false;
      this.scriptedAutomationsModel.automationSteps = [];
      const newItems = _.cloneDeep(this.templateMap[key]);
      
      setTimeout(() => {
        newItems.forEach((element, index) => {
          if(['onetime', 'onetime_blockly'].includes(element.type)) {
            
            const declaredVars = new Set()
            const nodes = parse(element.code)
            nodes.forEach(node => {
              if(node.name === 'VariableDefinition') {
                declaredVars.add(element.code.slice(node.from, node.to))
              }
            })
            
            this.globalVariablesMap[index] = declaredVars;
            
          }
          const arr = Object.keys(this.globalVariablesMap);
          const eligibleKeys = arr.filter(k => k < index);
          const vars = [];
          eligibleKeys.map(ek => vars.push(...this.globalVariablesMap[ek]))
          
          this.globalVariablesMapIndexWise[index] = vars;
          
          element.startState = element.state;
          this.addEditEditor(element)
        })
      }, 0);
      
    },
    changeLog() {
      if (this.searchLog.ruleId == 0) {
        this.logItemsFiltered = this.logItems;
      } else {
        this.logItemsFiltered = this.logItems.filter(
          (e) => e.ruleId == this.searchLog.ruleId
        );
      }
    },
    titleCase(s) {
      return s
        .toLowerCase()
        .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
        .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase());
    },
    clearDecisionModel() {
      this.decisionAlertFlag = false;
      this.blocklyJsSwitchFlag = false;
    },
    decisionOkAction(event) {
      this.decisionAlertFlag = false;
      this.blocklyJsSwitchFlag = false;
      if(event.from === 'SAR'){
        switch (event.id) {
          case 4:
            this.saveRule()
            break;
          case 5:
            this.updateSAR()
            break;
          case 6: 
            this.sarModeChange(event.sarData)
            break
          case 7: {
            this.$router.push({ path:'/sar-proxy'});
            break; 
          }
          case 8: {
            this.$router.push({ path:'/sar-proxy?blockly=true'});
            break; 
          }
          default:
            break;
        }
        return
      }
      const id = event.id;
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: DELETE_AR_ADV_RULE,
          variables: {
            ruleId: id,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.arItems = this.arItems.filter((x) => x._id != id);
          this.getAllRules();
          this.fullscreenLoader.hide();
          this.$notify.success(this.$t('successMsg.AutoDeleteMsg'));
        })
        .catch((error) => {
          //console.log(error);
          this.fullscreenLoader.hide();
          this.$notify.error(this.$t('successMsg.requestFailMsg'));
        });
    },
    getAllRules() {
      this.arLoading = true;
      this.arItems = [];

      this.$apollo
        .query({
          query: GET_ALL_RULES_ADV,
          variables: {
            teamId: 'fad928aa-3539-495e-92e9-9ddc0e5a0897',
            now: Date.now().toString(),
          },
        })
        .then((res) => {
          if (
            res.data.getAdvAllRules == null ||
            res.data.getAdvAllRules == undefined
          ) {
            //console.log('No data');
            return;
          }
          this.arItems = res.data.getAdvAllRules;
          this.arLoading = false;
        })
        .catch((error) => {
          //console.log(error);
          this.arLoading = false;
          //this.noDataTextKeys = 'No data to show';
        });
    },
    getAllLogs() {
      this.logLoading = true;
      this.logItems = [];

      this.$apollo
        .query({
          query: GET_ALL_LOGS,
          variables: {
            teamId: 'none',
            now: Date.now().toString(),
          },
        })
        .then((res) => {
          if (res.data.getAlllogs == null || res.data.getAlllogs == undefined) {
            //console.log('No data');
            return;
          }
          this.logItems = res.data.getAlllogs;
          this.logItems = this.logItems.map((e) => {
            const o = {
              createdOn: e.createdOn.toString().substr(0, 10),
              logId: e.logId,
              logStatus: e.logStatus,
              ruleFreq: e.ruleFreq,
              ruleId: e.ruleId,
              ruleName: e.ruleName,
              updatedOn:
                'On ' +
                moment(e.updatedOn).format('YYYY-MM-DD HH:mm').substr(0, 10) +
                ' At ' +
                moment(e.updatedOn)
                  .format('YYYY-MM-DD HH:mm')
                  .toString()
                  .substr(11, 5),
              _id: e._id,
              data: e.data,
            };
            return o;
          });
          //console.log(this.logItems)
          this.searchLogItems = [...this.logItems];
          this.searchLogItems.unshift({
            ruleId: 0,
            ruleName: 'All',
          });
          this.searchLog.ruleId = 0;
          this.changeLog();
          this.logLoading = false;
        })
        .catch((error) => {
          //console.log(error);
          this.logLoading = false;
          //this.noDataTextKeys = 'No data to show';
        });
    },
    dataFromCreateRule(e) {
      //console.log(e)
      this.fullscreenLoader = this.$loading.show();
      if (e) {
        this.getAllRules();
        this.getAllLogs();
        updateCLF('createRule', this);
      }
      this.fullscreenLoader.hide();
    },
    callCreateRule() {
      const names =
        this.arItems.map((e) => e.ruleName.toLowerCase().trim()) || [];
      this.dataToCreateRule = {
        mode: 1,
        now: Date.now(),
        names: names,
      };
    },
    reactivateARRule(item) {
      //console.log('editARRule',item);
      this.$apollo
        .query({
          query: REACTIVATE_SINGLE_RULE_ADV,
          variables: {
            ruleId: item,
          },
        })
        .then((res) => {
          this.$notify.success(this.$t('successMsg.AutoRuleEnableMsg'));
          this.getAllRules();
          this.fullscreenLoader.hide();
        })
        .catch((error) => {
          //console.log(error);
          this.fullscreenLoader.hide();
          this.$notify.error(this.$t('successMsg.ruleUpdateFailMsg'));
        });
    },
    viewARRule(item) {
      //console.log('editARRule',item);
      this.$apollo
        .query({
          query: GET_SINGLE_RULE_ADV,
          variables: {
            ruleId: item,
            now: Date.now().toString(),
          },
        })
        .then((res) => {
          if (
            res.data.getAdvSingleRules == null ||
            res.data.getAdvSingleRules == undefined
          ) {
            //console.log('No data');
            return;
          }
          const item = res.data.getAdvSingleRules[0];
          this.arLoading = false;
          const names = item.ruleName.toLowerCase().trim();
          this.dataToCreateRule = {
            mode: 3,
            now: Date.now(),
            item: item,
            goals: this.goals,
            names: names,
          };
        })
        .catch((error) => {
          //console.log(error);
          this.arLoading = false;
          //this.noDataTextKeys = 'No data to show';
        });
    },
    editARRule(item) {
      //console.log('editARRule',item);
      this.$apollo
        .query({
          query: GET_SINGLE_RULE_ADV,
          variables: {
            ruleId: item,
            now: Date.now().toString(),
          },
        })
        .then((res) => {
          if (
            res.data.getAdvSingleRules == null ||
            res.data.getAdvSingleRules == undefined
          ) {
            //console.log('No data');
            return;
          }
          const item = res.data.getAdvSingleRules[0];
          this.arLoading = false;
          const names = item.ruleName.toLowerCase().trim();
          this.dataToCreateRule = {
            mode: 2,
            now: Date.now(),
            item: item,
            goals: this.goals,
            names: names,
          };
        })
        .catch((error) => {
          //console.log(error);
          this.arLoading = false;
          //this.noDataTextKeys = 'No data to show';
        });
    },
    deleteARRule(id) {
      this.decisionAlertFlag = true;
      this.decisionAlertTitle = '';
      this.decisionAlertMessage = this.$t('createAR.sureToDelete');
      this.decisionAlertData = {
        id: id,
        from: 'deleteRule',
        now: Date.now(),
      };
    },

    disableARRule(id) {
      //console.log(id)
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: DISABLE_AR_ADV_RULE,
          variables: {
            ruleId: id,
          }
        })
        .then((res) => {
          //console.log(res.data);
          this.fullscreenLoader.hide();
          this.$notify.success(this.$t('successMsg.AutoRuleDisSuccessMsg'));
        })
        .catch((error) => {
          //console.log(error);
          this.fullscreenLoader.hide();
          this.$notify.error(this.$t('successMsg.requestFailMsg'));
        });
    },
    enableARRule(id) {
      // console.log(id)
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: ENABLE_AR_ADV_RULE,
          variables: {
            ruleId: id,
          }
        })
        .then((res) => {
          //console.log(res.data);
          this.fullscreenLoader.hide();
          this.$notify.success(this.$t('successMsg.AutoRuleEnableMsg'));
        })
        .catch((error) => {
          //console.log(error);
          this.fullscreenLoader.hide();
          this.$notify.error(this.$t('successMsg.requestFailMsg'));
        });
    },
    deleteARLog(id) {
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: DELETE_AR_LOG,
          variables: {
            ruleId: id,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.fullscreenLoader.hide();
        })
        .catch((error) => {
          //console.log(error);
          this.fullscreenLoader.hide();
        });
    },
    showLogStatusMsg(item) {
      //console.log(item.data)
      const a = [];
      this.appleMsgs = [];
      if (item.logStatus === 'SLACKSENT' || item.logStatus === 'SLACKFAIL') {
        this.infoAlertTitle = 'Messages from Slack!!!';
        if (item.data.error === 'not_in_channel') {
          a.push(
            'The Search Ads Maven App is not added to the specified channel'
          );
        } else if (item.data.error === 'channel_not_found') {
          a.push("Couldn't find the specified channel - might be deleted");
        } else if (item.data.error === 'invalid_auth') {
          a.push(
            'The Search Ads Maven App is not authorized to post to the specified channel'
          );
        } else if (item.data.error === 'missing_scope') {
          a.push(
            'The Search Ads Maven App does not have permissions to post files to the specified channel'
          );
        } else {
          a.push('Unable to post report to the specified channel');
        }
      } else if (
        item.logStatus === 'EMAILSENT' ||
        item.logStatus === 'EMAILFAIL'
      ) {
        this.infoAlertTitle = 'Messages!!!';
        a.push('Unable to send report to the specified email id');
      } else {
        this.infoAlertTitle = 'Messages from Apple!!!';
        if (item.data.error.errors instanceof Array) {
          const errs = item.data.error.errors;
          errs.map((err) => a.push(err.message));
        } else if (item.data.error.errors instanceof Object) {
          const err = item.data.error.errors;
          if (err) a.push(err.message);
          else a.push('Unable to validate the specified changes');
        } else {
          a.push('Unable to validate the specified changes');
        }
      }
      this.infoAlertFlag = true;

      //this.infoAlertMessage = a
      this.appleMsgs = a;
    },

    getAllGoals() {
      if (LSS && LSS.ownApps?.length > 0) {
        const ownApp = LSS.ownApps;
        for (const f of ownApp) {
          if (f.attrExist) {
            this.$apollo
              .query({
                query: GET_GOALS_FROM_APP,
                variables: {
                  appId: f.adamId,
                },
              })
              .then((res) => {
                this.goals.push({
                  adamId: f.adamId,
                  goals: res.data.getGoalsFromApp
                    ? res.data.getGoalsFromApp
                    : [],
                });
              });
          }
        }
      }
    },
    getFunctionsList(type) {
      return this.scriptedArVarMap[type].functions
    },
    getFiltFuncList(type) {
      return this.scriptedArVarMap[type].functions.filter((fn) => fn.toLowerCase().includes(this.searchVars.toLowerCase()))
    },
    getVariablesList(type) {
      return this.scriptedArVarMap[type].vars;
    },
    getSovVariablesList(type) {
      return this.scriptedArVarMap[type].SovVars;
    },
    getFiltVarList(type) {
      return this.scriptedArVarMap[type].vars.filter((fn) => fn.toLowerCase().includes(this.searchVars.toLowerCase()))
    },
    getMiscVariableList(type) {
      return this.scriptedArVarMap[type].misc
    },
    getPropList(type) {
      return this.scriptedArVarMap[type].propList || {}
    },
    addEditor(type) {
      this.searchVars = ''
      let label;
      let title;
      
      if(this.modeToggle === 'blocklyeditor') type = type + '_blockly';
      
      switch(type) {
        case 'trigger_blockly':
        case 'trigger': {
          label = 'Trigger';
          title = 'Trigger';
          break;
        }
        case 'campaign_blockly':
        case 'campaign': {
          label = 'Campaign Action';
          title = 'Campaign Action';
          break;
        }
        case 'adgroup_blockly':
        case 'adgroup': {
          label = 'Ad Group Action';
          title = 'Ad Group Action';
          break;
        }
        case 'keyword_blockly':
        case 'keyword': {
          label = 'Keyword Action';
          title = 'Keyword Action';
          break;
        }
        case 'onetime_blockly':
        case 'onetime': {
          label = 'One-Time Action'
          title = 'One-Time Action'
          break;
        }
      }
      this.validate[this.scriptedAutomationsModel.automationSteps.length] = null;
      this.scriptedAutomationsModel.automationSteps.push({
            isValid: true,
            label: label,
            type: type,
            title: title,
            titleEditable: false,
      });
      
      this.scriptedAutomationsModel.automationSteps.forEach((step, index) => {
          const arr = Object.keys(this.globalVariablesMap);
          const eligibleKeys = arr.filter(k => k < index);
          const vars = [];
          eligibleKeys.map(ek => vars.push(...this.globalVariablesMap[ek]))
          
          this.globalVariablesMapIndexWise[index] = vars;
      });
    },
    deleteScriptedArStep(index) {
      const abc = this.scriptedAutomationsModel.automationSteps.filter((o, i) => index !== i);
      this.scriptedAutomationsModel.automationSteps = [];
      setTimeout(() => {
        abc.map(arStep => {
          arStep.template = arStep.code;
          arStep.startState = arStep.state;
        });
        this.scriptedAutomationsModel.automationSteps = abc;
      }, 0)
      this.diagnosticsValues = [];
      this.dialogErrorMessage=[];
    },
    getBlocklyCode(values, item, index) {
      item.state = values.state;
      item.isValid = values.isValid;
      item.code = values.code;
      
      if(index == this.firstActionIndex) {
        this.diagnosticsValues = [];
      }
      
      let diagnosticsValue = values.diagnostics;
      if (diagnosticsValue && diagnosticsValue.length > 0) {
        let val = {
          index: index,
          values: diagnosticsValue,
          type: item.type,
          stepName: item.label,
        }
        if(this.diagnosticsValues.find(item => item.index === val.index)) {
          
        } else {
          this.diagnosticsValues.push(val);
        }
        // this.validateActions();
      }
      
      let language;
      switch(item.type) {
        case 'trigger_blockly': {
          language = 'sql';
          break;
        }
        default: {
          language = 'javascript';
        }
      }
      
      this.blocklyCodePreview = { ...this.blocklyCodePreview, [index]: hljs.highlight(values.code, { language, ignoreIllegals: true }).value };
      
      if(['onetime', 'onetime_blockly'].includes(item.type)) {
        
        this.globalVariablesMap[index] = values.declaredVars;
        this.scriptedAutomationsModel.automationSteps.forEach((step, index) => {
          const arr = Object.keys(this.globalVariablesMap);
          const eligibleKeys = arr.filter(k => k < index);
          const vars = [];
          eligibleKeys.map(ek => vars.push(...this.globalVariablesMap[ek]))
          
          this.globalVariablesMapIndexWise[index] = vars;
        });
      }
      
      
      if(!this.stopValidating && index == this.lastIndex && (this.showValidationPopup || this.initTestRun || this.initSaveSar || this.initUpdateSar)) {
        this.ruleErrorsArray = [];
        this.validationError = false;
        this.validationErrorMessage = [];
        if (this.diagnosticsValues.length > 0) {
          this.validationError = true;
          this.validationErrorMessage = this.diagnosticsValues.map((diagnostics) =>  {
            let msg = ``;
           if (diagnostics.index && diagnostics.stepName) {
              msg = `
                Step ${diagnostics.index + 1} : ${diagnostics.stepName} - Validation Error:
                <ul>${diagnostics.values.map((item) => `<li>${item.message}</li>`).join('')}</ul>
              `;
            } else {
              msg = `
                Validation Error:<br>
                <ul>${diagnostics.values.map((item) => `<li>${item.message}</li>`).join('')}</ul>
              `;
            }
            return msg;
          }
          );
        }
        this.scriptedAutomationsModel.automationSteps.map((step, index) => {
          let errGoal = false
          this.goalFields.map(field => {
            if(step.code && step.code.includes(field)){
              errGoal = true
            }
          })
          if(errGoal && (!this.selectedGoals || this.selectedGoals.length === 0)){
            this.validationError = true;
            this.ruleErrorsArray.push({
              error: 'Goal not selected but goal variables are used in code:',
              message: 'Goals must be chosen when using goal variables.'
            });
          }

          let errSov = false
          this.sovFields.map(field => {
            if(step.code && step.code.includes(field)){
              errSov = true
            }
          })
          if(errSov && (!this.selectShareVoiceOption || this.selectShareVoiceOption.length === 0)){
            this.validationError = true;
            this.ruleErrorsArray.push({
              error: 'SOV not selected but sov variables are used in code:',
              message: 'Share of Voice Attribution must be chosen when using SOV variables.'
            });
          }
        });
        if (!this.editModeSAR && this.ruleName) {
          const trimmedV = this.ruleName.toLowerCase().trim();
          if (this.sarNames && this.sarNames.some((s) => s === trimmedV)) {
            this.validationError = true;
            this.ruleErrorsArray.push({
              error: 'Rule name already exists: ',
              message: `The rule name '${trimmedV}' already exists.`
            });
          }
        }
        if (!Number.isInteger(Number(this.settings.lookback)) || this.settings.lookback==='' || this.settings.lookback < 0 || this.settings.lookback >= 31){
        this.validationError = true;          
        this.ruleErrorsArray.push({
          error: 'Invalid lookback days entered:',
          message: 'A Lookback should be from 0 to 30 days.'
        });
        }
        this.goalSelected();
        // !triggerActionPreset 
        // anActionWithoutTrigger
        // !inputFormValid
        // !!areScriptsInvalid  
        if(!this.inputFormValid) {
          this.$refs.inputFormRef.validate();
        let errorTitle = "Input variables error:";
        let msgsArray = [];
        let uniqueCheck = false;
        this.superGlobals.map((item,index) => {
          let row = index+1;
          if(this.checkUnique(index) !== true) {
            if(!uniqueCheck) {
                msgsArray.push({
                  msgErrDesc: 'Input name must be unique'
                });
            }
            uniqueCheck = true;
          }
          if(item.inputName == '' || !(/^[a-zA-Z0-9 ]+$/.test(item.inputName))) {  
            msgsArray.push({
              msgErrDesc: 'Input name in #row '+ row + ' is invalid or incomplete.' 
            });
          }

          if(item.desc == '') {
            msgsArray.push({
              msgErrDesc: 'Description in #row '+ row + ' is invalid or incomplete.' 
            });
          }

          let valueError = false;
          if(item.value == '' || item.value == null) {
            let v = item.value;
            if(isNaN(v)){
              if(v.length==1 && (v=="'" || v=="\"")){
                valueError = true;
              }
              if(v[0] == "'" && v[v.length - 1] == "'"){
                
              }
              else if(v[0] == "\"" && v[v.length - 1] == "\""){
                
              }
              else {
                valueError = true;
              }
            } else {
              valueError = true;
            }
          }
          if(valueError) {
            msgsArray.push({
              msgErrDesc: 'Value in #row '+ row + ' is invalid or incomplete.' 
            });
          }

        });

        if(msgsArray.length > 0) {
          this.validationError = true;
          this.ruleErrorsArray.push({
            error: errorTitle,
            message: msgsArray
          });
        }

        }
        
        if(this.initTestRun) {
          if(this.validationError) {
            this.dialogVisible = true;
            this.dialogErrorMessage = this.validationErrorMessage;
          } else {
            if(this.enableSARLiveRun) this.testRun_cloud();
            else this.testRun_mono();
          }
          
        }
        if(this.initSaveSar) {
          if(this.ruleName == '') {
              this.validationError = true;
              this.dialogVisible = true;
              this.ruleErrorsArray.push({
                error: 'Missing/invalid rule name: ',
                message: 'A valid rule name must be entered.'
              });
            }
          if(this.validationError) {
            this.dialogVisible = true;
            this.dialogErrorMessage = this.validationErrorMessage;
          } else {
            this.sarAction(4, {});
          }
        }

         if(this.initUpdateSar) {
          if(this.validationError) {
            this.dialogVisible = true;
            this.dialogErrorMessage = this.validationErrorMessage;
          } else {
            this.sarAction(5, {});
          }
        }
        if(this.showValidationPopup) {
          this.dialogVisible = true;
          this.dialogErrorMessage = this.validationErrorMessage;
        }
        this.showValidationPopup = false;
        this.initTestRun = false;
        this.initSaveSar=false;
        this.initUpdateSar=false;
        this.diagnosticsValues = [];
      }
      const tempValidate = {};
      Object.keys(this.validate).forEach(k => {
        tempValidate[k] = null;
      });
      
      const nextActionIndex = this.scriptedAutomationsModel.automationSteps.findIndex((f, i) => !['trigger', 'trigger_blockly'].includes(f.type) && i > index);
      
      if (nextActionIndex !== -1) {
        this.validate = { ...tempValidate, [nextActionIndex]: Date.now() };
      }
      
    },
    
    moveEditor(index, direction = -1) {
      const steps = _.cloneDeep(this.scriptedAutomationsModel.automationSteps);
      
      this.scriptedAutomationsModel.automationSteps = [];
      
      steps.map(item => { Object.assign(item, { insertFromNavigator: {} }) });
      const newIndex = index + direction;
      if (newIndex >= 0 && newIndex < steps.length && index !== newIndex) {
        const step = steps.splice(index, 1)[0];
        steps.splice(newIndex, 0, step);
        
        setTimeout(() => {
          steps.forEach(arStep => {
            arStep.template = arStep.code;
            arStep.startState = arStep.state;
          });
          this.scriptedAutomationsModel.automationSteps = steps;
        }, 0);
      }
    },

    async getEditorValues(values, item, type, index) {
      if(index == this.firstActionIndex) {
        this.diagnosticsValues = [];
      }
      let diagnosticsValue = values.diagnostics;
      if (diagnosticsValue && diagnosticsValue.length > 0) {
        let val = {
          index: index,
          values: diagnosticsValue,
          type: type,
          stepName: item.label,
        }
        if(this.diagnosticsValues.find(item => item.index === val.index)) {
          
        } else {
          this.diagnosticsValues.push(val);
        }
      }

      await this.$refs.goalsFormRef.validate();
      this.validProgDialogVisible = false;

      item.isValid = values.isValid;
      item.code = values.code;
      if (item?.template) {
        item.template = item.template.replace(/\binstalls\b/g, 'tapInstalls').replace(/\btapInstallCPI\b/g, 'tapAvgCPA').replace(/\bcpa\b/g, 'tapAvgCPA').replace(/\bcr\b/g, 'tapInstallRate');
      }
      this.declaredReportVars = values.declaredReportVars
      if(values.declaredReportVars && values.declaredReportVars.length){
        for(let i=0; i<values.declaredReportVars.length; i++){
          this.scriptedArVarMap.campaign.propList[values.declaredReportVars[i]]=['AddRow([])']
          this.scriptedArVarMap.adgroup.propList[values.declaredReportVars[i]]=['AddRow([])']
          this.scriptedArVarMap.keyword.propList[values.declaredReportVars[i]]=['AddRow([])']
          this.scriptedArVarMap.onetime.propList[values.declaredReportVars[i]]=['AddRow([])']
        }
      }
      if(['onetime', 'onetime_blockly'].includes(type)) {
        // console.log('values', values.declaredVars)
        this.globalVariablesMap[index] = values.declaredVars;
        this.scriptedAutomationsModel.automationSteps.forEach((step, index) => {
          const arr = Object.keys(this.globalVariablesMap);
          const eligibleKeys = arr.filter(k => k < index);
          const vars = [];
          eligibleKeys.map(ek => vars.push(...this.globalVariablesMap[ek]))
          
          this.globalVariablesMapIndexWise[index] = vars;
        });
      }
      if(type === 'trigger') {
        item.comments = values.comments;
      }
      if(!this.stopValidating && index == this.lastIndex && (this.showValidationPopup || this.initTestRun || this.initSaveSar || this.initUpdateSar)) {
        this.ruleErrorsArray = [];
        this.validationError = false;
        this.validationErrorMessage = [];
        if (this.diagnosticsValues.length > 0) {
          this.validationError = true;
          this.validationErrorMessage = this.diagnosticsValues.map((diagnostics) =>  {
            let msg = ``;
           if (diagnostics.index && diagnostics.stepName) {
              msg = `
                Step ${diagnostics.index + 1} : ${diagnostics.stepName} - Validation Error:
                <ul>${diagnostics.values.map((item) => `<li>${item.message}</li>`).join('')}</ul>
              `;
            } else {
              msg = `
                Validation Error:<br>
                <ul>${diagnostics.values.map((item) => `<li>${item.message}</li>`).join('')}</ul>
              `;
            }
            return msg;
          }
          );
        }
        this.scriptedAutomationsModel.automationSteps.map((step, index) => {
          let errGoal = false
          this.goalFields.map(field => {
            if(step.code && step.code.includes(field)){
              errGoal = true
            }
          })
          if(errGoal && (!this.selectedGoals || this.selectedGoals.length === 0)){
            this.validationError = true;
            this.ruleErrorsArray.push({
              error: 'Goal not selected but goal variables are used in code:',
              message: 'Goals must be chosen when using goal variables.'
            });
          }

          let errSov = false
          this.sovFields.map(field => {
            if(step.code && step.code.includes(field)){
              errSov = true
            }
          })
          if(errSov && (!this.selectShareVoiceOption || this.selectShareVoiceOption.length === 0)){
            this.validationError = true;
            this.ruleErrorsArray.push({
              error: 'SOV not selected but sov variables are used in code:',
              message: 'Share of Voice Attribution must be chosen when using SOV variables.'
            });
          }
        });
        if (!this.editModeSAR && this.ruleName) {
          const trimmedV = this.ruleName.toLowerCase().trim();
          if (this.sarNames && this.sarNames.some((s) => s === trimmedV)) {
            this.validationError = true;
            this.ruleErrorsArray.push({
              error: 'Rule name already exists: ',
              message: `The rule name '${trimmedV}' already exists.`
            });
          }
        }
        if (!Number.isInteger(Number(this.settings.lookback)) || this.settings.lookback==='' || this.settings.lookback < 0 || this.settings.lookback >= 31){
        this.validationError = true;          
        this.ruleErrorsArray.push({
          error: 'Invalid lookback days entered:',
          message: 'A Lookback should be from 0 to 30 days.'
        });
        }
        this.goalSelected();
        // !triggerActionPreset 
        // anActionWithoutTrigger
        // !inputFormValid
        // !!areScriptsInvalid  
        if(!this.inputFormValid) {
        let errorTitle = "Input variables error:";
        let msgsArray = [];
        let uniqueCheck = false;
        this.superGlobals.map((item,index) => {
          let row = index+1;
          if(this.checkUnique(index) !== true) {
            if(!uniqueCheck) {
                msgsArray.push({
                  msgErrDesc: 'Input name must be unique'
                });
            }
            uniqueCheck = true;
          }
          if(item.inputName == '' || !(/^[a-zA-Z0-9 ]+$/.test(item.inputName))) {  
            msgsArray.push({
              msgErrDesc: 'Input name in #row '+ row + ' is invalid or incomplete.' 
            });
          }

          if(item.desc == '') {
            msgsArray.push({
              msgErrDesc: 'Description in #row '+ row + ' is invalid or incomplete.' 
            });
          }

          let valueError = false;
          if(item.value == '' || item.value == null) {
            let v = item.value;
            if(isNaN(v)){
              if(v.length==1 && (v=="'" || v=="\"")){
                valueError = true;
              }
              if(v[0] == "'" && v[v.length - 1] == "'"){
                
              }
              else if(v[0] == "\"" && v[v.length - 1] == "\""){
                
              }
              else {
                valueError = true;
              }
            } else {
              valueError = true;
            }
          }
          if(valueError) {
            msgsArray.push({
              msgErrDesc: 'Value in #row '+ row + ' is invalid or incomplete.' 
            });
          }

        });

        if(msgsArray.length > 0) {
          this.validationError = true;
          this.ruleErrorsArray.push({
            error: errorTitle,
            message: msgsArray
          });
        }

        }
        
        if(this.initTestRun) {
          if(this.validationError) {
            this.dialogVisible = true;
            this.dialogErrorMessage = this.validationErrorMessage;
          } else {
            if(this.enableSARLiveRun) this.testRun_cloud();
            else this.testRun_mono();
          }
          
        }
        if(this.initSaveSar) {
          if(this.ruleName == '') {
              this.validationError = true;
              this.dialogVisible = true;
              this.ruleErrorsArray.push({
                error: 'Missing/invalid rule name: ',
                message: 'A valid rule name must be entered.'
              });
            }
          if(this.validationError) {
            this.dialogVisible = true;
            this.dialogErrorMessage = this.validationErrorMessage;
          } else {
            this.sarAction(4, {});
          }
        }

         if(this.initUpdateSar) {
          if(this.validationError) {
            this.dialogVisible = true;
            this.dialogErrorMessage = this.validationErrorMessage;
          } else {
            this.sarAction(5, {});
          }
        }
        if(this.showValidationPopup) {
          this.dialogVisible = true;
          this.dialogErrorMessage = this.validationErrorMessage;
        }
        this.showValidationPopup = false;
        this.initTestRun = false;
        this.initSaveSar=false;
        this.initUpdateSar=false;
        this.diagnosticsValues = [];
      }
       const tempValidate = {};
      Object.keys(this.validate).forEach(k => {
        tempValidate[k] = null;
      });
      
      const nextActionIndex = this.scriptedAutomationsModel.automationSteps.findIndex((f, i) => !['trigger', 'trigger_blockly'].includes(f.type) && i > index);
      
      if (nextActionIndex !== -1) {
        this.validate = { ...tempValidate, [nextActionIndex]: Date.now() };
      }
    },

    checkSuspicion() {
      const steps = _.pick(this.scriptedAutomationsModel, ['automationSteps']).automationSteps || []
      let isSusp = false
      steps.forEach(step => {
        if(step.type !== 'trigger') return
        const currentStepQueryLower = step.code.toLowerCase().trim()
        if(!currentStepQueryLower.includes('.name') && this.disallowedKeywords.some(e => currentStepQueryLower.includes(' '+e+' '))) {
          isSusp = true
        }
      });
      return isSusp
    },

    async testRun_mono() {
      if(this.checkSuspicion()){
        this.$notify.error('Suspicious query detected in sql editor');
        return
      }
      await this.$refs.goalsFormRef.validate();
      this.validProgDialogVisible = false;

      if (!this.goalFormvalid) {
        await this.$refs.goalsRef.focus();
        return;
      }
      let data = _.pick(this.scriptedAutomationsModel, ['automationSteps'])
      if(this.settings.lookback || this.settings.lookback == 0){
        this.settings.lookback = + this.settings.lookback
      }else{
        this.settings.lookback = 30
      }
      const req = {
        automationSteps: data.automationSteps,
        goals: this.selectedGoals || [],
        settings: this.settings,
        superGlobals:this.superGlobalsModified,
        ruleName:this.ruleName,
        selectShareVoiceOption:this.selectShareVoiceOption
      }
      if(this.editModeSAR) req.ruleId = this.selectedSAR.ruleId
      else req.ruleId = this.tempRuleId
      // Vue.prototype.$sckt.emit('syncprogress:check', {
      //     teamId: decodeToken(LSS.token).TeamID,
      //     CorrID: uuidv4(),
      // });
      this.snapshotLoading = true;
      this.snapshotDialog = true;
      this.tooManyResults = false
      this.$apollo
        .query({
          query: TEST_RUN_SCRIPTED_AR,
          variables: {
            data: JSON.stringify(req),
          }
        })
        .then((res) => {
          // const data = JSON.parse(res.data.testRun.data)
          // this.jsonData = data.map(e => {
          //   return {
          //     type : e.step.type,
          //     code : e.step.code,
          //     output: e.output
          //   }
          // })
          // this.outputJson = this.jsonData.filter((e) => e.type !== 'trigger')
          // this.snapshotDialog = true
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async testRun_cloud() {
      if(this.checkSuspicion()){
        this.$notify.error('Suspicious query detected in sql editor');
        return
      }
      await this.$refs.goalsFormRef.validate();
      this.validProgDialogVisible = false;

      if (!this.goalFormvalid) {
        await this.$refs.goalsRef.focus();
        return;
      }
      let data = _.pick(this.scriptedAutomationsModel, ['automationSteps'])
      if(this.settings.lookback || this.settings.lookback == 0){
        this.settings.lookback = + this.settings.lookback
      }else{
        this.settings.lookback = 30
      }
      if(this.includeInactiveEntities){
        this.settings.activeEntitiesOnly = false
      }else{
        this.settings.activeEntitiesOnly = true
      }
      let mids = []
      if((this.selectedMailIds && this.selectedMailIds.length > 0)) {
        mids = mids.concat(this.selectedMailIds)
      }
      if(this.externalMailIds && this.externalMailIds.length > 0){
        mids = mids.concat(this.externalMailIds)
      }
      this.settings.emailIds = mids
      const req = {
        automationSteps: data.automationSteps,
        goals: this.selectedGoals || [],
        settings: this.settings,
        superGlobals:this.superGlobalsModified,
        ruleName:this.ruleName || 'Un-named',
        selectShareVoiceOption:this.selectShareVoiceOption
      }
      if(this.editModeSAR) req.ruleId = this.selectedSAR.ruleId
      else req.ruleId = this.tempRuleId

      this.sarExecutionLog = []
      this.sarElapsedTime = '00:00'
      const startTime = new Date().getTime(); 
      this.sarElapsedTimer = setInterval(() => {
        const now = new Date().getTime();    
        const elapsed = now - startTime;
        const minutes = Math.floor((elapsed % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((elapsed % (1000 * 60)) / 1000);
        this.$nextTick(() => {
          this.sarElapsedTime = (minutes.toString().length > 1 ? minutes.toString() : '0'+ minutes.toString()) + ':' + (seconds.toString().length > 1 ? seconds.toString() : '0'+ seconds.toString())
        })
      }, 1000);

      this.snapshotLoading = true;
      this.snapshotDialog = true;
      this.tooManyResults = false
      this.testRunProgressText = ['Preparing:']
      this.testRunId = 'TR' + (decodeToken(LSS.token).UserId).slice(0,6) + Date.now()
      const inputs = {
          ruleId: req.ruleId,
          user: {
              TeamID: decodeToken(LSS.token).TeamID,
              UserID: decodeToken(LSS.token).UserId,
          },
          queuedAt: Date.now(),
          genId: Date.now(),
          runHour: new Date().getUTCFullYear().toString()+(new Date().getUTCMonth() + 1).toString()+new Date().getUTCDate().toString() + new Date().getUTCHours().toString(),
          testRun: true,
          testRunId: this.testRunId,
          runId: this.testRunId,
          type: 'TR',
          data: req
      }

      this.$apollo
      .query({
        query: QUEUE_SAR_TEST_RUN,
        variables: {
          data: JSON.stringify(inputs),
          at: Date.now()
        },
      })
      .then((res) => {
        if(res.data.testRunCloud.successMessage === "OK") {
          this.testRunProgressText = [
            'Preparing:',
            '> Enqueueing rule...',
          ]
          this.$notify.success(`Testrun queued successfully`);
        }
        else {
          clearInterval(this.sarElapsedTimer);
          console.log(error)
          this.testRunProgressText = [
            'Preparing:',
            '> Enqueueing rule...',
            '> Unable to queue the rule'
          ]
          this.snapshotLoading = false;
          this.snapshotDialog = false;
          this.testRunProgressText = [];  
          this.$notify.error(`Test run unable to execute`);
        }
      })
      .catch((error) => {
        clearInterval(this.sarElapsedTimer);
        console.log(error)
        this.testRunProgressText = [
            'Preparing:',
            '> Enqueueing rule...',
            '> Unable to queue the rule'
        ]
        this.snapshotLoading = false;
        this.snapshotDialog = false;
        this.testRunProgressText = [];  
        this.$notify.error(`Unable to execute`);
      });
    },

    saveRule(){
      if(this.checkSuspicion()){
        this.$notify.error('Suspicious query detected');
        return
      }
      this.fullscreenLoader = this.$loading.show();
      let data = _.pick(this.scriptedAutomationsModel, ['automationSteps'])
      data = data.automationSteps.map(({template, insertFromNavigator, ...keep}) => keep)
      if(this.settings.lookback || this.settings.lookback == 0){
        this.settings.lookback = + this.settings.lookback
      }else{
        this.settings.lookback = 30
      }
      if(this.includeInactiveEntities){
        this.settings.activeEntitiesOnly = false
      }else{
        this.settings.activeEntitiesOnly = true
      }
      const req = {
        automationSteps: data,
        settings: this.settings,
        goals: this.selectedGoals || [],
        superGlobals: this.superGlobalsModified || [],
        selectShareVoiceOption:this.selectShareVoiceOption
      }
      this.$apollo
        .query({
          query: SAVE_SCRIPTED_AR,
          variables: {
            data: JSON.stringify(req),
            name: this.ruleName,
            ruleId: this.tempRuleId
          },
        })
        .then((res) => {
          // console.log(res)
          this.fullscreenLoader.hide();
          const data = res.data.saveSAR
          if(data.successMessage === "OK"){
            this.$notify.success('Rule saved successfully');
            // return this.$router.push({ path:'/arrules', query: {  sar: true }});
            return this.$router.push({ path:'/sar-proxy'});
          }else{
            this.$notify.error('Unable to save rule');
          }
        })
        .catch((error) => {
          //console.log(error);
          this.fullscreenLoader.hide();
          this.$notify.error('Unable to save rule');
        });
    },
    addEditEditor(rule) {
      let label;
      switch(rule.type) {
        case 'trigger_blockly':
        case 'trigger': {
          label = 'Trigger';
          break;
        }
        case 'campaign_blockly':
        case 'campaign': {
          label = 'Campaign Action';
          break;
        }
        case 'adgroup_blockly':
        case 'adgroup': {
          label = 'Ad Group Action';
          break;
        }
        case 'keyword_blockly':
        case 'keyword': {
          label = 'Keyword Action';
          break;
        }
        case 'onetime_blockly':
        case 'onetime': {
          label = 'One-Time Action'
          break;
        }
      }
      this.validate[this.scriptedAutomationsModel.automationSteps.length] = null;
      this.scriptedAutomationsModel.automationSteps.push({
            ...rule,
            label: label,
            titleEditable: false,
            template: rule.code
      });

      this.scriptedAutomationsModel.automationSteps.forEach((step, index) => {
          const arr = Object.keys(this.globalVariablesMap);
          const eligibleKeys = arr.filter(k => k < index);
          const vars = [];
          eligibleKeys.map(ek => vars.push(...this.globalVariablesMap[ek]))
          
          this.globalVariablesMapIndexWise[index] = vars;
      });
    },
    async editSAR(rule){
      this.editModeSAR = true
      this.selectedSAR = rule
      let modeToggleType;
      this.sarName = rule.ruleName.toLowerCase().trim()
      this.ruleName = rule.ruleName
      this.settings = rule.settings
      this.superGlobals = rule.superGlobals??[]
      this.selectShareVoiceOption =rule.selectShareVoiceOption
      if (this.settings.isDated) {
        const value = [
          this.settings.dateRange.from,
          this.settings.dateRange.to,
        ];
        this.ruleDateRange = value;
        this.ruleDateRangelModel = value;
      } else {
        this.ruleDateRange = [];
        this.ruleDateRangelModel = [];
      }
      await this.$apollo.queries.getEmails.refetch()
      const emailIdsNotInEmailList = rule.settings.emailIds.filter(emailId => !this.emailList.map(item => item.Email).includes(emailId))
      const emailIdsInEmailList = rule.settings.emailIds.filter(emailId => this.emailList.map(item => item.Email).includes(emailId))      
      this.selectedMailIds = emailIdsInEmailList
      this.externalMailIds = emailIdsNotInEmailList
      if(this.emailList && this.emailList.length > 0 && emailIdsInEmailList.length === this.emailList.length) {
        this.selectAllEmails = true
        this.selectAllEmailIcon = 'mdi-checkbox-multiple-marked'
      }
      this.selectedSlacks = rule.settings.slackIds
      if(this.selectedSlacks && this.slackChannelList.length > 0 && this.selectedSlacks.length === this.slackChannelList.length) {
        this.selectAllSlacks = true
        this.selectAllSlackIcon = 'mdi-checkbox-multiple-marked'
      }
      if(rule.settings.activeEntitiesOnly === true){
        this.includeInactiveEntities = false
      }else{
        this.includeInactiveEntities = true
      }
      // this.sarNames = rule.existingNames || []
      this.scriptedAutomationsModel = {
        automationSteps: []
      }
      rule.automationSteps.forEach((element, index) => {
        if(['onetime', 'onetime_blockly'].includes(element.type)) {
          
          const declaredVars = new Set()
          const nodes = parse(element.code)
          nodes.forEach(node => {
            if(node.name === 'VariableDefinition') {
              declaredVars.add(element.code.slice(node.from, node.to))
            }
          })
          
          this.globalVariablesMap[index] = declaredVars;
          
        }
        const arr = Object.keys(this.globalVariablesMap);
        const eligibleKeys = arr.filter(k => k < index);
        const vars = [];
        eligibleKeys.map(ek => vars.push(...this.globalVariablesMap[ek]))
        
        this.globalVariablesMapIndexWise[index] = vars;
        
        element.startState = element.state;
        this.addEditEditor(element)
        
        if(/blockly/.test(element.type)) modeToggleType = 'blocklyeditor';
        else modeToggleType = 'jseditor';
      });
      
      this.modeToggle = modeToggleType;

      this.selectedGoals = rule.goals
    },
    updateSAR(){
      this.fullscreenLoader = this.$loading.show();
      let data = _.pick(this.scriptedAutomationsModel, ['automationSteps'])
      data = data.automationSteps.map(({template, insertFromNavigator, ...keep}) => keep)
      if(this.settings.lookback || this.settings.lookback == 0){
        this.settings.lookback = + this.settings.lookback
      }else{
        this.settings.lookback = 30
      }
      if(this.includeInactiveEntities){
        this.settings.activeEntitiesOnly = false
      }else{
        this.settings.activeEntitiesOnly = true
      }
      const req = {
        automationSteps: data,
        settings: this.settings,
        goals: this.selectedGoals || [],
        ruleId: this.selectedSAR.ruleId,
        superGlobals:this.superGlobalsModified || [],
        selectShareVoiceOption: this.selectShareVoiceOption
      }
      // console.log(req)
      this.$apollo
        .query({
          query: UPDATE_SCRIPTED_AR,
          variables: {
            data: JSON.stringify(req),
            name: this.ruleName,
            ruleId: this.selectedSAR._id
          },
        })
        .then((res) => {
          this.fullscreenLoader.hide();
          const data = res.data.updateSAR
          if(data.successMessage === "OK"){
            this.$notify.success('Rule updated successfully');
            // return this.$router.push({ path:'/arrules', query: {  sar: true }});
            return this.$router.push({ path:'/sar-proxy'});
          }else{
            this.$notify.error('Unable to update rule');
          }
        })
        .catch((error) => {
          this.fullscreenLoader.hide();
          this.$notify.error('Unable to update rule');
        });
    },
    async sarAction(id, data){
      await this.$refs.sarSettings.validate();
      if (!this.sarSettingsFormvalid) {
        return;
      }
      await this.$refs.goalsFormRef.validate();
      this.validProgDialogVisible = false;

      if (!this.goalFormvalid) {
        await this.$refs.goalsRef.focus();
        return;
      }
      if(this.settings.sendReports){
        if(this.selectedMailIds.length === 0 && this.selectedSlacks.length === 0 && this.externalMailIds.length === 0){
          this.$notify.error('When send reports checkbox is checked, you need to enter atleast one mode of communication');
          return
        }else{
          this.settings.emailIds = this.selectedMailIds.concat(this.externalMailIds)
          this.settings.slackIds = this.selectedSlacks
        }
      }
      if(this.ruleDateRange && this.ruleDateRange.length === 2) {
        this.settings.isDated = true;
        this.settings.dateRange = {
          from: this.ruleDateRange[0],
          to: this.ruleDateRange[1],
        };
      } else {
        this.settings.isDated = false;
        this.settings.dateRange = null;
      }
      this.decisionAlertFlag = true;
      this.decisionAlertTitle = ' ';
      switch (id) {
        case 4:
          this.decisionAlertMessage = this.$t('aRules.saveSAR');
          break;
        case 5:
          this.decisionAlertMessage = this.$t('aRules.updateSAR');
          break;
        default:
          break;
      }
      this.decisionAlertData = {
        id: id,
        from: 'SAR',
        now: Date.now(),
        sarData: data
      };
    },
    applyRuleDateRange() {
      this.ruleDateRange = this.ruleDateRangelModel;
      this.ruleRangePicker = false;
    },
    clearRuleDateRange() {
      this.ruleDateRange = [];
      this.ruleDateRangelModel = [];
      this.ruleRangePicker = false;
    },
    getAllScriptedRules() {
      this.arScriptedItems = [];

      this.$apollo
        .query({
          query: GET_ALL_RULES_SCRIPTED,
          variables: {
            now: Date.now(),
          },
        })
        .then((res) => {
          if (
            res.data.getAllScriptedRules == null ||
            res.data.getAllScriptedRules == undefined
          ) {
            return;
          }
          this.arScriptedItems = res.data.getAllScriptedRules;
          this.sarNames = res.data.getAllScriptedRules.map(e => e.ruleName.toLowerCase().trim())
        })
        .catch((error) => {
          //console.log(error);
          //this.noDataTextKeys = 'No data to show';
        });
    },
  },
  mounted() {
    this.scriptedArVarMap = sarVarMap;
    hljs.registerLanguage('javascript', javascript);
    hljs.registerLanguage('sql', sql)
    
    this.syncStatus = LSS.activeSync;
    Vue.prototype.$sckt.on('syncprogress:event', () => {
      this.syncStatus = LSS.activeSync;
    });

    if(LSS.token && decodeToken(LSS.token)){
      const TeamID = decodeToken(LSS.token).TeamID
      // this.enableSARLiveRun = false
      // const teamIds = ['C00394-SAM-QA','570f929d-91a0-4d3e-8265-ca023e719d7b','8332aa86-7684-44f8-a2f3-75386840ee35','C00387-SAM-QA']
      // this.enableSARLiveRun = teamIds.includes(TeamID)
      this.enableSARLiveRun = true
    }

    if(LSS.token && decodeToken(LSS.token)){
      this.uid = decodeToken(LSS.token).UserId
    }else{
      this.uid = '1234-qwer'
    }

    Vue.prototype.$sckt.on(this.uid, async (testRunEvent) => {
      if(!testRunEvent) return
      if(this.enableSARLiveRun){
        if(!testRunEvent || !testRunEvent.msg) return
        if(this.tooManyResults || testRunEvent.msg.testRunId != this.testRunId) return
        if(testRunEvent.msg.executionLog && testRunEvent.msg.executionLog.length >= this.testRunProgressText.length) {
          this.testRunProgressText = testRunEvent.msg.executionLog
          this.sarExecutionLog = testRunEvent.msg.executionLog // .join('\n')
        }
        switch(testRunEvent.msg.msg) {
          case 'RESULTS': {
            await (this.$apollo.queries.getGoals.refetch()).catch(e => console.log(e))
            const data = JSON.parse(testRunEvent.msg.data)
            this.jsonData = data.output.map(e => {
              return {
                type : e.step.type,
                code : e.step.code,
                codeWithoutComments: e.step.codeWithoutComments,
                output: e.output,
                title: e.step.title,
              }
            })
            this.outputJson = {
              goalsSelected: data.goals || [],
              result: this.jsonData.filter((e) =>  !['trigger', 'trigger_blockly'].includes(e.type)),
            }
            this.humanJson = await this.humanForm(this.jsonData, data.lookback, data.metricLog,data.sovLog);
            Object.assign(this.humanJson,{ executionLog: this.testRunProgressText })
            const temp = {            
              lookback_window: data.lookback,
              result: this.jsonData,
              metric_log: data.metricLog,
              sovLog: data.sovLog,
              executionLog: this.testRunProgressText
            }
            clearInterval(this.sarElapsedTimer);
            this.jsonData = temp
            this.snapshotLoading = false;
            // this.testRunProgressText = [];
            break;
          }
          case 'ERROR': {            
              clearInterval(this.sarElapsedTimer);
              this.$notify.error('Unable to complete test run');
              this.snapshotLoading = false;
              // this.testRunProgressText = [];
              break;
          }
          case 'ACTION_ERROR': {            
              clearInterval(this.sarElapsedTimer);
              this.$notify.error('Unable to complete test run action');
              this.snapshotLoading = false;
              // this.testRunProgressText = [];
              break;
          }
          default : {
            
          }
        }
      }else{
        if(testRunEvent.from !== 'SAR' || this.tooManyResults) return
        // console.log('testRunEvent',testRunEvent)
        // this.tooManyResults = false
        this.showMysqlprogress = false
        this.mysqlProgress = testRunEvent.progress? testRunEvent.progress : 0
        switch(testRunEvent.successMessage) {
          case 'STARTED': {
            this.testRunProgressText = 'Test run started. Preparing MySQL'
            break;
          }
          case 'MYSQL_TABLES_CREATED': {
            this.testRunProgressText = 'MySQL Tables created'
            break;
          }
          case 'MYSQL_TABLES_EMPTIED': {
            this.testRunProgressText = 'Discarding previous MySQL data'
            break;
          }
          case 'MYSQL_CAMPS_PROCESSING': {
            this.testRunProgressText = 'Populating campaigns data'
            this.showMysqlprogress = true
            break;
          }
          case 'MYSQL_CAMPS_DONE': {
            this.testRunProgressText = 'Campaigns populated successfully'
            break;
          }
          case 'MYSQL_NO_CAMPS': {
            this.testRunProgressText = 'No campaigns found'
            break;
          }
          case 'MYSQL_ADS_PROCESSING': {
            this.testRunProgressText = 'Populating adgroups data'
            this.showMysqlprogress = true
            break;
          }
          case 'MYSQL_ADS_DONE': {
            this.testRunProgressText = 'Adgroups populated successfully'
            break;
          }
          case 'MYSQL_NO_ADS': {
            this.testRunProgressText = 'No adgroups found'
            break;
          }
          case 'MYSQL_KEYS_PROCESSING': {
            this.testRunProgressText = 'Populating keywords data'
            this.showMysqlprogress = true
            break;
          }
          case 'MYSQL_KEYS_DONE': {
            this.testRunProgressText = 'Keywords populated successfully'
            break;
          }
          case 'MYSQL_NO_KEYS': {
            this.testRunProgressText = 'No keywords found'
            break;
          }
          case 'TOO_MANY_CAMPS': {
            this.tooManyResults = true
            this.testRunProgressText = 'Your trigger has resulted in more than 100 campaigns, 300 adgroups, or 1000 keywords to take action on. Please define trigger conditions which are more narrowly scoped and produce results within these limits.'
            break;
          }
          case 'TOO_MANY_ADS': {
            this.tooManyResults = true
            this.testRunProgressText = 'Your trigger has resulted in more than 100 campaigns, 300 adgroups, or 1000 keywords to take action on. Please define trigger conditions which are more narrowly scoped and produce results within these limits.'
            break;
          }
          case 'TOO_MANY_KEYS': {
            this.tooManyResults = true
            this.testRunProgressText = 'Your trigger has resulted in more than 100 campaigns, 300 adgroups, or 1000 keywords to take action on. Please define trigger conditions which are more narrowly scoped and produce results within these limits.'
            break;
          }
          case 'STEP': {
            this.testRunProgressText = 'Running step ' + (testRunEvent.step.index + 1)
            break;
          }
          case 'RUNNING_TRIGGER': {
            this.testRunProgressText = `Executing trigger ${testRunEvent.triggerItemIndex + 1}`
            break;
          }
          case 'RUNNING_ACTION': {
            this.testRunProgressText = `Executing ${testRunEvent.step.type} action ${testRunEvent.step.index + 1} on result ${testRunEvent.actionItemIndex}/${testRunEvent.actionItemsCount}`
            break;
          }
          case 'RUNNING_ONE_TIME_ACTION': {
            this.testRunProgressText = `Executing one time action ${testRunEvent.step.index + 1}`
            break;
          }
          case 'RESULTS': {
            await this.$apollo.queries.getGoals.refetch()
            const data = JSON.parse(testRunEvent.data)
            this.jsonData = data.output.map(e => {
              return {
                type : e.step.type,
                code : e.step.code,
                codeWithoutComments: e.step.codeWithoutComments,
                output: e.output,
                title: e.step.title,
              }
            })
            this.outputJson = {
              goalsSelected: data.goals || [],
              result: this.jsonData.filter((e) =>  !['trigger', 'trigger_blockly'].includes(e.type)),
            }
            this.humanJson = await this.humanForm(this.jsonData, data.lookback, data.metricLog,data.sovLog);
            const temp = {            
              lookback_window: data.lookback,
              result: this.jsonData,
              metric_log: data.metricLog,
              sovLog:data.sovLog
            }
            this.jsonData = temp
            this.snapshotLoading = false;
            this.testRunProgressText = '';      
            break;
          }
          case 'ERROR': {
              this.$notify.error('Unable to complete test run');
              this.snapshotLoading = false;
              this.testRunProgressText = '';
              break;
          }
          default : {
            // console.log('testRunEvent',testRunEvent)
          }
        }
      }
    });

    this.locale = localStorage.getItem('locale') || 'en';
    // this.getAllRules();
    // this.getAllLogs();
    // this.getAllGoals();
    this.getAllScriptedRules()
    updateCLF('automationRules', this);

    const sar = this.$route.query.sar ? this.$route.query.sar : '';
    if (sar) {
      this.$router.replace('/scripted-rule')
      try {
        const r = JSON.parse(LSS.editSAR)
        this.editSAR(r)
      } catch (error) {
        this.$notify.error('Unable to fetch rule details');
        // return this.$router.push({ path:'/arrules', query: {  sar: true }});
        return this.$router.push({ path:'/sar-proxy'});
      }
    }

    this.$root.$on('locale-changed', (locale) => {
      this.locale = locale.localeCode;
    });
    this.tempRuleId = uuidv4();
  },
  created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
};
</script>

<style>
  .v-navigation-drawer__content {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .theme--light.v-application pre code {
    color: black;
    padding: 20px;
    width: 100%;
    display: block;
  }
  .min-w-fit-content {
    min-width: fit-content;
  }
  .error-card {
    background-color: #ffebee;
    border: 1px solid #d32f2f;
  }
  .error-height {
    max-height: 300px;
    height: 100%;
  }

  .testRun {
    p {
      margin-bottom: 5px;
    }
  }
</style>