











import * as _ from 'lodash';
import Blockly from 'blockly';
import Vue from 'vue';

import './mutator';

interface CustomBlocklyGenerator extends Blockly.CodeGenerator {
  ORDER_ATOMIC?: number;
  ORDER_NONE?: number;
  ORDER_UNARY_NEGATION?: number;
  ORDER_ADDITION?: number;
  ORDER_SUBTRACTION?: number;
  ORDER_MULTIPLICATION?: number;
  ORDER_DIVISION?: number;
  ORDER_FUNCTION_CALL?: number;
  ORDER_RELATIONAL?: number;
  ORDER_QUERY_PART?: number;
}



const triggerSQLGenerator: CustomBlocklyGenerator = new Blockly.Generator('triggerSQL');
triggerSQLGenerator.ORDER_ATOMIC = 0;
triggerSQLGenerator.ORDER_NONE = 0;
triggerSQLGenerator.ORDER_UNARY_NEGATION = 4.3;
triggerSQLGenerator.ORDER_ADDITION = 6.2;
triggerSQLGenerator.ORDER_SUBTRACTION = 6.1;
triggerSQLGenerator.ORDER_MULTIPLICATION = 5.1;
triggerSQLGenerator.ORDER_DIVISION = 5.2;
triggerSQLGenerator.ORDER_FUNCTION_CALL = 2;
triggerSQLGenerator.ORDER_RELATIONAL = 8;
triggerSQLGenerator.ORDER_QUERY_PART = 10;

export default Vue.extend({
  name: 'BlocklyTriggers',
  props: {
    triggerVarMap: {
      type: Object,
      default() { return {} }
    },
    state: {
      type: Object,
      default: () => null
    },
    superGlobals: {
      type: Array
    }
  },
  data() {
    return {
      categoriesMap: {
        campaign: 'campaignProps',
        adgroup: 'adgroupProps',
        keyword: 'keywordProps'
      },
      id: 'blocklyTriggers-' + Date.now(),
      serializer: new Blockly.serialization.blocks.BlockSerializer(),
      workspace: null as Blockly.WorkspaceSvg,
      toolbox: {
        kind: 'categoryToolbox',
        contents: [
          {
            kind: 'category',
            name: 'Query',
            contents: [
              {
                kind: 'block',
                type: 'query_join',
                extraState: { customName: 'Trigger When', itemCount: 2 }
              },
              {
                kind: 'block',
                type: 'query_any_of',
                extraState: { customName: 'Any Of', itemCount: 2 },
                inputs: {
                  ADD0: {
                    block: {
                      type: 'logic_compare_sql',
                      fields: { operator: ">" },
                      inputs: {
                        left: {
                          block: {
                            type: 'target_query',
                            fields: { category: 'campaign', asavar: 'tapInstalls' }
                          }
                        },
                        right: {
                          block: {
                            type: 'math_number',
                            fields: { NUM: 0 }
                          }
                        }
                      }
                    }
                  },
                  ADD1: {
                    block: {
                      type: 'logic_compare_sql',
                      fields: { operator: "=" },
                      inputs: {
                        left: {
                          block: {
                            type: 'target_query',
                            fields: { category: 'campaign', asavar: 'state' }
                          }
                        },
                        right: {
                          block: {
                            type: 'text',
                            fields: { TEXT: 'ENABLED' }
                          }
                        }
                      }
                    }
                  }
                }
              },
              {
                kind: 'block',
                type: 'query_all_of',
                extraState: { customName: 'All Of', itemCount: 2 },
                inputs: {
                  ADD0: {
                    block: {
                      type: 'logic_compare_sql',
                      fields: { operator: ">" },
                      inputs: {
                        left: {
                          block: {
                            type: 'target_query',
                            fields: { category: 'campaign', asavar: 'tapInstalls' }
                          }
                        },
                        right: {
                          block: {
                            type: 'math_number',
                            fields: { NUM: 0 }
                          }
                        }
                      }
                    }
                  },
                  ADD1: {
                    block: {
                      type: 'logic_compare_sql',
                      fields: { operator: "=" },
                      inputs: {
                        left: {
                          block: {
                            type: 'target_query',
                            fields: { category: 'campaign', asavar: 'state' }
                          }
                        },
                        right: {
                          block: {
                            type: 'text',
                            fields: { TEXT: 'ENABLED' }
                          }
                        }
                      }
                    }
                  }
                }
              },
              {
                kind: 'block',
                type: 'target_query'
              },
              {
                kind: 'block',
                type: 'and_query'
              },
              {
                kind: 'block',
                type: 'or_query'
              },
              {
                kind: 'block',
                type: 'like_query'
              },
              {
                kind: 'block',
                type: 'not_like_query'
              },
              {
                kind: 'block',
                type: 'orderby_query'
              },
              {
                kind: 'block',
                type: 'orderby_limit_combined_query',
                inputs: {
                  field: {
                    block: {
                      type: 'target_query',
                      fields: { category: 'campaign', asavar: 'tapInstalls' }
                    }
                  },
                  limit: {
                    block: {
                      type: 'math_number',
                      fields: { NUM: 100 }
                    }
                  }
                }
              },
              {
                kind: 'block',
                type: 'limit_query'
              },
              {
                kind: 'block',
                type: 'limit_alt_query'
              }
            ],
            "cssConfig": {
              "icon": "blocklyTreeIconquery"
            },
          },
          {
            kind: 'category',
            name: 'Logic',
            contents: [
              {
                kind: 'block',
                type: 'logic_compare_sql'
              },
              {
                kind: 'block',
                type: 'math_arithmetic'
              },
            ],
            "cssConfig": {
              "icon": "blocklyTreeIcon"
            },
          },
          {
            kind: 'category',
            name: 'Values',
            contents: [
              {
                kind: 'block',
                type: 'text'
              },
              {
                kind: 'block',
                type: 'math_number'
              }
            ],
            "cssConfig": {
              "icon": "blocklyTreeIconvalue"
            },
          }
        ]
      }
    }
  },

  watch: {
    state: function (state) {
      if (state && this.workspace) {
        let jsonString = JSON.stringify(state);
        jsonString = jsonString.replace(/\binstalls\b/g, 'tapInstalls').replace(/\bcpa\b/g, 'tapAvgCPA').replace(/\btapInstallCPI\b/g, 'tapAvgCPA').replace(/\bcr\b/g, 'tapInstallRate');
        state = JSON.parse(jsonString);

        this.serializer.load(state, this.workspace);
      }
    },
    superGlobals: {
      deep: true,
      handler (value, oldValue) {
        if (JSON.stringify(value.sort()) != JSON.stringify(oldValue.sort())) {
          const blocks = this.workspace.getAllBlocks()
          const superGlobalBlocks = blocks.filter(e=> e.superGlobal)
          const superGlobalBlocksToDelete = superGlobalBlocks.filter(e=> !value.includes(e.type))
          superGlobalBlocksToDelete.forEach(el=>{
            var block = this.workspace.getBlockById(el.id);
            if (block) {
              block.dispose();
            }
          })
        }
        this.populateVars(value);
      }  
    }
  },

  methods: {
     deleteBlocks(blockTypes) {
        blockTypes.forEach(function(blockType) {
            delete Blockly.Blocks[blockType];
        });
    },
    populateVars(vars) {
      const contents = new Set();
      vars.filter((str) => str !== '@').forEach(varName => {
        delete Blockly.Blocks[varName];
        Blockly.defineBlocksWithJsonArray([{
          type: varName,
          message0: varName,
          output: null,
          extensions: ['custom_data']
        }]);

        triggerSQLGenerator['forBlock'][varName] = block => {
          const code = varName;
          return [code, triggerSQLGenerator.ORDER_ATOMIC];
        };

        contents.add({ kind: 'block', type: varName });
      })

      const index = this.toolbox.contents.findIndex(c => c.name === 'Global Variables');
      if (contents.size) {
        if (index === -1) {
          this.toolbox.contents.push({
            kind: 'category',
            name: 'Global Variables',
            contents: Array.from(contents)
          })
        } else {
          this.toolbox.contents[index].contents = Array.from(contents);
        }
      } else {
        if (index !== -1) this.toolbox.contents.splice(index, 1)
      }


      this.workspace.updateToolbox(this.toolbox);
    },
    setupToolbox() {
      const map = {};
      const categoriesDropdown = Object.keys(this.categoriesMap).map(key => [key, key]);
      const asavarsDropdown = [];
      Object.keys(this.categoriesMap).forEach(key => {
        this.triggerVarMap[this.categoriesMap[key]].forEach(varkey => {
          if (map[varkey]) return;
          asavarsDropdown.push([varkey, varkey]);
          map[varkey] = true;
        })
      });

      Blockly.defineBlocksWithJsonArray([
        {
          type: 'query_join',
          message0: '',
          output: 'String',
          mutator: 'join_mutator',
          colour: '#8b1e8f'
        },
        {
          type: 'query_any_of',
          message0: '',
          output: 'String',
          mutator: 'join_mutator',
          colour: '#8b1e8f'
        },
        {
          type: 'query_all_of',
          message0: '',
          output: 'String',
          mutator: 'join_mutator',
          colour: '#8b1e8f'
        },
        {
          type: 'target_query',
          message0: '%1.%2',
          args0: [
            {
              type: 'field_dropdown',
              name: 'category',
              options: categoriesDropdown
            },
            {
              type: 'field_dropdown',
              name: 'asavar',
              options: asavarsDropdown
            }
          ],
          inputsInline: true,
          output: 'Number',
          colour: '#8b1e8f'
        },
        {
          type: 'and_query',
          message0: 'and',
          colour: '#8b1e8f',
          output: 'String'
        },
        {
          type: 'or_query',
          message0: 'or',
          colour: '#8b1e8f',
          output: 'String'
        },
        {
          type: 'like_query',
          message0: '%1 like %2',
          args0: [
            {
              type: 'input_value',
              name: 'field',
              check: ['Number', 'String', 'Boolean']
            },
            {
              type: 'input_value',
              name: 'compare_to',
              check: ['Number', 'String', 'Boolean']
            }
          ],
          colour: '#8b1e8f',
          inputsInline: true,
          output: 'String'
        },
        {
          type: 'not_like_query',
          message0: '%1 not like %2',
          args0: [
            {
              type: 'input_value',
              name: 'field',
              check: ['Number', 'String', 'Boolean']
            },
            {
              type: 'input_value',
              name: 'compare_to',
              check: ['Number', 'String', 'Boolean']
            }
          ],
          colour: '#8b1e8f',
          inputsInline: true,
          output: 'String'
        },
        {
          type: 'orderby_query',
          message0: 'order by %1 %2',
          args0: [
            {
              type: 'input_value',
              name: 'field',
              check: ['Number', 'String', 'Boolean']
            },
            {
              type: 'field_dropdown',
              name: 'sort_order',
              options: [['desc', 'desc'], ['asc', 'asc']]
            }
          ],
          colour: '#8b1e8f',
          inputsInline: true,
          output: 'String'
        },
        {
          type: 'orderby_limit_combined_query',
          message0: 'Limit to first %1 results sorted by %2 %3',
          args0: [
            {
              type: 'input_value',
              name: 'limit',
              check: ['Number', 'String', 'Boolean']
            },
            {
              type: 'input_value',
              name: 'field',
              check: ['Number', 'String', 'Boolean']
            },
            {
              type: 'field_dropdown',
              name: 'sort_order',
              options: [['desc', 'desc'], ['asc', 'asc']]
            },
          ],
          colour: '#8b1e8f',
          inputsInline: true,
          output: 'String'
        },
        {
          type: 'limit_query',
          message0: 'limit %1',
          args0: [
            {
              type: 'input_value',
              name: 'limit',
              check: ['Number', 'String', 'Boolean']
            }
          ],
          colour: '#8b1e8f',
          inputsInline: true,
          output: 'String'
        },
        {
          type: 'limit_alt_query',
          message0: 'limit %1 %2',
          args0: [
            {
              type: 'input_value',
              name: 'offset',
              check: ['Number', 'String', 'Boolean']
            },
            {
              type: 'input_value',
              name: 'limit',
              check: ['Number', 'String', 'Boolean']
            }
          ],
          colour: '#8b1e8f',
          inputsInline: true,
          output: 'String'
        },
        {
          type: 'logic_compare_sql',
          message0: '%1 %2 %3',
          args0: [
            {
              type: 'input_value',
              name: 'left',
              check: ['Number', 'String', 'Boolean']
            },
            {
              type: 'field_dropdown',
              name: 'operator',
              options: [['<', '<'], ['>', '>'], ['=', '='], ['≠', '!='], ['>=', '>='], ['<=', '<=']],

            },
            {
              type: 'input_value',
              name: 'right',
              check: ['Number', 'String', 'Boolean']
            }
          ],
          inputsInline: true,
          output: 'Boolean',
          colour: '#1176AE'
        },
        {
          type: 'logic_operation',
          message0: '%1 %2 %3',
          args0: [
            {
              type: 'input_value',
              name: 'left',
              check: ['Number', 'String', 'Boolean']
            },
            {
              type: 'field_dropdown',
              name: 'operator',
              options: [['and', 'and'], ['or', 'or']]
            },
            {
              type: 'input_value',
              name: 'right',
              check: ['Number', 'String', 'Boolean']
            }
          ],
          inputsInline: true,
          output: 'Boolean',
          colour: '#1176AE'
        }
      ])



      // Custom logic blocks

      triggerSQLGenerator['forBlock']['query_join']= function (block : any) {
        const elements = new Array(block.itemCount_);
        for (let i = 0; i < block.itemCount_; i++) {
          elements[i] = triggerSQLGenerator.valueToCode(block, 'ADD' + i, triggerSQLGenerator.ORDER_NONE) || null;
        }
        const code = elements.join(' ');
        return [code, triggerSQLGenerator.ORDER_NONE];
      };

      triggerSQLGenerator['forBlock']['query_any_of'] = function (block : any) {
        const elements = new Array(block.itemCount_);
        for (let i = 0; i < block.itemCount_; i++) {
          elements[i] = triggerSQLGenerator.valueToCode(block, 'ADD' + i, triggerSQLGenerator.ORDER_NONE) || null;
        }
        const code = elements.join(' or ');
        return [code, triggerSQLGenerator.ORDER_QUERY_PART];
      };

      triggerSQLGenerator['forBlock']['query_all_of'] = function (block : any) {
        const elements = new Array(block.itemCount_);
        for (let i = 0; i < block.itemCount_; i++) {
          elements[i] = triggerSQLGenerator.valueToCode(block, 'ADD' + i, triggerSQLGenerator.ORDER_NONE) || null;
        }
        const code = elements.join(' and ');
        return [code, triggerSQLGenerator.ORDER_QUERY_PART];
      };

      triggerSQLGenerator['forBlock']['target_query'] = function (block) {
        const value_left = block.getFieldValue('category');
        const value_right = block.getFieldValue('asavar');
        const code = value_left + '.' + value_right;
        return [code, triggerSQLGenerator.ORDER_NONE];
      };

      triggerSQLGenerator['forBlock']['and_query'] = function (block) {
        const code = 'and'
        return [code, triggerSQLGenerator.ORDER_NONE];
      };

      triggerSQLGenerator['forBlock']['or_query'] = function (block) {
        const code = 'or'
        return [code, triggerSQLGenerator.ORDER_NONE];
      };

      triggerSQLGenerator['forBlock']['like_query'] = function (block) {
        const field_value = triggerSQLGenerator.valueToCode(block, 'field', triggerSQLGenerator.ORDER_ATOMIC);
        const compare = triggerSQLGenerator.valueToCode(block, 'compare_to', triggerSQLGenerator.ORDER_ATOMIC);
        const code = field_value + ' like %' + (compare && compare.replace(/"/g, '')) + '%';
        return [code, triggerSQLGenerator.ORDER_NONE];
      };

      triggerSQLGenerator['forBlock']['not_like_query'] = function (block) {
        const field_value = triggerSQLGenerator.valueToCode(block, 'field', triggerSQLGenerator.ORDER_ATOMIC);
        const compare = triggerSQLGenerator.valueToCode(block, 'compare_to', triggerSQLGenerator.ORDER_ATOMIC);
        const code = field_value + ' not like %' + (compare && compare.replace(/"/g, '')) + '%';
        return [code, triggerSQLGenerator.ORDER_NONE];
      };

      triggerSQLGenerator['forBlock']['orderby_query'] = function (block) {
        const field_value = triggerSQLGenerator.valueToCode(block, 'field', triggerSQLGenerator.ORDER_ATOMIC);
        const sort_order = block.getFieldValue('sort_order')
        const code = 'order by ' + field_value + ' ' + sort_order;
        return [code, triggerSQLGenerator.ORDER_NONE];
      };

      triggerSQLGenerator['forBlock']['limit_query'] = function (block) {
        const limit_value = triggerSQLGenerator.valueToCode(block, 'limit', triggerSQLGenerator.ORDER_ATOMIC);
        const code = 'limit ' + limit_value;
        return [code, triggerSQLGenerator.ORDER_NONE];
      };
      
      triggerSQLGenerator['forBlock']['orderby_limit_combined_query'] = function (block) {
        const field_value = triggerSQLGenerator.valueToCode(block, 'field', triggerSQLGenerator.ORDER_ATOMIC);
        const sort_order = block.getFieldValue('sort_order');
        const limit_value = triggerSQLGenerator.valueToCode(block, 'limit', triggerSQLGenerator.ORDER_ATOMIC);
        const code = 'order by ' + field_value + ' ' + sort_order + ' limit ' + limit_value;
        return [code, triggerSQLGenerator.ORDER_NONE];
      };

      triggerSQLGenerator['forBlock']['orderby_limit_combined_query'] = function (block) {
        const field_value = triggerSQLGenerator.valueToCode(block, 'field', triggerSQLGenerator.ORDER_ATOMIC);
        const sort_order = block.getFieldValue('sort_order');
        const limit_value = triggerSQLGenerator.valueToCode(block, 'limit', triggerSQLGenerator.ORDER_ATOMIC);
        const code = 'order by ' + field_value + ' ' + sort_order + ' limit ' + limit_value;
        return [code, triggerSQLGenerator.ORDER_NONE];
      };

      triggerSQLGenerator['forBlock']['limit_alt_query'] = function (block) {
        const offset_value = triggerSQLGenerator.valueToCode(block, 'offset', triggerSQLGenerator.ORDER_ATOMIC);
        const limit_value = triggerSQLGenerator.valueToCode(block, 'limit', triggerSQLGenerator.ORDER_ATOMIC);
        const code = 'limit ' + offset_value + ', ' + limit_value;
        return [code, triggerSQLGenerator.ORDER_NONE];
      };

      triggerSQLGenerator['forBlock']['math_number'] = function (block) {
        const code = Number(block.getFieldValue('NUM'));
        const order = triggerSQLGenerator.ORDER_ATOMIC;
        return [''+ code || "0", order];
      };

      triggerSQLGenerator['forBlock']['math_arithmetic'] = function (block) {
        // Basic arithmetic operators, and power.
        const OPERATORS = {
          'ADD': [' + ', triggerSQLGenerator.ORDER_ADDITION],
          'MINUS': [' - ', triggerSQLGenerator.ORDER_SUBTRACTION],
          'MULTIPLY': [' * ', triggerSQLGenerator.ORDER_MULTIPLICATION],
          'DIVIDE': [' / ', triggerSQLGenerator.ORDER_DIVISION],
          'POWER': [null, triggerSQLGenerator.ORDER_NONE],  // Handle power separately.
        };
        const tuple = OPERATORS[block.getFieldValue('OP')];
        const operator = tuple[0];
        const order = tuple[1];
        const argument0 = triggerSQLGenerator.valueToCode(block, 'A', order) || '0';
        const argument1 = triggerSQLGenerator.valueToCode(block, 'B', order) || '0';
        let code;
        // Power in JavaScript requires a special case since it has no operator.
        if (!operator) {
          code = 'power(' + argument0 + ', ' + argument1 + ')';
          return [code, triggerSQLGenerator.ORDER_FUNCTION_CALL];
        }
        code = argument0 + operator + argument1;
        return [code, order];
      };

      triggerSQLGenerator['forBlock']['text'] = function (block) {
        const code = block.getFieldValue('TEXT');
        const order = triggerSQLGenerator.ORDER_ATOMIC;
        return ['"' + code + '"', order];
      };

      triggerSQLGenerator['forBlock']['logic_compare_sql'] = function (block) {
        const value_left = triggerSQLGenerator.valueToCode(block, 'left', triggerSQLGenerator.ORDER_ATOMIC);
        const dropdown_operator = block.getFieldValue('operator');
        const value_right = triggerSQLGenerator.valueToCode(block, 'right', triggerSQLGenerator.ORDER_ATOMIC);
        const code = value_left + ' ' + dropdown_operator + ' ' + value_right;
        return [code, triggerSQLGenerator.ORDER_NONE];
      };

      triggerSQLGenerator['forBlock']['logic_operation'] = function (block) {
        const value_left = triggerSQLGenerator.valueToCode(block, 'left', triggerSQLGenerator.ORDER_ATOMIC);
        const dropdown_operator = block.getFieldValue('operator');
        const value_right = triggerSQLGenerator.valueToCode(block, 'right', triggerSQLGenerator.ORDER_ATOMIC);
        const code = value_left + ' ' + dropdown_operator + ' ' + value_right;
        return [code, triggerSQLGenerator.ORDER_NONE];
      };
    },
  },

  mounted() {
    this.deleteBlocks(['query_join', 'query_any_of', 'query_all_of', 'target_query', 'and_query', 'or_query', 'like_query', 'not_like_query', 'orderby_query', 'orderby_limit_combined_query', 'limit_query', 'limit_alt_query', 'logic_compare_sql', 'logic_operation', 'math_number', 'math_arithmetic']);
    this.setupToolbox();
    // Blockly.Blocks['math_number'] = {
    //   init: function() {
    //     this.setOutput(true, 'Number');
    //     this.setColour(160);
    //     this.setTooltip('Returns a numeric value');
    //     this.appendDummyInput()
    //     // .appendField("number:")
    //     // .appendField(new Blockly.FieldNumber(100, 0, 100, 10), 'FIELDNAME');
    //     .appendField("")
    //     .appendField(new Blockly.FieldNumber(0), 'FIELDNAME');
    //   }
    // }
    Blockly.defineBlocksWithJsonArray([
      {
        type:"math_number",
        message0:"%1",
        args0:[
          {
            type:"field_number",
            name:"NUM",
            value:0
          }
        ],
        output:"Number",
        // style:"math_blocks",
        colour: 160,
      },{
        type:"math_arithmetic",
        message0:"%1 %2 %3",
        args0:[
          {
            type:"input_value",
            name:"A",
            check:"Number"
          },{
            type:"field_dropdown",
            name:"OP",
            options:[
              ["%{BKY_MATH_ADDITION_SYMBOL}","ADD"],
              ["%{BKY_MATH_SUBTRACTION_SYMBOL}","MINUS"],
              ["%{BKY_MATH_MULTIPLICATION_SYMBOL}","MULTIPLY"],
              ["%{BKY_MATH_DIVISION_SYMBOL}","DIVIDE"],
              ["%{BKY_MATH_POWER_SYMBOL}","POWER"]
            ]
          },{
            type:"input_value",
            name:"B",
            check:"Number"
          }
        ],
        inputsInline:!0,
        output:"Number",
        // style:"math_blocks",
        colour: '#1176AE',
        helpUrl:"%{BKY_MATH_ARITHMETIC_HELPURL}",
        extensions:["math_op_tooltip"]
      }
    ]);
    this.workspace = Blockly.inject(this.id, {
      toolbox: this.toolbox, move: {
        scrollbars: {
          horizontal: false,
          vertical: true
        },
        drag: true,
        wheel: false
      }
    });

    let timeout;
    this.workspace.addChangeListener(() => {
      const code = triggerSQLGenerator.workspaceToCode(this.workspace);
      const state = this.serializer.save(this.workspace);

      if (timeout) return;
      timeout = setTimeout(() => {
        this.$emit('valid', { code, isValid: !!code, state })
        timeout = null;
      }, 500);
    });

    if (this.state) {
      this.serializer.load(this.state, this.workspace);
    }
    try{
      Blockly.Extensions.register('custom_data', function() {
      // Generate the dynamic custom data
      var customData = {
        superGlobal: true,
      };

      // Store the custom data in the block
      Object.assign(this, customData);
    });
    }
    catch(error){
    }
    
    setTimeout(() => {
      const code = triggerSQLGenerator.workspaceToCode(this.workspace);
      const state = this.serializer.save(this.workspace);
      this.$emit('valid', { code, isValid: !!code, state })
    }, 500);
  }
});
