











import * as _ from 'lodash';
import EslintBuild from 'eslint4b-prebuilt';
import Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import Vue from 'vue';
import { parse } from 'src/utils/util';

import './mutator';

// by default, blockly VARIABLE category will add a "change by" block if this block def exists
delete Blockly.Blocks['math_change']

const strRegExp = /^\s*'([^']|\\')*'\s*$/;

const forceString = function (value) {
  if (strRegExp.test(value)) {
    return [value, javascriptGenerator.ORDER_ATOMIC];
  }
  return ['String(' + value + ')', javascriptGenerator.ORDER_FUNCTION_CALL];
};

export default Vue.extend({
  name: 'BlocklyEditor',
  props: {
    validate: {
      type: Number,
      default: null
    },
    scriptedArFunctions: {
      type: Array,
      default: () => []
    },
    scriptedArVars: {
      type: Array,
      default: () => []
    },
    scriptedSovArVars: {
      type: Array,
      default: () => []
    },
    globalVars: {
      type: Array,
      default: () => []
    },
    state: {
      type: Object,
      default: () => null
    },
    type: {
      type: String,
      default: () => ''
    },
    superGlobals: {
      type: Array
    },
    lookback:{
      type: Number
    }
  },
  data() {
    return {
      eslintConst: null,
      eslintRules: null,
      id: 'blocklyEditor-' + Date.now(),
      serializer: new Blockly.serialization.blocks.BlockSerializer(),
      workspace: null as Blockly.WorkspaceSvg,
      declaredVars: {},
      toolbox: {
        kind: 'categoryToolbox',
        contents: [
          {
            kind: 'category',
            name: 'Logic',
            contents: [
              {
                kind: 'block',
                type: 'controls_if',
                inputs: {
                  IF0: {
                      block: {
                      type: 'logic_compare_any'
                    }
                  }
                }
              },
              {
                kind: 'block',
                type: 'logic_compare_any'
              },
              // {
              //   kind: 'block',
              //   type: 'logic_equals'
              // },
              {
                kind: 'block',
                type: 'logic_operation'
              },
              {
                kind: 'block',
                type: 'logic_negate'
              },
              {
                kind: 'block',
                type: 'math_arithmetic'
              }
            ],
            "cssConfig": {
              "icon": "blocklyTreeIcon"
            }
          },
          {
            kind: 'category',
            name: 'Values',
            contents: [
              {
                kind: 'block',
                type: 'math_number'
              },
              {
                kind: 'block',
                type: 'text'
              },
              {
                kind: 'block',
                type: 'logic_boolean'
              },
              {
                kind: 'block',
                type: 'format_as'
              },
              {
                kind: 'block',
                type: 'lists_join_many',
                extraState: { itemCount: 2, customName: 'Create List With' }
              },
              {
                kind: 'block',
                type: 'text_join_many',
                extraState: { itemCount: 2, customName: 'Create Text With' }
              },
            ],
            "cssConfig": {
              "icon": "blocklyTreeIconvalue"
            }
          }
        ]
      },
      campNumericVars:['dailyBudget', 'budget', 'cpm', 'spend', 'tapAvgCPA', 'totalAvgCPA', 'cpt', 'impressions', 'taps', 'tapInstalls', 'viewInstalls', 'totalInstalls', 'ttr', "goalEventCount", "goalRevenue", "goalRevenuePer", "goalCostPer", "goalROAS", "goalARPU", "goalAMPU"],
      adgNumericVars:[ 'cpm', 'spend', 'tapAvgCPA', 'totalAvgCPA', 'cpt', 'impressions', 'taps', 'tapInstalls', 'viewInstalls', 'totalInstalls', 'ttr', "goalEventCount", "goalRevenue", "goalRevenuePer", "goalCostPer", "goalROAS", "goalARPU", "goalAMPU"],
      keyNumericVars:['bidAmount', 'cpm', 'spend', 'tapAvgCPA', 'totalAvgCPA', 'cpt', 'impressions', 'taps', 'tapInstalls', 'viewInstalls', 'totalInstalls', 'ttr', "goalEventCount", "goalRevenue", "goalRevenuePer", "goalCostPer", "goalROAS", "goalARPU", "goalAMPU"]
      
    }
  },

  watch: {
    validate: function(value) {
      if (value) {
        this.lintAndEmit();
      }
    },
    state: function (state) {
      if (state && this.workspace) {
        if(Array.isArray(state.vars)) {
          state.vars.forEach(wsVar => {
            this.workspace.deleteVariableById(wsVar.id);
            this.workspace.createVariable(wsVar.name, undefined, wsVar.id)
          });
        }
        
        this.serializer.load(state.blocks, this.workspace);
      }
    },
    // globalVars: function (vars) {
    //   this.populateVars(vars);
    // },
    globalVars: {
      deep: true,
      handler (value, oldValue) {
        if (JSON.stringify(value.sort()) != JSON.stringify(oldValue.sort())) {
          const blocks = this.workspace.getAllBlocks()
          const superGlobalBlocks = blocks.filter(e=> e.superGlobal)
          const superGlobalBlocksToDelete = superGlobalBlocks.filter(e=> !value.includes(e.type))
          superGlobalBlocksToDelete.forEach(el=>{
            var block = this.workspace.getBlockById(el.id);
            if (block) {
              block.dispose();
            }
          })
        }
        this.populateVars(value);
      }  
    },
    lookback:{
      handler(value, oldValue){
        if(value!== oldValue){
          const asafunc = this.toolbox.contents.find(e=>e.name==='ASA Functions')
          if (asafunc && asafunc.contents && asafunc.contents.length){
            const getHistoricalBlock = asafunc.contents.find(e=>e.type==="GetHistoricValue")
            if(getHistoricalBlock){
              getHistoricalBlock.inputs.lookbackDayEnd.block.fields.NUM = value
            }
          }
        }
      }
    }
  },

  methods: {
    deleteBlocks(blockTypes) {
        blockTypes.forEach(function(blockType) {
            delete Blockly.Blocks[blockType];
        });
    },
    lintAndEmit() {
      let globals = {};
      
      const code = javascriptGenerator.workspaceToCode(this.workspace);
      const state = this.serializer.save(this.workspace);

      Object.assign(globals, this.scriptedSovArVars.reduce((a, v) => ({ ...a, [v]: 'readonly' }), {}));
      Object.assign(globals, this.globalVars.reduce((a, v) => ({ ...a, [v]: 'writable' }), {}));
      Object.assign(globals, this.scriptedArVars.reduce((a, v) => ({ ...a, [v]: 'readonly' }), {}));
      Object.assign(globals, this.scriptedArFunctions.map(fn => fn.split('(')[0]).reduce((a, v) => ({ ...a, [v]: 'readonly' }), {}));
      const config = {
        parserOptions: { ecmaVersion: 2019, sourceType: "module" },
        env: {},
        rules: this.eslintRules,
        globals
      };

      const output = this.eslintConst.verify(code, config);

      this.declaredVars = new Set();
      const nodes = parse(code)
      nodes.forEach((node, index) => {
        if (node.name === 'VariableDefinition') {
          this.declaredVars.add(code.slice(node.from, node.to))
        }
      })
      
      if (!code) output.push({ message: 'Expression expected' });

      this.$emit('valid', { code, isValid: !!code && !output.length, diagnostics: output, state: { blocks: state, vars: this.workspace.getAllVariables().map(wsVar => { return { name: wsVar.name, id: wsVar.id_ } }) }, declaredVars: Array.from(this.declaredVars) });
    },
    formatAsHelper(value, formatAs) {
      switch(formatAs) {
        case 'cost_usd': {
          return '$' + Number(String(value).replace(/[^0-9.]/gi, '') || 0).toFixed(2)
        }
      }
    },
    
    populateVars(vars) {
      const contents = new Set();
      vars.filter((str) => str !== '').forEach(varName => {
        delete Blockly.Blocks[varName]
        Blockly.defineBlocksWithJsonArray([{
          type: varName,
          message0: varName,
          output: null,
          extensions: ['custom_data']
        }]);

        javascriptGenerator['forBlock'][varName] = block => {
          const code = varName;
          return [code, javascriptGenerator.ORDER_ATOMIC];
        };

        contents.add({ kind: 'block', type: varName });
      })
      if(contents.size){
          contents.add({
          kind: 'block',
          type: 'custom_variables_set'
        })
        delete Blockly.Blocks['custom_variables_set']
        Blockly.defineBlocksWithJsonArray([
        {
          type: "custom_variables_set",
          message0: 'set %1 to %2',
          args0: [
              {
                type: 'field_dropdown',
                name: 'variableName',
                options: this.globalVars.map(e=>[e,e])
              },
              {
                type: "input_value",    // This expects an input of any type
                name: "value"
              }
            ]}
          ])
      }
      
      const index = this.toolbox.contents.findIndex(c => c.name === 'Global Variables');

      if (contents.size) {
        if (index === -1) {
          this.toolbox.contents.push({
            kind: 'category',
            name: 'Global Variables',
            contents: Array.from(contents)
          })
        } else {
          this.toolbox.contents[index].contents = Array.from(contents);
        }
      } else {
        if (index !== -1) this.toolbox.contents.splice(index, 1)
      }


      this.workspace.updateToolbox(this.toolbox);
    },
    setupCommon() {
      Blockly.defineBlocksWithJsonArray([
        {
          type: 'format_as',
          message0: 'Format %1 as %2',
          args0: [
            {
              type: 'input_value',
              name: 'value',
              check: ['Number', 'String', 'Boolean'],
            },
            {
              type: 'field_dropdown',
              name: 'format',
              options: [['Cost - USD', 'cost_usd']]
            }
          ],
          inputsInline: true,
          output: 'String',
          style: 'text_blocks'
        },
        {
          type: 'logic_compare_any',
          message0: '%1 %2 %3',
          args0: [
            {
              type: 'input_value',
              name: 'left',
              check: ['Number', 'String', 'Boolean'],
            },
            {
              type: 'field_dropdown',
              name: 'operator',
              options: [['<', '<'], ['>', '>'], ['=', '=='], ['≠', '!='], ['>=', '>='], ['<=', '<=']]
            },
            {
              type: 'input_value',
              name: 'right',
              check: ['Number', 'String', 'Boolean']
            }
          ],
          inputsInline: true,
          output: 'Boolean',
          colour: '#1176AE'
        },
        {
          type: 'logic_equals',
          message0: '%1 = %2',
          args0: [
            {
              type: 'input_value',
              name: 'left',
            },
            {
              type: 'input_value',
              name: 'right'
            }
          ],
          previousStatement: null,
          nextStatement: null,
          inputsInline: true,
          colour: '#1176AE'
        },
        {
          'type': 'lists_join_many',
          'message0': '',
          'output': null,
          'style': 'text_blocks',
          'helpUrl': '%{BKY_TEXT_JOIN_HELPURL}',
          'tooltip': '%{BKY_TEXT_JOIN_TOOLTIP}',
          'mutator': 'join_mutator',
        },
        {
          'type': 'text_join_many',
          'message0': '',
          'output': null,
          'style': 'text_blocks',
          'helpUrl': '%{BKY_TEXT_JOIN_HELPURL}',
          'tooltip': '%{BKY_TEXT_JOIN_TOOLTIP}',
          'mutator': 'join_mutator',
        }
      ])

      // Custom logic blocks
      
      javascriptGenerator['forBlock']['custom_variables_set'] = function (block) {
        const variableName = block.getFieldValue('variableName');
        const value = javascriptGenerator.valueToCode(block, 'value', javascriptGenerator.ORDER_ATOMIC);
        const code = variableName+' = '+value + '\n'
        return code;
      };

      javascriptGenerator['forBlock']['format_as'] = (block) => {
        const value = javascriptGenerator.valueToCode(block, 'value', javascriptGenerator.ORDER_ATOMIC);
        const format_value = block.getFieldValue('format');
        const code = '"' + this.formatAsHelper(value, format_value) + '"';
        return [code, javascriptGenerator.ORDER_NONE];
      };

      
      javascriptGenerator['forBlock']['logic_compare_any'] = function (block) {
        const value_left = javascriptGenerator.valueToCode(block, 'left', javascriptGenerator.ORDER_ATOMIC);
        const dropdown_operator = block.getFieldValue('operator');
        const value_right = javascriptGenerator.valueToCode(block, 'right', javascriptGenerator.ORDER_ATOMIC);
        const code = value_left + ' ' + dropdown_operator + ' ' + value_right;
        return [code, javascriptGenerator.ORDER_NONE];
      };

      javascriptGenerator['forBlock']['logic_equals'] = function (block) {
        const value_left = javascriptGenerator.valueToCode(block, 'left', javascriptGenerator.ORDER_ATOMIC);
        const value_right = javascriptGenerator.valueToCode(block, 'right', javascriptGenerator.ORDER_ATOMIC);
        const code = value_left + ' = ' + value_right + '\n';
        return code;
      };
      
      javascriptGenerator['forBlock']['logic_operation'] = function (block) {
        const value_left = javascriptGenerator.valueToCode(block, 'left', javascriptGenerator.ORDER_ATOMIC);
        const dropdown_operator = block.getFieldValue('operator')=='and'?'&&':'||';
        const value_right = javascriptGenerator.valueToCode(block, 'right', javascriptGenerator.ORDER_ATOMIC);
        const code = value_left + ' ' + dropdown_operator + ' ' + value_right;
        return [code, javascriptGenerator.ORDER_NONE];
      };

      javascriptGenerator['forBlock']['text_join_many'] = function (block) {
        // Create a string made up of any number of elements of any type.
        switch (block.itemCount_) {
          case 0:
            return ["''", javascriptGenerator.ORDER_ATOMIC];
          case 1: {
            const element = javascriptGenerator.valueToCode(block, 'ADD0',
              javascriptGenerator.ORDER_NONE) || "''";
            const codeAndOrder = forceString(element);
            return codeAndOrder;
          }
          case 2: {
            const element0 = javascriptGenerator.valueToCode(block, 'ADD0',
              javascriptGenerator.ORDER_NONE) || "''";
            const element1 = javascriptGenerator.valueToCode(block, 'ADD1',
              javascriptGenerator.ORDER_NONE) || "''";
            const code = forceString(element0)[0] +
              ' + ' + forceString(element1)[0];
            return [code, javascriptGenerator.ORDER_ADDITION];
          }
          default: {
            const elements = new Array(block.itemCount_);
            for (let i = 0; i < block.itemCount_; i++) {
              elements[i] = javascriptGenerator.valueToCode(block, 'ADD' + i,
                javascriptGenerator.ORDER_NONE) || "''";
            }
            const code = '[' + elements.join(',') + '].join(\'\')';
            return [code, javascriptGenerator.ORDER_FUNCTION_CALL];
          }
        }
      };

      javascriptGenerator['forBlock']['lists_join_many'] = function (block) {
        // Create a list with any number of elements of any type.
        const elements = new Array(block.itemCount_);
        for (let i = 0; i < block.itemCount_; i++) {
          elements[i] =
            javascriptGenerator.valueToCode(block, 'ADD' + i, javascriptGenerator.ORDER_NONE) ||
            'null';
        }
        const code = '[' + elements.join(', ') + ']';
        return [code, javascriptGenerator.ORDER_ATOMIC];
      };
      
    },
    setupOnetimeToolbox() {
      const noArgs = ['StopRule', 'DisableRule', 'StopAction'];
      const asaFunctionsToolbox = {
        kind: 'category',
        name: 'ASA Functions',
        contents: [],
        "cssConfig": {
          "icon": "blocklyTreeIconfunctions"
        }
      };

      this.scriptedArFunctions.forEach(sarf => {
        const displayName = sarf.split('(')[0];
        if(['Log','SendEmail','SendEmailMultiple'].includes(displayName)) return
        const uniqName = displayName + '_onetime';
        const noArg = !noArgs.find(na => na === displayName);
        delete Blockly.Blocks[uniqName]
        Blockly.defineBlocksWithJsonArray([{
          type: uniqName,
          message0: noArg ? displayName + ' %1' : displayName,
          args0: noArg ? [
            {
              type: 'input_value',
              name: 'id',
              check: ['Number']
            }
          ] : [],
          previousStatement: null,
          nextStatement: null,
          colour:  '#CA156C',
          
        }]);

        javascriptGenerator['forBlock'][uniqName] = block => {
          const value_id = javascriptGenerator.valueToCode(block, 'id', javascriptGenerator.ORDER_ATOMIC);
          const code = displayName + '(' + value_id + ')' + '\n'
          return code;
        };

        asaFunctionsToolbox.contents.push({
          kind: 'block',
          type: uniqName
        })

      });

      asaFunctionsToolbox.contents.push({
        kind: 'block',
        type: 'Log_onetime',
        inputs: {
          message: {
            block: {
              type: 'text',
              fields: { TEXT: 'message' }
            }
          }
        }
      },{
        kind: 'block',
        type: 'SendEmail_onetime',
        inputs: {
          email: {
            block: {
              type: 'text',
              fields: { TEXT: 'user@example.com' }
            }
          },
          subject: {
            block: {
              type: 'text',
              fields: { TEXT: 'Subject' }
            }
          },
          body: {
            block: {
              type: 'text',
              fields: { TEXT: 'Body' }
            }
          }
        }
      },{
        kind: 'block',
        type: 'SendEmailMultiple_onetime',
        inputs: {
          email: {
            block: {
              type: 'lists_join_many',
              tooltip: 'Add list of email Ids',
              inputs: {
                ADD0: {
                  block: {
                    type: 'text',
                    fields: { TEXT: 'user1@example.com' }
                  }
                },
                ADD1: {
                  block: {
                    type: 'text',
                    fields: { TEXT: 'user2@example.com' }
                  }
                }
              }
            }
          },
          subject: {
            block: {
              type: 'text',
              fields: { TEXT: 'Subject' }
            }
          },
          body: {
            block: {
              type: 'text',
              fields: { TEXT: 'Body' }
            }
          }
        }
      },{
        kind: 'block',
        type: 'SendReport_onetime',
        inputs: {
          email: {
            block: {
              type: 'text',
              fields: { TEXT: 'user@example.com' }
            }
          },
          subject: {
            block: {
              type: 'text',
              fields: { TEXT: 'Subject' }
            }
          },
          body: {
            block: {
              type: 'text',
              fields: { TEXT: 'Body' }
            }
          }
        }
      },{
        kind: 'block',
        type: 'SendReportMultiple_onetime',
        inputs: {
          email: {
            block: {
              type: 'lists_join_many',
              tooltip: 'Add list of email Ids',
              inputs: {
                ADD0: {
                  block: {
                    type: 'text',
                    fields: { TEXT: 'user1@example.com' }
                  }
                },
                ADD1: {
                  block: {
                    type: 'text',
                    fields: { TEXT: 'user2@example.com' }
                  }
                }
              }
            }
          },
          subject: {
            block: {
              type: 'text',
              fields: { TEXT: 'Subject' }
            }
          },
          body: {
            block: {
              type: 'text',
              fields: { TEXT: 'Body' }
            }
          },
          // report: {
          //   block: {
          //     type: 'text',
          //     fields: { TEXT: 'Body' }
          //   }
          // }
        }
      })
      this.deleteBlocks(['Log_onetime','SetCampaignBudget_onetime','SetKeywordBidAmount_onetime','WriteToStorage_onetime','ReadFromStorage_onetime','SendEmail_onetime','SendEmailMultiple_onetime','CreateReport_onetime','SendReport_onetime','SendReportMultiple_onetime'])
      Blockly.defineBlocksWithJsonArray([
        {
          type: 'Log_onetime',
          message0: 'Log %1',
          tooltip: 'Log a string / number / boolean from values',
          previousStatement: null,
          nextStatement: null,
          args0: [
            {
              type: 'input_value',
              name: 'message',
              check: ['Number', 'String', 'Boolean']
            }
          ],
          colour: '#CA156C',
          inputsInline: false,
          // output: 'String'
        },
        {
          type: 'SetCampaignBudget_onetime',
          message0: 'SetCampaignBudget %1 %2',
          previousStatement: null,
          nextStatement: null,
          args0: [
            {
              type: 'input_value',
              name: 'id',
              check: ['Number']
            },
            {
              type: 'input_value',
              name: 'budget',
              check: ['String', 'Number']
            }
          ],
          inputsInline: true,
          colour:  '#CA156C',
          
        },
        {
          type: 'SetKeywordBidAmount_onetime',
          message0: 'SetKeywordBidAmount %1 %2',
          previousStatement: null,
          nextStatement: null,
          args0: [
            {
              type: 'input_value',
              name: 'id',
              check: ['Number']
            },
            {
              type: 'input_value',
              name: 'bid',
              check: ['String', 'Number']
            }
          ],
          inputsInline: true,
          colour:  '#CA156C',
          
        },
        {
          type: 'WriteToStorage_onetime',
          message0: 'WriteToStorage %1 %2',
          previousStatement: null,
          nextStatement: null,
          args0: [
            {
              type: 'input_value',
              name: 'key',
              check: ['Number', 'String']
            },
            {
              type: 'input_value',
              name: 'value',
              check: ['String', 'Number']
            }
          ],
          inputsInline: true,
          colour:  '#CA156C',
          
        },
        {
          type: 'ReadFromStorage_onetime',
          message0: 'ReadFromStorage %1 %2',
          previousStatement: null,
          nextStatement: null,
          args0: [
            {
              type: 'input_value',
              name: 'key',
              check: ['Number', 'String']
            },
            {
              type: 'input_value',
              name: 'default_value',
              check: ['String', 'Number', 'Boolean']
            }
          ],
          inputsInline: true,
          colour:  '#CA156C',
          
        },
        {
          type: 'SendEmail_onetime',
          message0: 'Send Email %1 %2 %3',
          tooltip: 'Sends an email. Takes 3 string inputs - EmailId, Subject, Body',
          previousStatement: null,
          nextStatement: null,
          args0: [
            {
              type: 'input_value',
              name: 'email',
              check: ['String']
            },
            {
              type: 'input_value',
              name: 'subject',
              check: ['String']
            },
            {
              type: 'input_value',
              name: 'body',
              check: ['String']
            },
          ],
          inputsInline: false,
          colour:  '#CA156C',
          
        },
        {
          type: 'SendEmailMultiple_onetime',
          message0: 'Send Email Multiple %1 %2 %3',
          tooltip: 'Sends an email to multiple Ids. Takes 3 inputs - List of EmailIds, Subject, Body',
          previousStatement: null,
          nextStatement: null,
          args0: [
            {
              type: 'input_value',
              name: 'email'
            },
            {
              type: 'input_value',
              name: 'subject',
              check: ['String']
            },
            {
              type: 'input_value',
              name: 'body',
              check: ['String']
            },
          ],
          inputsInline: false,
          colour:  '#CA156C',
          
        },
        {
          type: 'CreateReport_onetime',
          message0: 'CreateReport %1 %2',
          args0: [
            {
              type: 'input_value',
              name: 'name',
              check: ['String']
            },
            {
              type: 'input_value',
              name: 'headers'
            }
          ],
          inputsInline: false,
          output: null,
          colour:  '#CA156C',
          
        },
        {
          type: 'SendReport_onetime',
          message0: 'Send Report %1 %2 %3 %4',
          tooltip: 'Sends an email report. Takes 4 inputs - EmailId, Subject, Body, report var',
          previousStatement: null,
          nextStatement: null,
          args0: [
            {
              type: 'input_value',
              name: 'email',
              check: ['String']
            },
            {
              type: 'input_value',
              name: 'subject',
              check: ['String']
            },
            {
              type: 'input_value',
              name: 'body',
              check: ['String']
            },
            {
              type: 'input_value',
              name: 'report'
            }
          ],
          inputsInline: false,
          colour:  '#CA156C',
          
        },
        {
          type: 'SendReportMultiple_onetime',
          message0: 'Send Report Multiple %1 %2 %3 %4',
          tooltip: 'Sends an email report to multiple Ids. Takes 4 inputs - List of EmailIds, Subject, Body, report var',
          previousStatement: null,
          nextStatement: null,
          args0: [
            {
              type: 'input_value',
              name: 'email'
            },
            {
              type: 'input_value',
              name: 'subject',
              check: ['String']
            },
            {
              type: 'input_value',
              name: 'body',
              check: ['String']
            },
            {
              type: 'input_value',
              name: 'report'
            }
          ],
          inputsInline: false,
          colour:  '#CA156C',
          
        },
      ])

      javascriptGenerator['forBlock']['Log_onetime'] = function (block) {
        const value_message = javascriptGenerator.valueToCode(block, 'message', javascriptGenerator.ORDER_ATOMIC);
        const code = 'Log(' + value_message + ')' + '\n'
        return code;
      };

      javascriptGenerator['forBlock']['SetCampaignBudget_onetime'] = function (block) {
        const value_id = javascriptGenerator.valueToCode(block, 'id', javascriptGenerator.ORDER_ATOMIC);
        const value_budget = javascriptGenerator.valueToCode(block, 'budget', javascriptGenerator.ORDER_ATOMIC);
        const code = 'SetCampaignBudget(' + value_id + ', ' + value_budget + ')' + '\n'
        return code;
      };

      javascriptGenerator['forBlock']['SetKeywordBidAmount_onetime'] = function (block) {
        const value_id = javascriptGenerator.valueToCode(block, 'id', javascriptGenerator.ORDER_ATOMIC);
        const value_bid = javascriptGenerator.valueToCode(block, 'bid', javascriptGenerator.ORDER_ATOMIC);
        const code = 'SetKeywordBidAmount(' + value_id + ', ' + value_bid + ')' + '\n'
        return code;
      };

      javascriptGenerator['forBlock']['WriteToStorage_onetime'] = function (block) {
        const value_key = javascriptGenerator.valueToCode(block, 'key', javascriptGenerator.ORDER_ATOMIC);
        const value = javascriptGenerator.valueToCode(block, 'value', javascriptGenerator.ORDER_ATOMIC);
        const code = 'WriteToStorage(' + value_key + ', ' + value + ')' + '\n'
        return code;
      };

      javascriptGenerator['forBlock']['ReadFromStorage_onetime'] = function (block) {
        const value_key = javascriptGenerator.valueToCode(block, 'key', javascriptGenerator.ORDER_ATOMIC);
        const value = javascriptGenerator.valueToCode(block, 'default_value', javascriptGenerator.ORDER_ATOMIC);
        const code = 'ReadFromStorage(' + value_key + ', ' + value + ')' + '\n'
        return code;
      };

      javascriptGenerator['forBlock']['SendEmail_onetime'] = function (block) {
        const value_email = javascriptGenerator.valueToCode(block, 'email', javascriptGenerator.ORDER_ATOMIC);
        const value_subject = javascriptGenerator.valueToCode(block, 'subject', javascriptGenerator.ORDER_ATOMIC);
        const value_body = javascriptGenerator.valueToCode(block, 'body', javascriptGenerator.ORDER_ATOMIC);
        // const value_report = javascriptGenerator.valueToCode(block, 'report', javascriptGenerator.ORDER_ATOMIC);
        // const code = 'SendEmail(' + value_email + ', ' + value_subject + ', ' + value_body + ',' + (value_report || null) + ')' + '\n'
        const code = 'SendEmail(' + value_email + ', ' + value_subject + ', ' + value_body + ')' + '\n'
        return code;
      };

      javascriptGenerator['forBlock']['SendEmailMultiple_onetime'] = function (block) {
        const value_email = javascriptGenerator.valueToCode(block, 'email', javascriptGenerator.ORDER_ATOMIC);
        const value_subject = javascriptGenerator.valueToCode(block, 'subject', javascriptGenerator.ORDER_ATOMIC);
        const value_body = javascriptGenerator.valueToCode(block, 'body', javascriptGenerator.ORDER_ATOMIC);
        // const value_report = javascriptGenerator.valueToCode(block, 'report', javascriptGenerator.ORDER_ATOMIC);
        const code = 'SendEmailMultiple(' + value_email + ', ' + value_subject + ', ' + value_body + ')' + '\n'
        return code;
      };

      javascriptGenerator['forBlock']['CreateReport_onetime'] = function (block) {
        const value_name = javascriptGenerator.valueToCode(block, 'name', javascriptGenerator.ORDER_ATOMIC);
        const value_headers = javascriptGenerator.valueToCode(block, 'headers', javascriptGenerator.ORDER_ATOMIC);
        const code = 'CreateReport(' + value_name + ', ' + value_headers + ')';
        return [code, javascriptGenerator.ORDER_NONE];
      };

      javascriptGenerator['forBlock']['SendReport_onetime'] = (block) => {
        const value_email = javascriptGenerator.valueToCode(block, 'email', javascriptGenerator.ORDER_ATOMIC);
        const value_subject = javascriptGenerator.valueToCode(block, 'subject', javascriptGenerator.ORDER_ATOMIC);
        const value_body = javascriptGenerator.valueToCode(block, 'body', javascriptGenerator.ORDER_ATOMIC);
        let value_report = javascriptGenerator.valueToCode(block, 'report', javascriptGenerator.ORDER_ATOMIC);  
        const var_report = value_report ? '['+value_report+']' : 'null'
        const code = 'SendEmail(' + value_email + ', ' + value_subject + ', ' + value_body + ', ' + var_report + ')' + '\n'
        return code;
      };

      javascriptGenerator['forBlock']['SendReportMultiple_onetime'] = (block) => {
        const value_email = javascriptGenerator.valueToCode(block, 'email', javascriptGenerator.ORDER_ATOMIC);
        const value_subject = javascriptGenerator.valueToCode(block, 'subject', javascriptGenerator.ORDER_ATOMIC);
        const value_body = javascriptGenerator.valueToCode(block, 'body', javascriptGenerator.ORDER_ATOMIC);
        const value_report = javascriptGenerator.valueToCode(block, 'report', javascriptGenerator.ORDER_ATOMIC);
        const var_report = value_report ? '['+value_report+']' : 'null'
        const code = 'SendEmailMultiple(' + value_email + ', ' + value_subject + ', ' + value_body + ', ' + var_report + ')' + '\n'
        return code;
      };
      this.toolbox.contents.push(asaFunctionsToolbox);
    },

    setupActionsToolbox() {
      const stringTypes = ['name', 'state', 'dateCreated', 'Date'];
      const asaVarsToolBox = {
        kind: 'category',
        name: 'ASA Variables',
        contents: [],
        "cssConfig": {
          "icon": "blocklyTreeIconvariables"
        }
      };
      const asaFunctionsToolbox = {
        kind: 'category',
        name: 'ASA Functions',
        contents: [],
        "cssConfig": {
          "icon": "blocklyTreeIconfunctions"
        }
      };

      const sovVarsToolBox = {
        kind: 'category',
        name: 'Share of Voice',
        contents: [],
        "cssConfig": {
          "icon": "blocklyTreeIconvariables"
        }
      };

      this.scriptedArVars.forEach(sarv => {
        delete Blockly.Blocks[sarv]
        Blockly.defineBlocksWithJsonArray([{
          type: sarv,
          message0: sarv,
          output: stringTypes.find(st => st === sarv) ? 'String' : 'Number',
          colour: '#D48033',
        }]);


        javascriptGenerator['forBlock'][sarv] = block => {
          const code = sarv
          return [code, javascriptGenerator.ORDER_ATOMIC];
        };

        asaVarsToolBox.contents.push({
          kind: 'block',
          type: sarv
        });

       

      });
      
       //sov report vars
       this.scriptedSovArVars.forEach(sarv => {
        delete Blockly.Blocks[sarv]
        Blockly.defineBlocksWithJsonArray([{
          type: sarv,
          message0: sarv,
          output: stringTypes.find(st => st === sarv) ? 'String' : 'Number',
          colour: '#FF8033',
        }]);


        javascriptGenerator['forBlock'][sarv] = block => {
          const code = sarv
          return [code, javascriptGenerator.ORDER_ATOMIC];
        };

        sovVarsToolBox.contents.push({
          kind: 'block',
          type: sarv
        });
              

      });


      [...this.scriptedArFunctions, 'AddRow([])'].forEach(sarf => {        
        const name = sarf.split('(')[0]
        if(name === 'Log' || name === 'GetHistoricValue') return
        delete Blockly.Blocks[name]
        Blockly.defineBlocksWithJsonArray([{
          type: name,
          message0: name,
          previousStatement: null,
          nextStatement: null,
          colour:  '#CA156C',
          
       
        }]);

        javascriptGenerator['forBlock'][name] = block => {
          const code = sarf + '\n'
          return code;
        };

        asaFunctionsToolbox.contents.push({
          kind: 'block',
          type: name
        })
      });

      asaFunctionsToolbox.contents.push({
        kind: 'block',
        type: 'Log',
        inputs: {
          message: {
            block: {
              type: 'text',
              fields: { TEXT: 'message' }
            }
          }
        }
      },
      {
        kind: 'block',
        type: 'GetHistoricValue',
        inputs: {
          lookbackDayStart: {
            block: {
              type: 'math_number',
              fields: { NUM: 1}
            }
          },
          lookbackDayEnd: {
            block: {
              type: 'math_number',
              fields: { NUM: this.lookback}
            }
          }
        }
      })
      this.deleteBlocks(['GetHistoricValue','Log','SetDefaultMaxCPTBid','SetCampaignDailyCap','ExistsInStorefront','SetCampaignBudget','CopyKeyword','SetKeywordBidAmount','AddRow'])
      Blockly.defineBlocksWithJsonArray([
      {
          type: 'GetHistoricValue',
          message0: 'Get Historic Aggregates %1 Lookback Day Start %2  Lookback Day End %3',
          /* previousStatement: null,
          nextStatement: null, */
          args0: [
            {
              type: 'field_dropdown',
              name: 'valueName',
              options: this.type==='campaign_blockly'?this.campNumericVars.map(e=>[e,e]):(this.type==='adgroup_blockly'?this.adgNumericVars.map(e=>[e,e]):(this.keyNumericVars.map(e=>[e,e])))
            },
            {
              type: 'input_value',
              name: 'lookbackDayStart',
              check: ['Number'],
            },
            {
              type: 'input_value',
              name: 'lookbackDayEnd',
              check: ['Number'],
            },
            
          ],
          colour: '#CA156C',
          inputsInline: true,
          output: null,
                  },
        {
          type: 'Log',
          message0: 'Log %1',
          tooltip: 'Log a string / number / boolean from values',
          previousStatement: null,
          nextStatement: null,
          args0: [
            {
              type: 'input_value',
              name: 'message',
              check: ['Number', 'String', 'Boolean']
            }
          ],
          colour: '#CA156C',
          inputsInline: false,
          // output: 'String'
        },
        {
          type: 'SetDefaultMaxCPTBid',
          message0: 'SetDefaultMaxCPTBid %1',
          previousStatement: null,
          nextStatement: null,
          args0: [
            {
              type: 'input_value',
              name: 'bid',
              check: ['Number']
            }
          ],
          colour:  '#CA156C',
          
        },
        {
          type: 'SetCampaignDailyCap',
          message0: 'SetCampaignDailyCap %1',
          previousStatement: null,
          nextStatement: null,
          args0: [
            {
              type: 'input_value',
              name: 'bid',
              check: ['Number']
            }
          ],
          colour:  '#CA156C',
          
        },
        {
          type: 'ExistsInStorefront',
          message0: 'ExistsInStorefront %1',
          /* previousStatement: null,
          nextStatement: null, */
          args0: [
            {
              type: 'input_value',
              name: 'bid',
              check: ['String']
            }
          ],
          colour:  '#CA156C',
          output: null,
        },
        {
          type: 'SetCampaignBudget',
          message0: 'SetCampaignBudget %1',
          previousStatement: null,
          nextStatement: null,
          args0: [
            {
              type: 'input_value',
              name: 'budget',
              check: ['String', 'Number']
            }
          ],
          colour:  '#CA156C',
          
        },
        {
          type: 'CopyKeyword',
          message0: 'CopyKeyword %1 %2',
          previousStatement: null,
          nextStatement: null,
          args0: [
            {
              type: 'input_value',
              name: 'target_id',
              check: ['Number']
            },
            {
              type: 'input_value',
              name: 'delete_original',
              check: ['Boolean']
            }
          ],
          inputsInline: true,
          colour:  '#CA156C',
          
        },
        {
          type: 'SetKeywordBidAmount',
          message0: 'SetKeywordBidAmount %1',
          previousStatement: null,
          nextStatement: null,
          args0: [
            {
              type: 'input_value',
              name: 'bid',
              check: ['String', 'Number']
            }
          ],
          colour:  '#CA156C',
          
        },
        {
          type: 'AddRow',
          message0: 'Add Row to Report %1 %2',
          previousStatement: null,
          nextStatement: null,
          args0: [
            {
              type: 'input_value',
              name: 'report_variable',
            },
            {
              type: 'input_value',
              name: 'variables',
            }
          ],
          colour:  '#CA156C',
          
        },
      ])

      // Custom ASA function that need user input
      javascriptGenerator['forBlock']['Log'] = function (block) {
        const value_message = javascriptGenerator.valueToCode(block, 'message', javascriptGenerator.ORDER_ATOMIC);
        const code = 'Log(' + value_message + ')' + '\n'
        return code;
      };

      javascriptGenerator['forBlock']['GetHistoricValue'] = function (block) {
        const valueName = block.getFieldValue('valueName', javascriptGenerator.ORDER_ATOMIC);
        const lookbackDayStart = javascriptGenerator.valueToCode(block, 'lookbackDayStart', javascriptGenerator.ORDER_ATOMIC);
        const lookbackDayEnd = javascriptGenerator.valueToCode(block, 'lookbackDayEnd', javascriptGenerator.ORDER_ATOMIC);
        const code = 'GetHistoricValue("' + valueName + '",' + lookbackDayStart + ',' + lookbackDayEnd + ')'
        return [code, javascriptGenerator.ORDER_ATOMIC];
      };

      javascriptGenerator['forBlock']['SetCampaignBudget'] = function (block) {
        const value_budget = javascriptGenerator.valueToCode(block, 'budget', javascriptGenerator.ORDER_ATOMIC);
        const code = 'SetCampaignBudget(id, ' + value_budget + ')' + '\n'
        return code;
      };

      javascriptGenerator['forBlock']['CopyKeyword'] = function (block) {
        const value_target = javascriptGenerator.valueToCode(block, 'target_id', javascriptGenerator.ORDER_ATOMIC);
        const value_delete_original = javascriptGenerator.valueToCode(block, 'delete_original', javascriptGenerator.ORDER_ATOMIC);
        const code = 'CopyKeyword(id, ' + value_target + ', ' + value_delete_original + ')' + '\n'
        return code;
      };

      javascriptGenerator['forBlock']['SetKeywordBidAmount'] = function (block) {
        const value_bid = javascriptGenerator.valueToCode(block, 'bid', javascriptGenerator.ORDER_ATOMIC);
        const code = 'SetKeywordBidAmount(id, ' + value_bid + ')' + '\n'
        return code;
      };

      javascriptGenerator['forBlock']['SetDefaultMaxCPTBid'] = function (block) {
        const value_bid = javascriptGenerator.valueToCode(block, 'bid', javascriptGenerator.ORDER_ATOMIC);
        const code = 'SetDefaultMaxCPTBid(id, ' + value_bid + ')' + '\n'
        return code;
      };

      javascriptGenerator['forBlock']['SetCampaignDailyCap'] = function (block) {
        const value_bid = javascriptGenerator.valueToCode(block, 'bid', javascriptGenerator.ORDER_ATOMIC);
        const code = 'SetCampaignDailyCap(id, ' + value_bid + ')' + '\n'
        return code;
      };

      javascriptGenerator['forBlock']['ExistsInStorefront'] = function (block) {
        const value_bid = javascriptGenerator.valueToCode(block, 'bid', javascriptGenerator.ORDER_ATOMIC);
        const code = 'ExistsInStorefront(id, ' + value_bid + ')'
        return [code, javascriptGenerator.ORDER_ATOMIC];
      };

      javascriptGenerator['forBlock']['AddRow'] = function (block) {
        const value_variable = javascriptGenerator.valueToCode(block, 'report_variable', javascriptGenerator.ORDER_ATOMIC);
        const value_variables = javascriptGenerator.valueToCode(block, 'variables', javascriptGenerator.ORDER_ATOMIC);
        const code = value_variable + '.AddRow(' + value_variables + ')' + '\n'
        return code;
      };
      this.toolbox.contents.push(asaVarsToolBox)
      this.toolbox.contents.push(asaFunctionsToolbox)
       if (this.type === 'keyword_blockly'){
        this.toolbox.contents.push(sovVarsToolBox)
       }
    }
  },

  mounted() {
    this.deleteBlocks(['format_as', 'logic_compare_any', 'logic_equals', 'lists_join_many', 'text_join_many','logic_boolean','logic_negate']);
    this.setupCommon();
    if (this.type === 'onetime_blockly') this.setupOnetimeToolbox();
    else this.setupActionsToolbox();

    this.toolbox.contents.push({
      kind: "category",
      name: "Variables",
      custom: "VARIABLE",
      "cssConfig": {
        "icon": "blocklyTreeIconCustomVariable"
      }
    });
    Blockly.defineBlocksWithJsonArray([
      {
        type:"logic_boolean",
        message0:"%1",
        args0:[
          {
            type:"field_dropdown",
            name:"BOOL",
            options:[
              ["%{BKY_LOGIC_BOOLEAN_TRUE}","TRUE"],
              ["%{BKY_LOGIC_BOOLEAN_FALSE}","FALSE"]
            ]
          }
        ],
        output:"Boolean",
        // style:"logic_blocks",
        colour: 160,
        tooltip:"%{BKY_LOGIC_BOOLEAN_TOOLTIP}",
        helpUrl:"%{BKY_LOGIC_BOOLEAN_HELPURL}"
      },
      {
        type:"logic_negate",
        message0:"%{BKY_LOGIC_NEGATE_TITLE}",
        args0:[
          {
            type:"input_value",
            name:"BOOL",
            check:"Boolean"
          }
        ],
        output:"Boolean",
        // style:"logic_blocks",
        colour: '#1176AE',
        tooltip:"%{BKY_LOGIC_NEGATE_TOOLTIP}",
        helpUrl:"%{BKY_LOGIC_NEGATE_HELPURL}"
      }
    ]);
    this.workspace = Blockly.inject(this.id, {
      toolbox: this.toolbox, move: {
        scrollbars: {
          horizontal: false,
          vertical: true
        },
        drag: true,
        wheel: false
      }
    });

    this.populateVars(this.globalVars);
    
    this.eslintConst = new EslintBuild();

    this.eslintRules = { 'no-unused-vars': 0 };

    this.eslintConst.getRules().forEach((desc, name) => {
        if (desc.meta.docs.recommended && this.eslintRules[name] === undefined) this.eslintRules[name] = 2;
    });

    if (this.state) {
      // blockly tends to forget the names of the variables, and complains when we try to use same id again
      if(Array.isArray(this.state.vars)) {
        this.state.vars.forEach(wsVar => {
          const variable = this.workspace.getVariableById(wsVar.id);
          if (variable) {
              this.workspace.deleteVariableById(wsVar.id);
          }
          this.workspace.createVariable(wsVar.name, undefined, wsVar.id)
        });
      }
      let jsonString = JSON.stringify(this.state.blocks);
      jsonString = jsonString.replace(/\binstalls\b/g, 'tapInstalls').replace(/\bcpa\b/g, 'tapAvgCPA').replace(/\btapInstallCPI\b/g, 'tapAvgCPA').replace(/\bcr\b/g, 'tapInstallRate');
      this.state.blocks = JSON.parse(jsonString);
      this.serializer.load(this.state.blocks, this.workspace);
    }
    try{
      Blockly.Extensions.register('custom_data', function() {
      // Generate the dynamic custom data
      var customData = {
        superGlobal: true,
      };

      // Store the custom data in the block
      Object.assign(this, customData);
    });
        }
    catch(error){
    }
  }
});
